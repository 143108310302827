import {useEffect, useState} from 'react';

import {BackendApiService} from '@/services/backend-api-service';
import {singlePromise} from '@/util/promise-util';

type useBackendQueryArgs<R, Response> = {
    domain: string;
    modelName: string;
    data: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    memoize?: boolean;
    callback?: (response: Response) => R;
}
type useBackendQueryResult<R> = [
    boolean,
    R | null
]

const backendQueryCache: Record<string, { data: any, size: number }> = {}; // eslint-disable-line @typescript-eslint/no-explicit-any

// @ts-expect-error tkt frere
window.backendQueryCache = backendQueryCache;

export function useBackendQuery<Response, R = Response>({domain, modelName, data, memoize, callback}: useBackendQueryArgs<R, Response>, dependencies?: unknown[])
    : useBackendQueryResult<R> {
    const backendCallKey = `${domain}/${modelName}/${JSON.stringify(data)}`;
    dependencies = dependencies ?? [];

    const [loading, setLoading] = useState<boolean>(true);
    const [result, setResult] = useState<R | null>(null);

    useEffect(() => {
        callback = callback ?? (a => a as unknown as R);
        setLoading(true);

        if (memoize && backendQueryCache[backendCallKey]) {
            console.debug(`useBackendQuery: returned memoized ${backendCallKey}`);
            setResult(backendQueryCache[backendCallKey].data);
            setLoading(false);
        }
        else {
            (async () => {
                try {
                    const response = await singlePromise(() => BackendApiService.getRequest({
                        domain: domain,
                        modelName: modelName,
                        data: data,
                    }), backendCallKey);

                    const _result = callback!(response);
                    if (memoize) {
                        if (dependencies!.length > 0) {
                            console.warn(`useBackendQuery: cannot memoize ${backendCallKey}; there are external dependencies`);
                        }
                        else {
                            const size = backendCallKey.length + (() => {
                                try {
                                    return JSON.stringify(_result).length;
                                }
                                catch {
                                    return -1;
                                }
                            })();

                            if (size < 0) {
                                console.warn(`useBackendQuery: cannot memoize ${backendCallKey}; size cannot be estimated`);
                            }
                            else if (size > 1024*1024 /*1024*1024*/) {
                                console.warn(`useBackendQuery: cannot memoize ${backendCallKey}; size exceeds 1MB`);
                            }
                            else {
                                backendQueryCache[backendCallKey] = {
                                    data: _result,
                                    size: size,
                                };
                            }
                        }
                    }
                    setResult(_result);
                }
                catch(err) {
                    console.error(err);
                }
                finally {
                    setLoading(false);
                }
            })();
        }
    }, [ ...dependencies, backendCallKey ]);

    return [
        loading,
        result,
    ];
}
