import React from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import {colorPalette} from '@/themes/darkmode.js';
import {withTranslation} from 'react-i18next';

class PatientOnboardingPrintContent extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        t: PropTypes.func,
        patient: PropTypes.object.isRequired,
        qrCode: PropTypes.object.isRequired,
        onSaved: PropTypes.func,
    };

    state = {
        loading: false,
        patient: null,
    };

    render() {
        const props = this.props;
        const { t } = this.props;

        return (
            <div className={props.className}>
                <p>{props.patient.firstName} {props.patient.lastName}{t(['&apos;s account has been successfully created.', 'patientOnboarding.accountCreated'])}</p>
                <p>{t(['The caregiver needs to scan this QR code in the Gabi Monitor Application during the onboarding process.', 'patientOnboarding.scan'])}</p>
                <img className="qrCode" src={`data:image/jpeg;base64,${props.qrCode.qrCodeData}`} alt={t(['Patient QR Code', 'patientOnboarding.qrCodeAlt'])} />
            </div>
        );
    }
}

//language=SCSS
PatientOnboardingPrintContent = Styled(PatientOnboardingPrintContent)`
& {
    color:${colorPalette.darkColor};
    font-size:18px;
    font-weight:bold;
    margin-top:80px;
    padding: 0 100px;
    
    .qrCode {
        border:1px solid ${colorPalette.activeColor};
        padding:10px;
        width:300px;
        margin-top:40px;
    }
}
`;

export default withTranslation()(PatientOnboardingPrintContent);

