import React from 'react';
import Styled from 'styled-components';
import PropTypes from 'prop-types';
import {BackendApiService} from '@/services/backend-api-service.js';
import deepEqual from 'deep-eql';
import LoadingView from '@/components/static/loading-view.tsx';
import {Modal} from '@/components/layout/modal';
import GroupRemoveUser from '@/components/business/analytics/group/group-remove-user.jsx';
import {UserPic} from '@/components/static/user-pic.jsx';
import {Button} from '@/components/buttons/button.jsx';
import autoBind from 'react-autobind';
import {connect as redux_connect} from 'react-redux';
import {GroupUserRole} from '@/components/business/analytics/group/group-user-role.jsx';
import {colorPalette} from '@/themes/darkmode.js';
import GroupUserChangeRole from '@/components/business/analytics/group/group-user-change-role.jsx';
import hcpDefaultPic from '@/public/icons/hcp-default-pic.svg';

const reduxMapStateToProps = function(state) {
    return {
        me: state.me,
    };
};

class GroupUserDetails extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        user: PropTypes.object.isRequired,
        groupId: PropTypes.string.isRequired,
        adminCount: PropTypes.number.isRequired,
        isMeGroupAdmin: PropTypes.bool.isRequired,
        onUserRemoved: PropTypes.func,
        onRoleChanged: PropTypes.func,
    };

    state = {
        showGroupRemoveUserModal: false,
        userDetails: null,
        userRole: null,
        loading: false,
    };

    constructor(props) {
        super(props);
        autoBind(this);
    }

    componentDidMount() {
        this.getUser();
    }

    render() {
        const state = this.state;
        const props = this.props;
        const user = props.user;
        const userDetails = state.userDetails;

        return (
            <div className={`${this.props.className} user-block`}>
                {state.showGroupRemoveUserModal &&
                    <Modal title="Remove user from group" onClosed={this.handleCloseModal}>
                        <GroupRemoveUser
                            groupId={props.groupId}
                            userId={user.id.id}
                            userRole={user.role}
                            userDetails={userDetails}
                            onRemoved={this.handleUserRemoved}
                            onCancelled={this.handleCloseModal}
                        />
                    </Modal>
                }

                {!state.loading && userDetails &&
                    <div>
                        <UserPic pic={hcpDefaultPic} alt={userDetails.firstName} width={70}/>
                        <span className="userDetails">
                            {userDetails.firstName}&nbsp;{userDetails.lastName}<br />
                            <span>{userDetails.email}</span>
                        </span>
                        <GroupUserChangeRole
                            userId={user.id.id}
                            groupId={props.groupId}
                            isAdmin={props.user.role === GroupUserRole.ADMIN}
                            isMeGroupAdmin={props.isMeGroupAdmin}
                            adminCount={props.adminCount}
                            onRoleChanged={this.handleChangeUserRole}
                        />
                        {/* eslint-disable-next-line react/prop-types */}
                        {props.isMeGroupAdmin && (user.id.id !== props.me.data.sub.split('|')[1]) &&
                            <Button className="button-short-filled-hover-delete button-remove-user" displayIcon={true} onClick={this.handleOpenGroupRemoveUserModal}>Remove user from group</Button>
                        }
                    </div>
                }
                {state.loading &&
                    <LoadingView />
                }
            </div>
        );
    }

    handleOpenGroupRemoveUserModal() {
        this.setState(state => ({
            ...state,
            showGroupRemoveUserModal: true,
        }));
    }

    handleCloseModal() {
        this.setState(state => ({
            ...state,
            showGroupRemoveUserModal: false,
        }));
    }

    handleUserRemoved() {
        if(this.props.onUserRemoved) {
            this.props.onUserRemoved();
        }
    }

    handleChangeUserRole() {
        if(this.props.onRoleChanged) {
            this.props.onRoleChanged();
        }
    }

    async getUser() {
        try {
            this.setState(state => ({
                ...state,
                loading: true,
            }));

            const user = await BackendApiService.getRequest({
                domain: 'hcp',
                modelName: 'getHcpPersonalData',
                data: {
                    hcpId: this.props.user.id.id
                }
            });

            this.setState(state => ({
                ...state,
                loading: false,
                userDetails: user.personalDetails,
                userRole: this.props.user.role === GroupUserRole.ADMIN,
            }));
        }
        catch (e) {
            console.error(e);
            this.setState(state => ({
                ...state,
                loading: false,
            }));
        }
    }

    componentWillUnmount() {
        this.setState(state => ({
            ...state,
            showGroupRemoveUserModal: false,
            userDetails: null,
        }));
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !deepEqual(this.props, nextProps) || !deepEqual(this.state, nextState);
    }
}

//language=SCSS
GroupUserDetails = Styled(GroupUserDetails)`
& {
    .userDetails {
        display: inline-block;
        vertical-align: top;
        padding-top: 4px;
        padding-left: 10px;
        width: calc(100% - 110px);
        word-break: break-all;
        span {
            color:${colorPalette.clearColor};
        }
    }
    
    .changeRoleWrapper {
        display:flex;
        margin:15px 0;
        input {
            width:25px;
            height:25px;
        }
        label {
            margin: 2px 0 0 10px;
        }
    }
    
    .button-remove-user {
        position:absolute;
        top:7px;
        right:7px;
        background-color:${colorPalette.thirdBackground};
    }
}
`;

export default redux_connect(reduxMapStateToProps)(GroupUserDetails);

