import {useState} from 'react';
import {useTranslation, withTranslation} from 'react-i18next';

import {PatientCaregiverForm} from '@/components/business/common/patient/patient-caregiver-form';
import {InfoMessage} from '@/components/form/info-message';
import {BackendApiService} from '@/services/backend-api-service';
import {Caregiver} from '@/types/caregiver';

type PatientCreationCaregiverProps = {
    className?: string;
    patientId: string;
    onSaved: (caregiver: Caregiver) => void;
};

function PatientCreationCaregiver({className, patientId, onSaved}: PatientCreationCaregiverProps) {
    const { t } = useTranslation();
    const [ loading, setLoading ] = useState(false);

    const createPatientCaregiver = async (caregiverData: Caregiver) => {
        setLoading(true);

        try {
            const caregiverCreated = await BackendApiService.getRequest({
                domain: 'caregiver',
                modelName: 'createCaregiver',
                data: {
                    caregiver: caregiverData,
                }
            });
            const caregiver: Caregiver = await BackendApiService.getRequest({
                domain: 'patient',
                modelName: 'assignCaregiverToPatient',
                data: {
                    patientId: patientId,
                    caregiverId: caregiverCreated.id,
                }
            });
            if (onSaved) {
                onSaved(caregiver);
            }
        }
        catch (e) {
            console.error(e);
        }
        finally {
            setLoading(false);
        }
    };

    return (
        <div className={`patient-creation-caregiver ${className ?? ''}`}>
            <h3>{t(['Caregiver information', 'createCaregiver.title'])}</h3>

            <InfoMessage infoMessage={t(['Event configuration was successfully saved!', 'createCaregiver.info'])} text={t(['Let\'s continue with caregiver information.', 'createCaregiver.infoText'])} />

            <PatientCaregiverForm loading={loading} onSubmit={(caregiver: Caregiver) => createPatientCaregiver(caregiver)} />
        </div>
    );
}

export default withTranslation()(PatientCreationCaregiver);

