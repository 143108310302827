import PropTypes from 'prop-types';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {colorPalette, colors} from '@/themes/darkmode.js';
import {PatientDashboardTypeEnum} from '@/enum/patient-dashboard-type-enum.jsx';
import {buttonFilledTheme} from '@/components/buttons/button-filled-theme.jsx';

const PatientsDashboardNavigation = ({activeDashboard}) => {
    const { t } = useTranslation();

    return (
        <StyledWrapper>
            <nav>
                <Link to="/patientsActive" className={(activeDashboard === PatientDashboardTypeEnum.RECENT_RECORDING ? 'active' : '')}>{t(['Active patients', 'patientsDashboardNavigation.active'])}</Link>
                <Link to="/patientsInactive" className={(activeDashboard === PatientDashboardTypeEnum.INACTIVE ? 'active' : '')}>{t(['Inactive patients', 'patientsDashboardNavigation.inactive'])}</Link>
                <Link to="/patientsSearch" className={(activeDashboard === PatientDashboardTypeEnum.SEARCH ? 'searchPatientLink active' : 'searchPatientLink')}>{t(['All patients', 'patientsDashboardNavigation.all'])}</Link>
                <Link to="/patient-creation" className="createPatientLink">{t(['New patient', 'pages.patients.title'])}</Link>
            </nav>
        </StyledWrapper>
    );
};

PatientsDashboardNavigation.propTypes = {
    activeDashboard: PropTypes.number
};

//language=SCSS
const StyledWrapper = styled.div`
  && {
      margin-bottom: 30px;
      position:relative;
      
      a {
          display:inline-block;
          border-radius: 20px;
          color: ${colorPalette.frontColor};
          padding: 5px 15px;
          background: ${colorPalette.thirdBackground};
          margin-right: 10px;
          &:hover, &:focus {
              text-decoration: none;
              background-color: ${colorPalette.activeColorDarker};
          }
      }
      .active {
        &, &:hover, &:focus {
            background-color: ${colorPalette.activeColor};
        }
      }
      
      .createPatientLink {
          background-image: ${colors.buttonCreate.backgroundIcon};
          border-radius: 5px;
          background-repeat: no-repeat;
          background-size: 20px;
          background-position: 10px center;
          padding: 10px 20px 10px 45px;
          position: absolute;
          right: 0;
          top: 0;
          margin: 0;
          ${buttonFilledTheme(colors.buttonCreate)}
      }

      .searchPatientLink {
          background-image: ${colors.buttonSearch.backgroundIcon};
          background-repeat: no-repeat;
          background-size: 16px;
          background-position: 10px center;
          padding: 5px 15px 5px 35px;
      }
  }
`;

export default PatientsDashboardNavigation;
