import React from 'react';
import Styled from 'styled-components';
import { Page } from '@/decorators/page';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';

function wrapWithPage(Component) {
    return Page({
        name: 'Access denied'
    })(Component);
}

class Err403Page extends React.Component {

    static propTypes = {
        t: PropTypes.func,
        className: PropTypes.string,
    };

    render() {
        const { t } = this.props;

        return (
            <div className={this.props.className}>
                <h1 className="text-center">{t(['You don\'t have access to this page.', 'pages.error403.title'])}</h1>
                <h2 className="text-center">{t(['Error 403', 'pages.error403.error'])}</h2>
            </div>
        );
    }

    shouldComponentUpdate() {
        return false;
    }
}

//language=SCSS
Err403Page = Styled(Err403Page)`
& {
    padding: 50px 0 0 0;
}
`;

wrapWithPage(Err403Page);

export default withTranslation()(Err403Page);
