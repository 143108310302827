import PropTypes from 'prop-types';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import PatientsActiveTableRow from '@/components/business/analytics/patients/patients-active-table-row.jsx';
import {InfoMessage} from '@/components/form/info-message.jsx';
import TableSortable from '@/components/table/table-sortable.jsx';
import hcpProgramConfig from '@/components/business/analytics/patients/hcp-program-config.js';
import ComponentErrorMessage from '@/components/widgets/component-error-message.tsx';

const PatientsActiveTable = ({patients, program}) => {
    const { t } = useTranslation();

    const programConfig = hcpProgramConfig(program);
    const tableSortableHeaderColumn = [
        {
            label: '',
            accessor: 'id',
            sortable: false
        },
        {
            label: t(['Patient', 'patient.title']),
            accessor: 'patient.lastName',
            sortable: true
        },
        {
            label: t(['Last recording', 'patient.lastRecording']),
            accessor: 'lastRecordingDataTimeSortable',
            sortable: true
        },
    ];
    if(programConfig.pulseRate.average) {
        tableSortableHeaderColumn.push({
            label: t(['Pulse Rate', 'global.pulseRate']),
            subLabel: t(['Average', 'global.average']),
            className: 'separatorCellFirst signalTableHeader signalTableHeader_pulseRate',
            accessor: 'lastRecordingInformation.pulseRate.statistics.average',
            sortable: true
        });
    }
    if(programConfig.spo2.average) {
        tableSortableHeaderColumn.push({
            label: t(['SpO2', 'global.spo2']),
            subLabel: t(['Average', 'global.average']),
            className: 'signalTableHeader signalTableHeader_spo2',
            accessor: 'lastRecordingInformation.spo2.statistics.average',
            sortable: true
        });
    }
    if(programConfig.pulseRate.eventsBelow) {
        tableSortableHeaderColumn.push({
            label: t(['Pulse Rate', 'global.pulseRate']),
            subLabel: `${t(['Events/h', 'global.eventsTitle'])} - ${t(['Below', 'global.below'])}`,
            className: 'separatorCell signalTableHeader signalTableHeader_pulseRate',
            accessor: 'lastRecordingInformation.pulseRate.eventsCountLow.eventCount',
            sortable: true
        });
    }
    if(programConfig.pulseRate.eventsAbove) {
        tableSortableHeaderColumn.push({
            label: t(['Pulse Rate', 'global.pulseRate']),
            subLabel: `${t(['Events/h', 'global.eventsTitle'])} - ${t(['Above', 'global.above'])}`,
            className: 'signalTableHeader signalTableHeader_pulseRate',
            accessor: 'lastRecordingInformation.pulseRate.eventsCountHigh.eventCount',
            sortable: true
        });
    }
    if(programConfig.spo2.eventsBelow) {
        tableSortableHeaderColumn.push({
            label: t(['SpO2', 'global.spo2']),
            subLabel: `${t(['Percentage', 'global.percentage'])} - ${t(['Below', 'global.below'])}`,
            className: 'signalTableHeader signalTableHeader_spo2',
            accessor: 'lastRecordingInformation.spo2.eventPercent.eventPercent',
            sortable: true
        });
    }

    if(program !== null && patients) {
        if(patients && patients.length > 0) {
            return (
                <StyledWrapper>
                    <TableSortable
                        data={patients}
                        columns={tableSortableHeaderColumn}
                        defaultSorting={{sortByOrder: 'desc', accessor: 'lastRecordingDate'}}
                        TableBodyRowComponent={PatientsActiveTableRow}
                    />
                </StyledWrapper>
            );
        } else {
            return <InfoMessage infoMessage="No active patients." />;
        }
    } else {
        return <ComponentErrorMessage component="PatientsActiveTable" />;
    }
};

PatientsActiveTable.propTypes = {
    patients: PropTypes.array.isRequired,
    program: PropTypes.number.isRequired,
};

//language=SCSS
const StyledWrapper = styled.div`
  && {
    
  }
`;

export default PatientsActiveTable;
