import {HcpProgramEnum} from '@/enum/hcp-program-enum.jsx';

const HcpProgramConfig = (program) => {
    const config = {
        pulseRate: {
            average: true,
            eventsBelow: true,
            eventsAbove: true,
        },
        spo2: {
            average: true,
            eventsBelow: true,
        },
        movements: true,
        sleeping: true,
    };

    if(program === HcpProgramEnum.CARDIO) {
        config.spo2.eventsBelow = false;
    }

    if(program === HcpProgramEnum.RESPIRATORY) {
        config.pulseRate.eventsBelow = false;
        config.pulseRate.eventsAbove = false;
    }

    return config;
};

export default HcpProgramConfig;
