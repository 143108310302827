import {subDays} from 'date-fns';
import {BandAssignationStatus} from 'gabi-api-js/patient/patient_common_pb';
import PropTypes from 'prop-types';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useNavigate} from 'react-router-dom';
import styled from 'styled-components';

import PatientAvatar from '@/components/business/analytics/patient/common/patient-avatar';
import PatientLastRecording from '@/components/business/analytics/patient/common/patient-last-recording';
import {Button} from '@/components/buttons/button';
import IconTooltip from '@/components/buttons/icon-tooltip';
import {Modal} from '@/components/layout/modal';
import {BandAssignationStatusLabel} from '@/enum/band-assignation-status';
import {PatientChildSexLabel} from '@/enum/patient-child-sex-enum';
import PatientDelete from '@/pages/analytics/patient/patient-delete';
import warningIcon from '@/public/icons/warning-icon.svg';
import {formatApiDateToJSDate} from '@/services/api-requests/requests-utils';
import {colorPalette} from '@/themes/darkmode';
import {formatDateUniversal} from '@/util/date-utils';
import {NewDateUtil} from '@/util/new-date-util';
import PatientUpdateOnboarding from '@/components/business/analytics/patient/patient-update/patient-update-onboarding';

let PatientsTableRow = ({data}) => {
    const { t } = useTranslation();
    let navigate = useNavigate();

    const [showPatientDeleteModal, setShowPatientDeleteModal] = useState(false);
    const [patientDeleted, setPatientDeleted] = useState(false);
    const [showOnboarding, setShowOnboarding] = useState(null);

    const isStudyPatient = data.trialId?.id;

    let healthReportView = 'last30Days';

    if (data.lastRecordingDate) {
        const lastRecordingDate = formatApiDateToJSDate(data.lastRecordingDate);
        const trendsDate = subDays(NewDateUtil(), 30);

        if(lastRecordingDate < trendsDate) {
            healthReportView = 'trends';
        }
    }
    
    const handleClickUpdateOnboarding = () => {
        setShowOnboarding(true);
    };
    
    const handleCloseUpdateOnboarding = () => {
        setShowOnboarding(false);
    };

    const handleOpenDeletePatientModal = () => {
        setShowPatientDeleteModal(true);
    };

    const handleOpenEditPatientPage = () => {
        let url = `/patient-update/${data.patientId.id}/patients/`;
        navigate(url);
    };

    const handleCloseModal = () => {
        setShowPatientDeleteModal(false);
    };

    const handlePatientDeleted = () => {
        setShowPatientDeleteModal(false);
        setPatientDeleted(true);
    };

    if (patientDeleted) {
        return (
            <StyledTr key={data.patientId.id} className="patients-table-row deleted">
                <td>
                    {t(['Deleted', 'patient.deleted'])}
                </td>
                <td colSpan={5} className="patientNameCell">
                    <div>
                        <PatientAvatar skinType={data.skinType} />
                        <p>
                            {data.firstName || data.externalSource?.idInSource || data.patientId.id} {data.lastName}
                            <span>{t('patient.sexLabel.' + PatientChildSexLabel[data.sex])}</span>
                            <span>{data.trialId?.id}</span>
                        </p>
                    </div>
                </td>
            </StyledTr>
        );
    }
    else {
        return (
            <StyledTr key={data.patientId.id} className="patients-table-row">
                <td className="linkCell">
                    {data.assignationStatus === BandAssignationStatus.NO_QR_CODE_AVAILABLE &&
                        <IconTooltip icon={warningIcon} iconAltText={t(['Red warning icon', 'pages.patients.incompletePatientImgAltText'])}>
                            {t(['Please complete this patient information to generate a QR code for the caregiver.', 'pages.patients.incompletePatientText'])}
                        </IconTooltip>
                    }
                    {data.lastRecordingDate && (
                        <Link to={`/patients/${data.patientId.id}/healthReport/${healthReportView}`}>
                            {t(['Patient details', 'pages.patients.patientDetails'])}
                        </Link>
                    )}
                </td>
                <td className="patientNameCell">
                    <div>
                        <PatientAvatar skinType={data.skinType}/>
                        <p>
                            {data.firstName || data.externalSource?.idInSource || data.patientId.id} {data.lastName}
                            <span>
                                {data.birthDate ?
                                    <>{formatDateUniversal(formatApiDateToJSDate(data.birthDate))}&nbsp;-&nbsp;</>
                                    :
                                    <><em>{t(['No birth date information', 'patient.birthDateEmpty'])}&nbsp;-&nbsp;</em></>
                                }
                                {t('patient.sexLabel.' + PatientChildSexLabel[data.sex])}
                            </span>
                            <span>{data.trialId?.id}</span>
                        </p>
                    </div>
                </td>
                <td>
                    {data.assignationStatus === BandAssignationStatus.NO_BAND_ASSIGNED && (
                        <div className="qrcode-button">
                            {showOnboarding && (
                                <PatientUpdateOnboarding patientId={data.patientId.id} showModalOnboarding={true} onClosed={handleCloseUpdateOnboarding} />
                            )}
                            <Button
                                className="button-short-filled-hover-edit button-qrcode"
                                displayIcon={true}
                                onClick={handleClickUpdateOnboarding}
                            />
                        </div>
                    )}
                    
                    {data.assignationStatus === BandAssignationStatus.HAS_RECORDING && data.lastRecordingDataTime &&
                        <PatientLastRecording lastRecordingDataTime={data.lastRecordingDataTime} />
                    }

                    {data.assignationStatus !== BandAssignationStatus.HAS_RECORDING &&
                        <em>{t(`global.bandAssignationStatus.${BandAssignationStatusLabel[data.assignationStatus]}`)}</em>
                    }
                </td>
                <td>
                    {(data.caregiverInformation) ?
                        <div className="caregiver-info">
                            {data.caregiverInformation.firstName}
                            {' '}
                            {data.caregiverInformation.lastName}<br/>
                            <a href={`tel:${data.caregiverInformation.phoneNumber}`}>{data.caregiverInformation.phoneNumber}</a><br/>
                            <a href={`mailto:${data.caregiverInformation.email}`}>{data.caregiverInformation.email}</a>
                        </div>
                        :
                        <em>{t('caregiver.noCaregiver', 'No caregiver information')}</em>
                    }
                </td>
                <td className="buttonCell">
                    {!isStudyPatient &&
                        <Button
                            className="button-short-filled-hover-edit button-edit-patient"
                            displayIcon={true}
                            onClick={handleOpenEditPatientPage}
                        >
                            {t(['Update patient', 'pages.patients.updatePatient'])}
                        </Button>
                    }
                </td>
                <td className="buttonCell">
                    {!data.lastRecordingDate && !data.bandSerial?.serial &&
                        <Button
                            className="button-short-filled-hover-delete button-remove-patient"
                            displayIcon={true}
                            onClick={handleOpenDeletePatientModal}
                        >
                            {t(['Delete patient', 'pages.patients.deletePatient'])}
                        </Button>
                    }
                    {showPatientDeleteModal &&
                        <Modal title={t(['Delete patient', 'pages.patients.deletePatient'])} onClosed={handleCloseModal} hideCloseButton>
                            <PatientDelete
                                patient={data}
                                onDeleted={handlePatientDeleted}
                                onCancelled={handleCloseModal}
                            />
                        </Modal>
                    }
                </td>
            </StyledTr>
        );
    }
};

PatientsTableRow.propTypes = {
    className: PropTypes.string,
    data: PropTypes.object,
    columns: PropTypes.array,
};

//language=SCSS
const StyledTr = styled.tr`
& {
    &.deleted {
        background: ${colorPalette.delete};
    }
    
    .linkCell {
        text-align: center;
        > * {
          text-align: initial;
        }
    }
    
    .caregiver-info {
        max-width: 20vw;
        overflow: hidden; /* make sure it hides the content that overflows */
        text-overflow: ellipsis; /* give the beautiful '...' effect */
    }
    
    .button-qrcode {
        float: right;
        position: relative;
        top: -4px;
    }
    
    button {
        height: 35px;
    }
    
    .button-edit-patient,
    .button-delete-patient {
        display: inline-block;
    }
}
`;

export default PatientsTableRow;
