import deepEqual from 'deep-eql';
import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';
import {withTranslation} from 'react-i18next';
import {connect as redux_connect} from 'react-redux';
import Styled from 'styled-components';

import {HcpTutorialStatus} from '@/components/business/analytics/hcp/hcp-tutorial-status';
import {GuidedTour} from '@/components/layout/guided-tour';
import {GuidedTourStep} from '@/components/layout/guided-tour-step';
import demoBabyHello from '@/public/images/demo_baby_hello.svg';
import healthReportBackGif from '@/public/images/guidedTour/health-report/back.gif';
import healthReportClickChartsGif from '@/public/images/guidedTour/health-report/clickCharts.gif';
import healthReportClickInfoGif from '@/public/images/guidedTour/health-report/clickInfo.gif';
import healthReportEventConfigurationGif from '@/public/images/guidedTour/health-report/eventConfiguration.gif';
import healthReportEventSampleGif from '@/public/images/guidedTour/health-report/eventSample.gif';
import healthReportExpandGif from '@/public/images/guidedTour/health-report/expand.gif';
import {BackendApiService} from '@/services/backend-api-service';
import {tutorialStatus} from '@/store/models/index';

const reduxMapStateToProps = function(state) {
    return {
        tutorialStatus: state.tutorialStatus,
    };
};

const reduxDispatch = function(dispatch) {
    return {
        setTutorialStatus: (status) => {
            dispatch(tutorialStatus.actions.set(status));
        }
    };
};

class GuidedTourHealthReport extends React.Component {
    static propTypes = {
        t: PropTypes.func,
        tutorialStatus: PropTypes.any,
        setTutorialStatus: PropTypes.func,
        onClosed: PropTypes.func,
    };

    state = {
        steps: [
            this.props.t(['Welcome on the Health report', 'guidedTour.healthReport.welcome.title']),
            this.props.t(['Expand for more information', 'guidedTour.healthReport.expand.title']),
            this.props.t(['Access event configuration', 'guidedTour.healthReport.eventConfiguration.title']),
            this.props.t(['Details for an event from the table', 'guidedTour.healthReport.eventSample.title']),
            this.props.t(['Access to specific timing in daily biometrics', 'guidedTour.healthReport.clickChart.title']),
            this.props.t(['Use info buttons', 'guidedTour.healthReport.clickInfo.title']),
            this.props.t(['Go back to patients list', 'guidedTour.healthReport.back.title']),
        ],
        activeStep: 0,
    };

    constructor(props) {
        super(props);
        autoBind(this);
    }
    
    componentDidMount() {
        [
            healthReportBackGif,
            healthReportClickChartsGif,
            healthReportClickInfoGif,
            healthReportEventConfigurationGif,
            healthReportEventSampleGif,
            healthReportExpandGif,
        ].forEach((picture) => {
            const img = new Image();
            img.src = picture;
        });
    }

    render() {
        const state = this.state;
        const { t } = this.props;

        return (
            <>
                <GuidedTour steps={state.steps} activeStep={state.activeStep} onChanged={this.handleChangeActiveStep} onClosed={this.handleCloseGuidedTour}>
                    {state.activeStep === 0 &&
                        <GuidedTourStep>
                            <p>{t(['Let’s take your first steps with this dashboard', 'guidedTour.healthReport.welcome.text'])}</p>
                            <img src={demoBabyHello} width={100} alt={t(['Baby fox waving hello', 'guidedTour.healthReport.welcome.alt'])} />
                        </GuidedTourStep>
                    }
                    {state.activeStep === 1 &&
                        <GuidedTourStep>
                            <p>{t(['Use the two arrows button to display and access the details', 'guidedTour.healthReport.expand.text'])}</p>
                            <img src={healthReportExpandGif} width={400} alt={t(['Animated gif showing how to expand patient information', 'guidedTour.healthReport.expand.alt'])} />
                        </GuidedTourStep>
                    }
                    {state.activeStep === 2 &&
                        <GuidedTourStep>
                            <p>{t(['Configure events thresholds and sensitivity', 'guidedTour.healthReport.eventConfiguration.text'])}</p>
                            <img src={healthReportEventConfigurationGif} height={200} alt={t(['Animated gif showing how to access event configuration', 'guidedTour.healthReport.eventConfiguation.alt'])} />
                        </GuidedTourStep>
                    }
                    {state.activeStep === 3 &&
                        <GuidedTourStep>
                            <p>{t(['Click on the eye to display the biometric and movement graphs for the selected event', 'guidedTour.healthReport.eventSample.text'])}</p>
                            <img src={healthReportEventSampleGif} height={200} alt={t(['Animated gif showing how to display an event sample', 'guidedTour.healthReport.eventSample.alt'])} />
                        </GuidedTourStep>
                    }
                    {state.activeStep === 4 &&
                        <GuidedTourStep>
                            <p>{t(['Access a specific time in the daily biometrics by clicking on a chart', 'guidedTour.healthReport.clickChart.text'])}</p>
                            <img src={healthReportClickChartsGif} width={400} alt={t(['Animated gif showing how to click on charts', 'guidedTour.healthReport.clickChart.alt'])} />
                        </GuidedTourStep>
                    }
                    {state.activeStep === 5 &&
                        <GuidedTourStep>
                            <p>{t(['Use info buttons to display more information about the chart', 'guidedTour.healthReport.clickInfo.text'])}</p>
                            <img src={healthReportClickInfoGif} width={400} alt={t(['Animated gif showing how to use info buttons', 'guidedTour.healthReport.clickInfo.alt'])} />
                        </GuidedTourStep>
                    }
                    {state.activeStep === 6 &&
                        <GuidedTourStep>
                            <p>{t(['You can access your patients list by using the breadcrumb.', 'guidedTour.healthReport.back.text'])}</p>
                            <img src={healthReportBackGif} width={400} alt={t(['Animated gif showing how to go back to patients', 'guidedTour.healthReport.back.alt'])} />
                        </GuidedTourStep>
                    }
                </GuidedTour>
            </>
        );
    }

    handleChangeActiveStep(activeStep) {
        this.setState(state => ({
            ...state,
            activeStep: activeStep,
        }));
    }

    handleCloseGuidedTour() {
        this.changedGuidedTourTutorialStatus();

        if(this.props.onClosed) {
            this.props.onClosed();
        }
    }

    changedGuidedTourTutorialStatus() {
        BackendApiService.getRequest({
            domain: 'hcp',
            modelName: 'updateTutorialStatus',
            status: {
                guidedTourHealthReport: HcpTutorialStatus.DISABLED,
            },
        }).then((tutorialStatus) => {
            const updatedTutorialStatus = Object.assign({}, this.props.tutorialStatus);
            updatedTutorialStatus.guidedTourHealthReport = HcpTutorialStatus.DISABLED;
            this.props.setTutorialStatus(updatedTutorialStatus);

            return tutorialStatus;
        }).catch(err => {
            console.error(err);
            this.setState(state => ({
                ...state,
            }));
        });
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !deepEqual(this.props, nextProps) || !deepEqual(this.state, nextState);
    }
}

//language=SCSS
GuidedTourHealthReport = Styled(GuidedTourHealthReport)`
& {

}
`;

export default redux_connect(reduxMapStateToProps, reduxDispatch)(withTranslation()(GuidedTourHealthReport));

