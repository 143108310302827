type ChartAxisTickColoredProps = {
    x: number;
    y: number;
    height: number;
    payload: { value: number };
    textAnchor: string;
    decimals?: number,
    unit?: string;
    fill: string;
    alignUpTick?: boolean;
    alignDownTick?: boolean;
};

function ChartAxisTickColored({x, y, height, payload, textAnchor, decimals, unit, fill, alignUpTick, alignDownTick}: ChartAxisTickColoredProps) {
    decimals = decimals ?? 0;
    const decimalPower = Math.pow(10, decimals);
    if (alignUpTick && y < 6.6) {
        y = 6.6;
    }
    if (alignDownTick && y > height - 8) {
        y = height - 8;
    }

    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={4} dx={-4} textAnchor={textAnchor || 'end'} fill={fill} >
                {Math.abs(Math.round(payload.value*decimalPower)/decimalPower)}{unit ?? ''}
            </text>
        </g>
    );
}

export { ChartAxisTickColored };
