import React from 'react';
import PropTypes from 'prop-types';
import {BackendApiService} from '@/services/backend-api-service.js';
import LoadingView from '@/components/static/loading-view.tsx';
import { buttonFilledTheme } from '@/components/buttons/button-filled-theme.jsx';
import Styled from 'styled-components';
import {colors} from '@/themes/darkmode.js';
import {withTranslation} from 'react-i18next';

class HcpUpdatePassword extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        t: PropTypes.func,
        hcp: PropTypes.object.isRequired,
        onSaved: PropTypes.func
    };

    state = {
        loading: false,
        changePasswordLink: null,
    };

    componentDidMount() {
        this.getChangePasswordLink();
    }

    render() {
        const state = this.state;
        const { t } = this.props;

        return (
            <div className={this.props.className}>
                {!state.loading &&
                    <div>
                        <h3>{t(['Change my password', 'hcpUpdatePassword.title'])}</h3>
                        <a target="_blank" rel="noreferrer" className="change-password-link" href={state.changePasswordLink}>{t(['Change my password', 'hcpUpdatePassword.changePasswordLink'])}</a>
                    </div>
                }
                {state.loading &&
                    <LoadingView />
                }
            </div>
        );
    }

    async getChangePasswordLink() {
        this.setState(state => ({
            ...state,
            loading: true,
        }));

        BackendApiService.getRequest({
            domain: 'hcp',
            modelName: 'getHcpChangePasswordLink',
        })
            .then((changePasswordLink) => {
                this.setState(state => ({
                    ...state,
                    loading: false,
                    changePasswordLink: changePasswordLink.link,
                }));

                return changePasswordLink;
            })
            .catch(err => {
                console.error(err);
                this.setState(state => ({
                    ...state,
                    loading: false,
                }));
            });
    }
}

//language=SCSS
HcpUpdatePassword = Styled(HcpUpdatePassword)`
& { 
    .change-password-link {
        display:inline-block;
        margin-top:15px;
        border:none;
        border-radius:3px;
        color:${colors.button.text};
        background-repeat:no-repeat;
        background-position: 10px center;
        padding: 7px 15px;
        ${buttonFilledTheme(colors.buttonCreate)}
    }
}
`;

export default withTranslation()(HcpUpdatePassword);

