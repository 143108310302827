import PropTypes from 'prop-types';
import styled from 'styled-components';
import {colorPalette} from '@/themes/darkmode.js';
import {useTranslation} from 'react-i18next';

const PatientHealthConditionLabel = ({healthConditionLabel}) => {
    const { t } = useTranslation();

    return (
        <StyledWrapper>
            {t([healthConditionLabel, `healthCondition.healthConditionTypes.${healthConditionLabel.toLowerCase().replace(' ', '-')}`])}
        </StyledWrapper>
    );
};

PatientHealthConditionLabel.propTypes = {
    healthConditionLabel: PropTypes.string,
};

//language=SCSS
const StyledWrapper = styled.div`
  && {
      display:inline-block;
      font-size:12px;
      padding:2px 10px;
      border-radius:20px;
      background: ${colorPalette.activeColor};
      margin-right: 10px;
  }
`;

export default PatientHealthConditionLabel;
