import {differenceInDays} from 'date-fns';
import {SignalEventConfiguration} from 'gabi-api-js/patient/patient_common_pb';
import {AlertType, Average, EventsPerDay, HasData} from 'gabi-api-js/signal/signal_query_pb';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Bar, BarChart, ReferenceLine, Tooltip, XAxis, YAxis} from 'recharts';
import {CategoricalChartFunc} from 'recharts/types/chart/generateCategoricalChart';
import {TooltipProps} from 'recharts/types/component/Tooltip';
import styled from 'styled-components';

import {ChartAxisTickColored} from '@/components/business/analytics/patient/patient-chart-axis/chart-axis-tick-colored';
import {ChartAxisTickDate} from '@/components/business/analytics/patient/patient-chart-axis/chart-axis-tick-date';
import {getDomainForEvents} from '@/components/business/analytics/timeline/timeline-chart-domains';
import {ChartDualLabel, ChartDualLabelProps} from '@/components/charts/chart-dual-label';
import {ChartContainer} from '@/components/layout/chart-container';
import LoadingView from '@/components/static/loading-view';
import ComponentErrorMessage from '@/components/widgets/component-error-message';
import {getSignalTypeIdentifier, getSignalTypeUnit, SignalTypeEnum} from '@/enum/signal-type-enum';
import {BackendApiService} from '@/services/backend-api-service';
import {colorPalette} from '@/themes/darkmode';
import {EventThresholdType} from '@/types/event-threshold-type';
import {formatDateUniversal, formatSecondsToHms} from '@/util/date-utils';
import {roundDurationHoursToQuarterPrecision} from '@/util/time-utils';

type HealthReportEventsPerDayProps = {
    patientId: string;
    signalType: number;
    thresholdTypes: EventThresholdType[];
    dateFrom: Date;
    dateTo: Date;
    subtitle?: string;
    signalEventConfiguration: SignalEventConfiguration.AsObject;
    onClick?: (date: Date) => void;
}

type EventFormatted = {
    date: string;
    events: number | null;
    above?: number | null;
    below?: number | null;
    hasEnoughData: boolean;
    reliableDurationInSeconds: number;
    duration: number | null;
};

function getAlertType(thresholdType: EventThresholdType): AlertType {
    switch(thresholdType) {
    case EventThresholdType.HIGH:
        return AlertType.ALERT_HIGH;
    case EventThresholdType.LOW:
        return AlertType.ALERT_LOW;
    }
}

function HealthReportEventsPerDay({patientId, signalType, thresholdTypes, dateFrom, dateTo, subtitle, signalEventConfiguration, onClick}: HealthReportEventsPerDayProps) {
    const { t } = useTranslation();
    const [eventsPerDayAbove, setEventsPerDayAbove] = useState<EventFormatted[]>([]);
    const [eventsPerDayBelow, setEventsPerDayBelow] = useState<EventFormatted[]>([]);
    const [averageEventsPerHoursAboveForPeriod, setAverageEventsPerHoursAboveForPeriod] = useState<Average.AsObject | null>(null);
    const [averageEventsPerHoursBelowForPeriod, setAverageEventsPerHoursBelowForPeriod] = useState<Average.AsObject | null>(null);
    const [loading, setLoading] = useState(true);

    const nbDays = differenceInDays(dateTo, dateFrom)+1;

    useEffect(() => {
        const fetch = async () => {
            setLoading(true);
            const getEventsPerDayPromises = thresholdTypes.map(thresholdType => {
                return getEventsPerDay(thresholdType);
            });
            const getAveragesPromises = thresholdTypes.map(thresholdType => {
                return getAverageEventsPerHoursForPeriod(thresholdType);
            });
            const promises = [
                ...getEventsPerDayPromises,
                ...getAveragesPromises,
            ];
            await Promise.all(promises);
            setLoading(false);
        };
        fetch();
    }, [signalEventConfiguration, thresholdTypes, dateFrom, dateTo]);

    const getEventsPerDay = async (thresholdType: EventThresholdType) => {
        try {
            const response = await BackendApiService.getRequest({
                domain: 'signal',
                modelName: thresholdType === EventThresholdType.HIGH ? 'getEventsHighPerDay' : 'getEventsLowPerDay',
                data: {
                    patientId: patientId,
                    signalType: signalType,
                    dateFrom: dateFrom,
                    dateTo: dateTo,
                }
            });
            const eventsPerDayFormatted: EventFormatted[] = [];
            const eventsPerDayList = (response as EventsPerDay.AsObject).eventsPerDayList;

            eventsPerDayList
                .forEach(event => {
                    const hasEnoughData = event.hasRecording && event.hasData >= HasData.HAS_MIN_RELIABLE_DATA;
                    eventsPerDayFormatted.push({
                        date: event.date,
                        hasEnoughData: hasEnoughData,
                        events: (hasEnoughData && event.reliableDurationInSeconds > 0) ? event.events : null,
                        reliableDurationInSeconds: event.reliableDurationInSeconds,
                        duration: (hasEnoughData && event.reliableDurationInSeconds > 0) ? roundDurationHoursToQuarterPrecision(event.reliableDurationInSeconds) : null,
                    });
                });

            if (thresholdType === EventThresholdType.HIGH) {
                setEventsPerDayAbove(eventsPerDayFormatted);
            }
            if (thresholdType === EventThresholdType.LOW) {
                setEventsPerDayBelow(eventsPerDayFormatted);
            }
            return response;
        }
        catch(err) {
            console.error(err);
        }
    };

    const getAverageEventsPerHoursForPeriod = async (thresholdType: EventThresholdType) => {
        try {
            const alertType = getAlertType(thresholdType);
            const response: Average.AsObject = (await BackendApiService.getRequest({
                domain: 'signal',
                modelName: 'getAverageEventsPerHoursForPeriod',
                data: {
                    patientId: patientId,
                    signalType: signalType,
                    alertType: alertType,
                    dateFrom: dateFrom,
                    dateTo: dateTo,
                }
            }));

            if (thresholdType === EventThresholdType.HIGH) {
                setAverageEventsPerHoursAboveForPeriod(response);
            }
            if (thresholdType === EventThresholdType.LOW) {
                setAverageEventsPerHoursBelowForPeriod(response);
            }
        }
        catch(err) {
            console.error('getAverageEventsPerHoursForPeriod ERROR');
            console.error(err);
        }
    };

    const handleClick: CategoricalChartFunc = (rechartData) => {
        if (onClick) {
            if (rechartData && rechartData.activePayload && rechartData.activePayload[0]) {
                const rechartsPayload = rechartData?.activePayload[0].payload;
                if (rechartsPayload) {
                    const selectedDate = new Date(rechartsPayload.date);
                    const duration = rechartsPayload.reliableDurationInSeconds;
                    if (duration && duration > 0) {
                        onClick(selectedDate);
                    }
                }
            }
        }
    };

    const renderTooltip = (data: TooltipProps<number | string | Array<number | string>, string | number>, t: (keys: string[], args?: object) => string) => {
        if (data?.payload && data?.payload[0] && data?.payload[0].payload) {
            const reliableDurationInSeconds = data?.payload[0]?.payload?.reliableDurationInSeconds;
            const hasEnoughData = data?.payload[0]?.payload?.hasEnoughData;
            const unit = signalType === SignalTypeEnum.SPO2 ? '%' : ` ${t(['events', 'global.eventsPerHour'])}`;

            const eventsAbove = data?.payload[0]?.payload?.above;
            const eventsBelow = Math.abs(data?.payload[0]?.payload?.below);

            return (
                <div className="chart-tooltip">
                    <strong>{formatDateUniversal(new Date(data?.payload[0]?.payload?.date + ' 00:00:00'))}</strong><br/>
                    {!reliableDurationInSeconds ? (
                        <span>{t(['No recording for this day', 'healthReport.statistics.last7nights.noRecordingForThisDay'])}</span>
                    ) : (<>
                        <span className="tooltip-duration">{t(['Duration', 'healthReport.statistics.last7nights.duration'])}: {formatSecondsToHms(reliableDurationInSeconds)}</span>
                        {!hasEnoughData ? (
                            <span>{t(['Not enough data for this night', 'healthReport.statistics.last7nights.notEnoughDataForThisDay'])}</span>
                        ) : (<>
                            <span className="tooltip-events">
                                {t(['Above', 'global.above'])}{': '}{eventsAbove.toFixed(2)}{unit}
                            </span>
                            <span className="tooltip-events">
                                {t(['Below', 'global.below'])}{': '}{eventsBelow.toFixed(2)}{unit}
                            </span>
                        </>)}
                    </>)}
                </div>
            );
        }
        return null;
    };

    if (patientId && dateFrom && dateTo && signalEventConfiguration) {
        const title = (() => {
            switch(signalType) {
            case SignalTypeEnum.PULSE_RATE: return t(['Pulse Rate - events', 'healthReport.events.last7nights.pulseRateTitle']);
            case SignalTypeEnum.SPO2: return t(['SpO2 - percentage', 'healthReport.events.last7nights.spo2Title']);
            case SignalTypeEnum.MOVEMENTS: return ''; // not used
            }
            return '';
        })();

        subtitle = subtitle ?? (() => {
            const out: string[] = [];
            if (thresholdTypes.includes(EventThresholdType.LOW)) {
                out.push(`${t(['Below', 'global.below'])} ${signalEventConfiguration.low?.value}${getSignalTypeUnit(signalType)}`);
            }
            if (thresholdTypes.includes(EventThresholdType.HIGH)) {
                out.push(`${t(['Above', 'global.above'])} ${signalEventConfiguration.high?.value}${getSignalTypeUnit(signalType)}`);
            }

            return out.join(' or ');
        })();

        if (!loading) {
            // Merge events per hour above and below as one array
            const eventsPerDay: EventFormatted[] = [];
            const refEventsPerDay = thresholdTypes[0] === EventThresholdType.HIGH ? eventsPerDayAbove : eventsPerDayBelow;
            for (let i=0; i < refEventsPerDay.length; ++i) {
                const baseEvent: EventFormatted = refEventsPerDay[i];
                eventsPerDay[i] = {
                    ...baseEvent,
                    events: (eventsPerDayAbove[i]?.events ?? 0) + (eventsPerDayBelow[i]?.events ?? 0),
                    above: eventsPerDayAbove[i]?.events ?? 0,
                    below: -1 * (eventsPerDayBelow[i]?.events ?? 0),
                };
            }

            // Define domain by taking the min/max amount of event per hour for symetry
            let maxAmount = 0;
            for (let i=0; i < refEventsPerDay.length; ++i) {
                if (eventsPerDay[i].above! > maxAmount) {
                    maxAmount = eventsPerDay[i].above!;
                }
                if (-1 * eventsPerDay[i].below! > maxAmount) {
                    maxAmount = -1 * eventsPerDay[i].below!;
                }
            }

            const domainForEvents = getDomainForEvents(maxAmount, true);

            const eventsPerDayNotZero = eventsPerDay.filter(x => x.events && x.events !== 0).length;
            const hasDataAbove = averageEventsPerHoursAboveForPeriod?.hasData ?? HasData.HAS_NO_DATA;
            const hasDataBelow = averageEventsPerHoursBelowForPeriod?.hasData ?? HasData.HAS_NO_DATA;
            const bestHasData: HasData = Math.max(hasDataAbove, hasDataBelow);

            const tooltipText = <>
                <p>
                    {signalType === SignalTypeEnum.PULSE_RATE &&
                        <>{t(['This chart shows the daily average of the pulse rate events/h.', 'infoButton.eventsPerDay.pulseRate'])}&nbsp;</>
                    }
                    {signalType === SignalTypeEnum.SPO2 &&
                        <>{t(['This chart shows the daily percentage of time for which the SpO2 measurements are outside the defined thresholds.', 'infoButton.eventsPerDay.spo2'])}&nbsp;</>
                    }
                    {t(['Notice that only reliable data are considered.', 'infoButton.eventsPerDay.reliableData'])}
                </p>
                <p>
                    {t(['For each night, the recording duration is also displayed, taking only the reliable data into account.', 'infoButton.eventsPerDay.duration'])}
                </p>
            </>;

            const signalTypeLow = colorPalette.signalTypeLight;

            return (
                <StyledWrapper className="health-report-events-per-day" color={colorPalette.signalTypeLight[getSignalTypeIdentifier(signalType)]}>
                    {/*<pre>*/}
                    {/*    {JSON.stringify(eventsPerDay, null, '  ')}*/}
                    {/*</pre>*/}
                    <ChartContainer
                        title={title}
                        subtitle={subtitle}
                        infoTooltipTitle={`${t(`global.${getSignalTypeIdentifier(signalType)}`)} - ${t(['daily events', 'infoButton.eventsPerDay.title'])}`}
                        infoTooltipText={tooltipText}
                        infoTooltipOverlayPosition="bottomLeft"
                    >
                        {bestHasData < HasData.HAS_MIN_RELIABLE_DATA ? (
                            <div className="empty-message">
                                {(bestHasData === HasData.HAS_NO_DATA) && (
                                    t('healthReport.events.last7nights.noRecording', {
                                        nbDays: nbDays,
                                    })
                                )}
                                {(bestHasData === HasData.HAS_DATA) && (
                                    t('healthReport.events.last7nights.noReliableData', {
                                        nbDays: nbDays,
                                    })
                                )}
                                {(bestHasData === HasData.HAS_RELIABLE_DATA) && (
                                    t('healthReport.events.last7nights.notEnoughReliableData', {
                                        nbDays: nbDays,
                                    })
                                )}
                            </div>
                        ) : (eventsPerDayNotZero <= 0) ? (
                            <div className="empty-message">
                                {t('healthReport.events.last7nights.noEvent', {
                                    nbDays: nbDays,
                                })}
                            </div>
                        ) : (
                            <BarChart height={250} data={eventsPerDay} margin={{ top: 0, right: 0, bottom: 0, left: 10 }} onClick={handleClick} stackOffset="sign">
                                <XAxis dataKey="date" interval="equidistantPreserveStart" tick={<ChartAxisTickDate textAnchor="middle" />} />
                                <YAxis
                                    orientation="left"
                                    dataKey="events"
                                    width={40}
                                    domain={domainForEvents.domain}
                                    ticks={domainForEvents.ticks}
                                    interval={0}
                                    allowDataOverflow={true}
                                    tick={(props) => <ChartAxisTickColored {...props} alignUpTick fill={colorPalette.signalTypeLight[getSignalTypeIdentifier(signalType)]} textAnchor="end" />}
                                    label={(props: ChartDualLabelProps) => (
                                        <ChartDualLabel
                                            {...props}
                                            fill={colorPalette.signalTypeLight[getSignalTypeIdentifier(signalType)]}
                                            aboveText={`${t(['Above', 'global.above'])} ${signalEventConfiguration.high?.value}${getSignalTypeUnit(signalType)}`}
                                            belowText={`${t(['Below', 'global.below'])} ${signalEventConfiguration.low?.value }${getSignalTypeUnit(signalType)}`}
                                        />
                                    )}
                                />
                                {/* Invisible YAxis to align with HealthReportPercentagePerDay : */}
                                <YAxis
                                    yAxisId="right"
                                    orientation="right"
                                    dataKey="duration"
                                    width={34}
                                    interval={0}
                                    strokeWidth={0}
                                    tick={false}
                                    stroke={'#ff0000'}
                                    padding={{ top: 10 }}
                                />
                                <Tooltip content={(data) => renderTooltip(data, t)} isAnimationActive={false}/>
                                <ReferenceLine
                                    y={0}
                                    stroke={'rgba(255,255,255, 0.2)'}
                                />
                                {thresholdTypes.includes(EventThresholdType.LOW) && (
                                    <Bar
                                        minPointSize={(_, index) => eventsPerDay[index].reliableDurationInSeconds > 0 ? -1 : 0}
                                        stackId="stack"
                                        //dot={{ fill: signalTypeLow[getSignalTypeIdentifier(signalType)] }}
                                        dataKey={'below'}
                                        fill={signalTypeLow[getSignalTypeIdentifier(signalType)]}
                                    />
                                )}
                                {thresholdTypes.includes(EventThresholdType.HIGH) && (
                                    <Bar
                                        minPointSize={(_, index) => eventsPerDay[index].reliableDurationInSeconds > 0 ? 1 : 0}
                                        stackId="stack"
                                        //dot={{ fill: colorPalette.signalType[getSignalTypeIdentifier(signalType)] }}
                                        dataKey="above"
                                        fill={colorPalette.signalType[getSignalTypeIdentifier(signalType)]}
                                    />
                                )}
                            </BarChart>
                        )}
                    </ChartContainer>
                </StyledWrapper>
            );
        }
        else {
            return (
                <StyledWrapper className="health-report-events-per-day" color={colorPalette.signalTypeLight[getSignalTypeIdentifier(signalType)]}>
                    <ChartContainer
                        title={title}
                        subtitle={subtitle}
                    >
                        <LoadingView color={colorPalette.signalType[getSignalTypeIdentifier(signalType)]} />
                    </ChartContainer>
                </StyledWrapper>
            );
        }
    }
    else {
        return <ComponentErrorMessage component="HealthReportEventsPerDay" />;
    }
}

//language=SCSS
const StyledWrapper = styled.div`
& {
    position: relative;
    min-height: 260px;
    height: 100%;
    display: flex; 
    flex-direction: column;
    
    > * {
      flex-grow: 1;
    }

    .tooltip-events {
        color: ${props => props.color};
    }

    .tooltip-duration {
        color: ${colorPalette.clearColor};
    }
    
    svg {
        cursor: pointer;
    }

    .average-block {
        position: relative;
        font-size: 13px;

        p {
            margin-bottom: 5px;
            text-align: right;
            white-space: nowrap;
        }

        p span {
            color: ${props => props.color};
        }

        img.warningFlag {
            width: 15px;
            margin-right: 7px;
            margin-top: -3px;
        }
    }
}
`;

export {HealthReportEventsPerDay};
