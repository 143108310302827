import PropTypes from 'prop-types';
import TableBody from '@/components/table/table-sortable-body';
import TableHead from '@/components/table/table-sortable-head';
import ComponentErrorMessage from '@/components/widgets/component-error-message';
import { useSortableTable } from '@/hooks/useSortableTable';

const TableSortable = ({ data, columns, defaultSorting, onShowSignalSample, TableBodyRowComponent }) => {
    const [tableData, handleSorting] = useSortableTable(data, columns, defaultSorting);

    const onShowSignalSampleExample = (rowData, posX, posY) => {
        onShowSignalSample(rowData, posX, posY);
    };

    if(data && columns && defaultSorting) {
        return (
            <>
                <table>
                    <TableHead columns={columns} handleSorting={handleSorting} />
                    <TableBody columns={columns} tableData={tableData} onShowSignalSample={(rowData, posX, posY) => onShowSignalSampleExample(rowData, posX, posY)} TableBodyRowComponent={TableBodyRowComponent} />
                </table>
            </>
        );
    } else {
        return <ComponentErrorMessage component="TableSortable" />;
    }
};

TableSortable.propTypes = {
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    defaultSorting: PropTypes.object.isRequired,
    onShowSignalSample: PropTypes.func,
    TableBodyRowComponent: PropTypes.elementType,
};

export default TableSortable;
