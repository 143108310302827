import React from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import {colorPalette} from '@/themes/darkmode.js';
import {BackendApiService} from '@/services/backend-api-service.js';
import LoadingView from '@/components/static/loading-view.tsx';
import PatientOnboardingPrintContent from '@/components/business/analytics/patient/patient-update/patient-update-onboarding-print-content.jsx';
import {AppLogoFull} from '@/components/static/app-logo-full';

class PatientOnboardingPrint extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        patientId: PropTypes.string.isRequired,
        qrCode: PropTypes.object.isRequired,
    };

    state = {
        loading: false,
        patient: null,
    };

    componentDidMount() {
        this.fetchPatient();
    }

    render() {
        const props = this.props;
        const state = this.state;

        return (
            <div className={`patient-onboarding-print ${props.className}`}>
                <header className="text-left">
                    <AppLogoFull height={50} className="login-logo"/>
                </header>
                {!state.loading && state.patient &&
                <PatientOnboardingPrintContent patient={state.patient} qrCode={props.qrCode}/>
                }
                {state.loading &&
                    <LoadingView />
                }
            </div>
        );
    }

    fetchPatient() {
        this.setState(state => ({
            ...state,
            loading: true,
        }));

        BackendApiService.getRequest({
            domain: 'patient',
            modelName: 'getPatientDetails',
            data: {
                patientId: this.props.patientId,
            }
        }).then((patient) => {
            this.setState(state => ({
                ...state,
                loading: false,
                patient: patient,
            }));
            return patient;
        }).catch(err => {
            console.error(err);
        });
    }
}

//language=SCSS
PatientOnboardingPrint = Styled(PatientOnboardingPrint)`
& {
    text-align:center;
    
    header {
        width:90%;
        margin:0 auto;
        height:70px;
        background:${colorPalette.secondBackground};
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
        padding:10px 30px;
    }
}
`;

export default PatientOnboardingPrint;

