import React from 'react';
import Styled from 'styled-components';
import autoBind from 'react-autobind';
import {Button} from '@/components/buttons/button.jsx';
import {BackendApiService} from '@/services/backend-api-service.js';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';

class GroupRename extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        t: PropTypes.func,
        group: PropTypes.object.isRequired,
        onRenamed: PropTypes.func,
        onCancelled: PropTypes.func
    };

    state = {
        name: this.props.group.name,
        loading: false,
    };

    constructor(props) {
        super(props);
        autoBind(this);
    }

    isFilled() {
        const state = this.state;
        return (
            state.name && state.name !== ''
        );
    }

    render() {
        const props = this.props;
        const state = this.state;
        const { t } = this.props;

        return (
            <div className={props.className}>
                <form onSubmit={this.handleSubmit}>
                    <div className="fieldWrapper">
                        <label htmlFor="renameGroup-name" className={`mandatoryField ${(this.state.name === '') ? 'empty' : 'filled'}`}>{t(['Name of the group', 'renameGroup.name'])}</label>
                        <input name="name" id="renameGroup-name" value={this.state.name} required aria-required="true" autoFocus onChange={this.handleChangeName} />
                    </div>
                    <div>
                        <Button className="button-create" disabled={!this.isFilled()} loading={state.loading}>{t(['Rename group', 'renameGroup.confirm'])}</Button>
                        <Button className="button-cancel" onClick={this.handleCancel}>{t(['Cancel', 'renameGroup.cancel'])}</Button>
                    </div>
                </form>
            </div>
        );
    }

    handleChangeName(e) {
        this.setState(state => ({
            ...state,
            name: e.target.value,
        }));
    }

    handleSubmit(e) {
        e.preventDefault();
        this.renameGroup();
    }

    handleCancel(e) {
        e.preventDefault();
        if(this.props.onCancelled) {
            this.props.onCancelled();
        }
    }

    async renameGroup() {
        try {
            this.setState(state => ({
                ...state,
                loading: true,
            }));

            const group = await BackendApiService.getRequest({
                domain: 'group',
                modelName: 'renameGroup',
                data: {
                    id: this.props.group.groupId.id,
                    name: this.state.name
                }
            });

            this.setState(state => ({
                ...state,
                loading: false,
                name: '',
            }));
            if(this.props.onRenamed) {
                this.props.onRenamed(group);
            }
        }
        catch (err) {
            console.error(err);
            this.setState(state => ({
                ...state,
                loading: false,
            }));
        }
    }
}

//language=SCSS
GroupRename = Styled(GroupRename)`
& {
    
}
`;

export default withTranslation()(GroupRename);
