import React from 'react';
import Styled from 'styled-components';
import PropTypes from 'prop-types';
import deepEqual from 'deep-eql';
import {UserPic} from '@/components/static/user-pic.jsx';
import {colorPalette} from '@/themes/darkmode.js';
import hcpDefaultPic from '@/public/icons/hcp-default-pic.svg';
import {withTranslation} from 'react-i18next';

class GroupPendingUserDetails extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        t: PropTypes.func,
        email: PropTypes.string.isRequired,
        groupId: PropTypes.string.isRequired,
    };

    state = {};

    render() {
        const props = this.props;
        const { t } = this.props;

        return (
            <div className={`${this.props.className} user-block`}>
                <div>
                    <UserPic pic={hcpDefaultPic} alt={t(['Generic user avatar picture', 'groupPendingUserDetails.userPicAlt'])} width={70}/>
                    <span className="userDetails">
                        {props.email}
                    </span>
                </div>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !deepEqual(this.props, nextProps) || !deepEqual(this.state, nextState);
    }
}

//language=SCSS
GroupPendingUserDetails = Styled(GroupPendingUserDetails)`
& {
    .userDetails {
        display: inline-block;
        vertical-align: top;
        padding-top: 22px;
        padding-left: 10px;
        width: calc(100% - 110px);
        word-break: break-all;
        span {
            color:${colorPalette.clearColor};
        }
    }
}
`;

export default withTranslation()(GroupPendingUserDetails);
