import {differenceInDays} from 'date-fns';
import {SignalEventConfiguration} from 'gabi-api-js/patient/patient_common_pb';
import {
    AlertType,
    Average, AverageForDay,
    EventsPerDay,
    HasData,
    Trend,
    UnifiedSignalDurations
} from 'gabi-api-js/signal/signal_query_pb';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Bar, ComposedChart, Line, Tooltip, XAxis, YAxis} from 'recharts';
import {CategoricalChartFunc} from 'recharts/types/chart/generateCategoricalChart';
import {TooltipProps} from 'recharts/types/component/Tooltip';
import styled from 'styled-components';

import {AverageValue} from '@/components/business/analytics/health-report/health-report-average-value';
import {HealthReportStatisticsTrend} from '@/components/business/analytics/health-report/health-report-statistics-trend';
import {ChartAxisTickColored} from '@/components/business/analytics/patient/patient-chart-axis/chart-axis-tick-colored';
import {ChartAxisTickDate} from '@/components/business/analytics/patient/patient-chart-axis/chart-axis-tick-date';
import {ChartAxisTickDuration} from '@/components/business/analytics/patient/patient-chart-axis/chart-axis-tick-duration';
import {getDomainForDuration, getDomainForEvents} from '@/components/business/analytics/timeline/timeline-chart-domains';
import {ChartContainer} from '@/components/layout/chart-container';
import LoadingView from '@/components/static/loading-view';
import ComponentErrorMessage from '@/components/widgets/component-error-message';
import {getSignalTypeIdentifier, getSignalTypeUnit, SignalTypeEnum} from '@/enum/signal-type-enum';
import {useBackendQuery} from '@/hooks/use-backend-query';
import {BackendApiService} from '@/services/backend-api-service';
import {colorPalette} from '@/themes/darkmode';
import {EventThresholdType} from '@/types/event-threshold-type';
import {getMax} from '@/util/array-util';
import {formatDateUniversal, formatSecondsToHms} from '@/util/date-utils';
import {roundTo} from '@/util/number-util';
import {roundDurationHoursToQuarterPrecision} from '@/util/time-utils';

type HealthReportPercentagePerDayProps = {
    patientId: string;
    signalType: number;
    thresholdType: EventThresholdType;
    lastRecordingDate: Date;
    dateFrom: Date;
    dateTo: Date;
    ignoreUnifiedDuration?: boolean;
    signalEventConfiguration: SignalEventConfiguration.AsObject;
    displayPreviousNights: boolean;
    lightColor?: boolean;
    onClick?: (date: Date) => void;
}

type EventFormatted = {
    date: string;
    events: number | null;
    hasEnoughData: boolean;
    reliableDurationInSeconds: number;
    duration: number | null;
};

function getAlertType(thresholdType: EventThresholdType): AlertType {
    switch(thresholdType) {
    case EventThresholdType.HIGH:
        return AlertType.ALERT_HIGH;
    case EventThresholdType.LOW:
        return AlertType.ALERT_LOW;
    }
}

function HealthReportPercentagePerDay({patientId, signalType, lastRecordingDate, thresholdType, dateFrom, dateTo, ignoreUnifiedDuration, signalEventConfiguration, displayPreviousNights, lightColor, onClick}: HealthReportPercentagePerDayProps) {
    const { t } = useTranslation();
    const [eventsPerDay, setEventsPerDay] = useState<EventFormatted[] | null>(null);
    const [averageEventsPerHourForDay, setAverageEventsPerHourForDay] = useState<AverageForDay.AsObject | null>(null);
    const [averageEventsPerHoursForPeriod, setAverageEventsPerHoursForPeriod] = useState<Average.AsObject | null>(null);
    const [loading, setLoading] = useState(true);

    const nbDays = displayPreviousNights ? 15 : differenceInDays(dateTo, dateFrom)+1;

    const [unifiedDurationLoading, unifiedDuration] = useBackendQuery<UnifiedSignalDurations.AsObject, number>({
        domain: 'signal',
        modelName: 'getSignalsUnifiedDuration',
        data: {
            patientId: patientId,
            signalTypes: [SignalTypeEnum.PULSE_RATE, SignalTypeEnum.SPO2],
            dateFrom: dateFrom,
            dateTo: dateTo,
        },
        memoize: true,
        callback: (response) => {
            return response.maxUnifiedDurationInSeconds;
        },
    });

    useEffect(() => {
        const fetch = async () => {
            setLoading(true);
            const promises = [
                getEventsPerDay(),
                getAverageEventsPerHoursForDay(),
            ];
            if (!displayPreviousNights) {
                promises.push(getAverageEventsPerHoursForPeriod());
            }
            await Promise.all(promises);
            setLoading(false);
        };
        fetch();
    }, [signalEventConfiguration, thresholdType, dateFrom, dateTo]);

    const getEventsPerDay = async () => {
        try {
            const modelName = (() => {
                switch (thresholdType) {
                case EventThresholdType.LOW: return 'getEventsLowPerDay';
                case EventThresholdType.HIGH: return 'getEventsHighPerDay';
                }
            })();

            try {
                const response = await BackendApiService.getRequest({
                    domain: 'signal',
                    modelName: modelName,
                    data: {
                        patientId: patientId,
                        signalType: signalType,
                        dateFrom: dateFrom,
                        dateTo: dateTo,
                    }
                });
                const eventsPerDayFormatted: EventFormatted[] = [];
                const eventsPerDayList = (response as EventsPerDay.AsObject).eventsPerDayList;

                eventsPerDayList
                    .forEach(event => {
                        const hasEnoughData = event.hasRecording && event.hasData >= HasData.HAS_MIN_RELIABLE_DATA;
                        eventsPerDayFormatted.push({
                            date: event.date,
                            hasEnoughData: hasEnoughData,
                            events: (hasEnoughData && event.reliableDurationInSeconds > 0) ? event.events : null,
                            reliableDurationInSeconds: event.reliableDurationInSeconds,
                            duration: (hasEnoughData && event.reliableDurationInSeconds > 0) ? roundDurationHoursToQuarterPrecision(event.reliableDurationInSeconds) : null,
                        });
                    });

                setEventsPerDay(eventsPerDayFormatted);
                return response;
            }
            catch(err) {
                console.error(err);
            }
        }
        catch(err) {
            console.error(err);
        }
    };

    const getAverageEventsPerHoursForDay = async () => {
        try {
            const alertType = getAlertType(thresholdType);
            const response = (await BackendApiService.getRequest({
                domain: 'signal',
                modelName: 'getAverageEventsPerHoursForDay',
                data: {
                    patientId: patientId,
                    signalType: signalType,
                    alertType: alertType,
                    day: lastRecordingDate,
                    nbPreviousDays: 14,
                }
            }));

            setAverageEventsPerHourForDay(response);
        }
        catch(err) {
            console.error('getAverageEventsPerHoursForDay ERROR');
            console.error(err);
        }
    };

    const getAverageEventsPerHoursForPeriod = async () => {
        try {
            const alertType = getAlertType(thresholdType);
            const response = (await BackendApiService.getRequest({
                domain: 'signal',
                modelName: 'getAverageEventsPerHoursForPeriod',
                data: {
                    patientId: patientId,
                    signalType: signalType,
                    alertType: alertType,
                    dateFrom: dateFrom,
                    dateTo: dateTo,
                }
            }));

            setAverageEventsPerHoursForPeriod(response);
        }
        catch(err) {
            console.error('getAverageEventsPerHoursForPeriod ERROR');
            console.error(err);
        }
    };

    const handleClick: CategoricalChartFunc = (rechartData) => {
        if (onClick) {
            if (rechartData && rechartData.activePayload && rechartData.activePayload[0]) {
                const rechartsPayload = rechartData?.activePayload[0].payload;
                if (rechartsPayload) {
                    const selectedDate = new Date(rechartsPayload.date);
                    const duration = rechartsPayload.reliableDurationInSeconds;
                    if (duration && duration > 0) {
                        onClick(selectedDate);
                    }
                }
            }
        }
    };

    const renderTooltip = (data: TooltipProps<number | string | Array<number | string>, string | number>, t: (keys: string[], args?: object) => string) => {
        if (data?.payload) {
            const events = data?.payload[0]?.payload?.events;
            const reliableDurationInSeconds = data?.payload[0]?.payload?.reliableDurationInSeconds;

            return (
                <div className="chart-tooltip">
                    <strong>{formatDateUniversal(new Date(data?.payload[0]?.payload?.date + ' 00:00:00'))}</strong><br />
                    <span className="tooltip-duration">{t(['Duration', 'healthReport.events.last7nights.duration'])}: {formatSecondsToHms(reliableDurationInSeconds)}</span>
                    {signalType === SignalTypeEnum.SPO2 ?
                        <span className="tooltip-events">{roundTo(events, 2)}%</span>
                        :
                        <span className="tooltip-events">{roundTo(events, 2)} {t(['events/h', 'global.eventsPerHour'])}</span>
                    }
                </div>
            );
        }
        return null;
    };

    if (patientId && dateFrom && dateTo && signalEventConfiguration) {
        const title = (() => {
            switch(signalType) {
            case SignalTypeEnum.PULSE_RATE: return t(['Pulse Rate - events', 'healthReport.events.last7nights.pulseRateTitle']);
            case SignalTypeEnum.SPO2: return t(['SpO2 - percentage', 'healthReport.events.last7nights.spo2Title']);
            case SignalTypeEnum.MOVEMENTS: return ''; // not used
            }
            return '';
        })();

        const subtitle = (() => {
            const out: string[] = [];
            if (thresholdType === EventThresholdType.LOW) {
                out.push(`${t(['Below', 'global.below'])} ${signalEventConfiguration.low?.value}${getSignalTypeUnit(signalType)}`);
            }
            if (thresholdType === EventThresholdType.HIGH) {
                out.push(`${t(['Above', 'global.above'])} ${signalEventConfiguration.high?.value}${getSignalTypeUnit(signalType)}`);
            }

            return out.join(' or ');
        })();

        const signalColors = lightColor ? colorPalette.signalTypeLight : colorPalette.signalType;

        if(!loading && !unifiedDurationLoading && eventsPerDay) {
            const eventsPerDayNotZero = eventsPerDay.filter(x => x.events && x.events !== 0).length;

            const averageUnit = (() => {
                switch(signalType) {
                case SignalTypeEnum.PULSE_RATE: return (
                    <span>{' '}{t(['events/h', 'global.eventsPerHour'])}</span>
                );
                case SignalTypeEnum.SPO2: return '%';
                default:
                case SignalTypeEnum.MOVEMENTS: return '';
                }
            })();

            const topRightContent = displayPreviousNights ? (
                <div className="average-block">
                    <p>
                        <HealthReportStatisticsTrend
                            hasData={Math.min(
                                averageEventsPerHourForDay?.averageValue?.hasData ?? HasData.HAS_NO_DATA,
                                averageEventsPerHourForDay?.averagePrevious?.hasData ?? HasData.HAS_NO_DATA
                            )}
                            trend={averageEventsPerHourForDay?.trend ?? Trend.NORMAL}
                        />
                        {t(['Last night avg:', 'healthReport.aggregatedSignal.avgRecording'])}{' '}
                        <AverageValue averageObj={averageEventsPerHourForDay?.averageValue ?? null} unit={averageUnit} precision={2} />
                    </p>
                    <p>
                        {t(['Prev. 14 nights avg:', 'healthReport.aggregatedSignal.avgLast14Nights'])}{' '}
                        <AverageValue averageObj={averageEventsPerHourForDay?.averagePrevious ?? null} unit={averageUnit} precision={2} />
                    </p>
                </div>
            ) : (
                <div className="average-block">
                    <p>
                        {t(['Avg for period:', 'healthReport.statistics.averageForPeriod'])}{' '}
                        <AverageValue averageObj={averageEventsPerHoursForPeriod ?? null} unit={averageUnit} precision={2} />
                    </p>
                </div>
            );

            const tooltipText = <>
                <p>
                    {signalType === SignalTypeEnum.PULSE_RATE &&
                        <>{t(['This chart shows the daily average of the pulse rate events/h.', 'infoButton.eventsPerDay.pulseRate'])}&nbsp;</>
                    }
                    {signalType === SignalTypeEnum.SPO2 &&
                        <>{t(['This chart shows the daily percentage of time for which the SpO2 measurements are outside the defined thresholds.', 'infoButton.eventsPerDay.spo2'])}&nbsp;</>
                    }
                    {t(['Notice that only reliable data are considered.', 'infoButton.eventsPerDay.reliableData'])}
                </p>
                <p>
                    {t(['For each night, the recording duration is also displayed, taking only the reliable data into account.', 'infoButton.eventsPerDay.duration'])}
                </p>
            </>;

            const eventsDomain = getDomainForEvents((eventsPerDay.length === 0 ? 0 : getMax(eventsPerDay, (event) => event.events ?? 0).events) ?? 0, false);

            const maxDuration = ignoreUnifiedDuration ?
                (eventsPerDay.length === 0 ? 0 : getMax(eventsPerDay, (event) => event.reliableDurationInSeconds).reliableDurationInSeconds) :
                unifiedDuration!;
            const durationDomain = getDomainForDuration(maxDuration);

            const bestHasData: HasData = (() => {
                if (displayPreviousNights) {
                    const hasDataForDay = averageEventsPerHourForDay!.averageValue!.hasData ?? HasData.HAS_NO_DATA;
                    const hasDataForPrevious = averageEventsPerHourForDay!.averagePrevious!.hasData ?? HasData.HAS_NO_DATA;
                    return Math.max(hasDataForDay, hasDataForPrevious);
                }
                else {
                    return averageEventsPerHoursForPeriod?.hasData ?? HasData.HAS_NO_DATA;
                }
            })();

            return (
                <StyledWrapper className="health-report-percentage-per-day" color={colorPalette.signalTypeLight[getSignalTypeIdentifier(signalType)]}>
                    <ChartContainer
                        title={title}
                        subtitle={subtitle}
                        topRightContent={topRightContent}
                        infoTooltipTitle={`${t(`global.${getSignalTypeIdentifier(signalType)}`)} - ${t(['daily events', 'infoButton.eventsPerDay.title'])}`}
                        infoTooltipText={tooltipText}
                        infoTooltipOverlayPosition="bottomLeft"
                    >
                        {(bestHasData < HasData.HAS_MIN_RELIABLE_DATA) ? (
                            <div className="empty-message">
                                {(bestHasData === HasData.HAS_NO_DATA) && (
                                    t('healthReport.events.last7nights.noRecording', {
                                        nbDays: nbDays,
                                    })
                                )}
                                {(bestHasData === HasData.HAS_DATA) && (
                                    t('healthReport.events.last7nights.noReliableData', {
                                        nbDays: nbDays,
                                    })
                                )}
                                {(bestHasData === HasData.HAS_RELIABLE_DATA) && (
                                    t('healthReport.events.last7nights.notEnoughReliableData', {
                                        nbDays: nbDays,
                                    })
                                )}
                            </div>
                        ) : (eventsPerDayNotZero <= 0) ? (
                            <div className="empty-message">
                                {t('healthReport.events.last7nights.noEvent', {
                                    nbDays: nbDays,
                                })}
                            </div>
                        ) : (
                            <ComposedChart height={250} data={eventsPerDay} margin={{ top: 0, right: 0, bottom: 0, left: 10 }} onClick={handleClick}>
                                <XAxis dataKey="date" interval="equidistantPreserveStart" tick={<ChartAxisTickDate textAnchor="middle" />} />
                                <YAxis
                                    yAxisId="left"
                                    orientation="left"
                                    dataKey="events"
                                    width={26}
                                    domain={eventsDomain.domain}
                                    ticks={eventsDomain.ticks}
                                    interval={0}
                                    padding={{ top: 10 }}
                                    tick={(props) => <ChartAxisTickColored {...props} fill={colorPalette.signalTypeLight[getSignalTypeIdentifier(signalType)]} textAnchor="end" />}
                                />
                                <YAxis
                                    yAxisId="right"
                                    orientation="right"
                                    dataKey="duration"
                                    width={34}
                                    domain={durationDomain.domain}
                                    ticks={durationDomain.ticks}
                                    interval={0}
                                    tick={<ChartAxisTickDuration textAnchor="start" />}
                                    padding={{ top: 10 }}
                                />
                                <Bar yAxisId="right" dataKey="duration" barSize={40} fill={colorPalette.thirdBackground}/>
                                <Line
                                    type="step"
                                    dot={{ fill: signalColors[getSignalTypeIdentifier(signalType)] }}
                                    dataKey="events"
                                    stroke={signalColors[getSignalTypeIdentifier(signalType)]}
                                    strokeWidth={2}
                                    isAnimationActive={false}
                                    yAxisId="left"
                                />
                                <Tooltip content={(data) => renderTooltip(data, t)} isAnimationActive={false} />
                            </ComposedChart>
                        )}
                    </ChartContainer>
                </StyledWrapper>
            );
        }
        else {
            return (
                <StyledWrapper className="health-report-events-per-day" color={colorPalette.signalTypeLight[getSignalTypeIdentifier(signalType)]}>
                    <ChartContainer
                        title={title}
                        subtitle={subtitle}
                    >
                        <LoadingView color={signalColors[getSignalTypeIdentifier(signalType)]} />
                    </ChartContainer>
                </StyledWrapper>
            );
        }
    }
    else {
        return <ComponentErrorMessage component="HealthReportPercentagePerDay" />;
    }
}

//language=SCSS
const StyledWrapper = styled.div`
& {
    position: relative;
    min-height: 260px;
    height: 100%;
    display: flex; 
    flex-direction: column;
    
    > * {
      flex-grow: 1;
    }

    .tooltip-events {
        color: ${props => props.color};
    }

    .tooltip-duration {
        color: ${colorPalette.clearColor};
    }
    
    svg {
        cursor: pointer;
    }
    
    .average-block {
        position: relative;
        font-size: 13px;

        p {
            margin-bottom: 5px;
            text-align: right;
            white-space: nowrap;
        }

        p span {
            color: ${props => props.color};
        }

        img.warningFlag {
            width: 15px;
            margin-right: 7px;
            margin-top: -3px;
        }
    }
}
`;

export {HealthReportPercentagePerDay};
