import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';
import {LineChart, XAxis, YAxis} from 'recharts';
import Styled from 'styled-components';

import {ChartAxisTickColored} from '@/components/business/analytics/patient/patient-chart-axis/chart-axis-tick-colored';
import {getDomainForSignalType} from '@/components/business/analytics/timeline/timeline-chart-domains';
import {subchartsPropType, TimelineChartPlot} from '@/components/business/analytics/timeline/timeline-chart-plot';
import {TimelineChartZoomArea} from '@/components/business/analytics/timeline/timeline-chart-zoom-area';
import {Autosized} from '@/components/layout/autosized';
import {FlexChild, FlexLayout} from '@/components/layout/flex-layout';
import {getSignalTypeIdentifier, SignalTypeEnum} from '@/enum/signal-type-enum';
import {colorPalette} from '@/themes/darkmode';

class TimelineChart extends React.Component {
    static propTypes = {
        baseDate: PropTypes.instanceOf(Date),
        className: PropTypes.string,
        endTime: PropTypes.number.isRequired,
        eventConfiguration: PropTypes.object.isRequired,
        height: PropTypes.number.isRequired,
        id_patient: PropTypes.string.isRequired,
        medicalEvents: PropTypes.object.isRequired,
        ranges: PropTypes.object.isRequired,
        scrollBarCursorWidth: PropTypes.number.isRequired,
        scrollX: PropTypes.number.isRequired,
        signals: PropTypes.object.isRequired,
        startTime: PropTypes.number.isRequired,
        subcharts: subchartsPropType,
        width: PropTypes.number.isRequired,
        onScroll: PropTypes.func,
        onZoomChart: PropTypes.func,
        onDisplayTooltip: PropTypes.func
    };

    state = {
        zoomAreaStartTimestamp: null,
        zoomAreaStartCoordinate: {x: 0, y: 0},
        zoomAreaEndTimestamp: null,
        zoomAreaEndCoordinate: {x: 0, y: 0},
        zoomSelectedSubchart: null,
        tooltip: {
            positionX: 0,
            positionY: 0,
            subchartType: null,
            value: 0,
            timestamp: null,
        },
    };
    
    static yaxisWidth = 83;
    
    chartData = null;
    
    constructor(props) {
        super(props);
        autoBind(this);
        this.computeChartData(props);
    }
    
    getSubCharts() {
        return (this.props.subcharts.slice() || [
            SignalTypeEnum.PULSE_RATE,
            SignalTypeEnum.SPO2,
            SignalTypeEnum.MOVEMENTS,
        ]).sort();
    }
    
    computeChartData(props) {
        const chartData = props.signals;

        if (chartData) {
            this.chartData = {};
            this.chartData.hr = chartData.hr;
            this.chartData.spo2 = chartData.spo2;
            this.chartData.actigraphy = chartData.actigraphy;
        }
    }
    
    render() {
        const props = this.props;
        const state = this.state;
        let scrollX = props.scrollX;
        const width = props.width - TimelineChart.yaxisWidth;
        const height = props.height;

        if(scrollX < 0) {
            scrollX = 0;
        }
        if(scrollX > (1 - (props.scrollBarCursorWidth / 100))) {
            scrollX = 1 - (props.scrollBarCursorWidth / 100);
        }

        if (!props.signals) {
            return null;
        }
        else {
            const chartData = this.chartData;
            const _subcharts = this.getSubCharts();
            const subcharts = _subcharts.reduce((acc, item) => {
                acc[item] = true;
                return acc;
            }, {});
            
            const domainX = [
                props.startTime,
                props.endTime,
            ];
            
            const domainYhr = getDomainForSignalType(SignalTypeEnum.PULSE_RATE, props.ranges.hr[0], props.ranges.hr[1]);
            const domainYspo2 = getDomainForSignalType(SignalTypeEnum.SPO2, props.ranges.spo2[0], props.ranges.spo2[1]);
            const domainYactigraphy = getDomainForSignalType(SignalTypeEnum.MOVEMENTS, props.ranges.actigraphy[0], props.ranges.actigraphy[1]);

            return (
                <div className={props.className} style={{position: 'absolute', height: 'calc(100% - 85px)', width: '100%', top: '85px'}}>
                    <FlexLayout direction="row">
                        <FlexChild grow={0} width={TimelineChart.yaxisWidth}>
                            <FlexLayout direction='column' style={{ width: '100%' }}>
                                {subcharts[SignalTypeEnum.PULSE_RATE] && this.renderSubChartYAxis(SignalTypeEnum.PULSE_RATE, domainX, [
                                    <YAxis key="yaxis" dataKey="hrRange" type="number" name="value"
                                        label={{ value: 'Pulse', position: 'insideLeft', angle: -90, dx: -18, dy: 20, style: {fill: colorPalette.signalType[getSignalTypeIdentifier(SignalTypeEnum.PULSE_RATE)]} }}
                                        tick={(props) => <ChartAxisTickColored {...props} alignUpTick alignDownTick fill={colorPalette.signalTypeLight[getSignalTypeIdentifier(SignalTypeEnum.PULSE_RATE)]} unit={'bpm'} />}
                                        domain={domainYhr.domain}
                                        ticks={domainYhr.ticks}
                                        interval={0}
                                        allowDataOverflow={true}
                                    />,
                                ])}
                                {subcharts[SignalTypeEnum.SPO2] && this.renderSubChartYAxis(SignalTypeEnum.SPO2, domainX, [
                                    <YAxis key="yaxis" dataKey="spo2Range" type="number" name="value"
                                        label={{ value: 'SpO2', position: 'insideLeft', angle: -90, dx: -19, dy: 20, style: {fill: colorPalette.signalType[getSignalTypeIdentifier(SignalTypeEnum.SPO2)]} }}
                                        tick={(props) => <ChartAxisTickColored {...props} alignUpTick alignDownTick fill={colorPalette.signalTypeLight[getSignalTypeIdentifier(SignalTypeEnum.SPO2)]} unit={'%'} />}
                                        domain={domainYspo2.domain}
                                        ticks={domainYspo2.ticks}
                                        interval={0}
                                        allowDataOverflow={true}
                                    />,
                                ])}
                                {subcharts[SignalTypeEnum.MOVEMENTS] && this.renderSubChartYAxis(SignalTypeEnum.MOVEMENTS, domainX, [
                                    <YAxis key="yaxis" dataKey="actigraphyRange" type="number" name="value"
                                        label={{ value: 'Movements', position: 'insideLeft', angle: -90, dx: -19, dy: 40, style: {fill: colorPalette.signalType[getSignalTypeIdentifier(SignalTypeEnum.MOVEMENTS)]} }}
                                        tick={(props) => <ChartAxisTickColored {...props} alignUpTick decimals={2} fill={colorPalette.signalTypeLight[getSignalTypeIdentifier(SignalTypeEnum.MOVEMENTS)]} unit={'G'} />}
                                        domain={domainYactigraphy.domain}
                                        ticks={domainYactigraphy.ticks}
                                        interval={0}
                                        allowDataOverflow={true}
                                    />,
                                ])}
                            </FlexLayout>
                        </FlexChild>
                        <FlexChild grow={1} width={1} style={{overflow: 'hidden'}}>
                            <div className="fullh" style={{ width: width, willChange: 'transform', transform: `translate(${-scrollX*100}%,0)`}}>
                                <TimelineChartPlot
                                    baseDate={props.baseDate}
                                    chartData={chartData}
                                    domainX={domainX}
                                    eventConfiguration={props.eventConfiguration}
                                    height={height}
                                    medicalEvents={props.medicalEvents}
                                    ranges={props.ranges}
                                    subcharts={_subcharts}
                                    width={width}
                                    yaxisWidth={TimelineChart.yaxisWidth}
                                    //onScroll={this.handleScroll}
                                    onMouseDown={this.handleChartMouseDown}
                                    onMouseMove={this.handleChartMouseMove}
                                    onMouseUp={this.handleChartMouseUp}
                                />

                                <TimelineChartZoomArea
                                    startCoordinate={state.zoomAreaStartCoordinate}
                                    endCoordinate={state.zoomAreaEndCoordinate}
                                    subchartType={state.zoomSelectedSubchart}
                                />
                            </div>
                        </FlexChild>
                    </FlexLayout>
                </div>
            );
        }
    }
    
    renderSubChartYAxis(subchartType, domainX, content) {
        const props = this.props;
        const tickHeight = props.subcharts[props.subcharts.length-1] === subchartType ? undefined : 1;
        return (
            <FlexChild className="flex-chart-subchart-y-axis" grow={1} height={1}>
                <Autosized component={({height}) => {
                    return (
                        <LineChart
                            width={TimelineChart.yaxisWidth}
                            height={height-4}
                            margin={{
                                top: 0, right: 0, left: 20, bottom: 0,
                            }}
                            style={{display: 'inline-block', float: 'left'}}
                        >
                            {content}
                            <XAxis dataKey="secondsSince12pm" type="number" name="t" domain={domainX} tick={<div/>} height={tickHeight}/>,
                        </LineChart>
                    );
                }} />
            </FlexChild>
        );
    }

    handleChartMouseDown(entry) {
        if (entry && entry.activePayload && entry.activeCoordinate) {
            this.setState({
                zoomSelectedSubchart: entry.activePayload[0].payload.subchartType,
                zoomAreaStartCoordinate: entry.activeCoordinate,
                zoomAreaStartTimestamp: entry.activePayload[0].payload.secondsSince12pm
            });
        }
    }

    handleChartMouseMove(entry, e) {
        if (entry && entry.activeCoordinate && entry.activePayload) {
            const tooltipData = {
                positionX: e.pageX,
                positionY: e.pageY,
                subchartType: entry.activePayload[0].payload.subchartType,
                value: entry.activePayload[0].payload.value,
                reliableValue: entry.activePayload[0].payload.reliableValue,
                reliability: entry.activePayload[0].payload.reliability,
                secondsSince12pm: entry.activePayload[0].payload.secondsSince12pm,
            };
            this.props.onDisplayTooltip(tooltipData);

            if (this.state.zoomAreaStartCoordinate.x > 0) {
                this.setState({
                    zoomAreaEndCoordinate: entry.activeCoordinate,
                });
            }
        } else {
            this.props.onDisplayTooltip({});
        }
    }

    handleChartMouseUp(entry) {
        if (entry && entry.activePayload && entry.activeCoordinate) {
            let startTimeStamp = this.state.zoomAreaStartTimestamp;
            let endTimeStamp = entry.activePayload[0].payload.secondsSince12pm;

            if (startTimeStamp > endTimeStamp) {
                startTimeStamp = entry.activePayload[0].payload.secondsSince12pm;
                endTimeStamp = this.state.zoomAreaStartTimestamp;
            }

            const zoomData = {
                zoomSelectedSubchart: this.state.zoomSelectedSubchart,
                zoomAreaStartTimestamp: startTimeStamp,
                zoomAreaEndTimestamp: endTimeStamp,
                zoomAreaStartCoordinate: this.state.zoomAreaStartCoordinate,
                zoomAreaEndCoordinate: this.state.zoomAreaEndCoordinate,
            };
            this.props.onZoomChart(zoomData);

            this.setState(() => ({
                zoomAreaStartTimestamp: startTimeStamp,
                zoomAreaEndTimestamp: endTimeStamp,
                zoomAreaStartCoordinate: {x: 0, y: 0},
                zoomAreaEndCoordinate: {x: 0, y: 0},
            }));
        }
    }
}

//language=SCSS
TimelineChart = Styled(TimelineChart)`
& {
    .recharts-cartesian-grid-horizontal *,
    .recharts-cartesian-grid-vertical *,
    .recharts-cartesian-axis-tick-line {
        stroke:${colorPalette.thirdBackground};
    }
}
`;

export { TimelineChart };
