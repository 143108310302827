import React from 'react';
import deepEqual from 'deep-eql';
import Styled from 'styled-components';
import autoBind from 'react-autobind';
import {colorPalette} from '@/themes/darkmode.js';
import PropTypes from 'prop-types';
import {zIndices} from '@/themes/darkmode.js';
import Draggable from 'react-draggable';
import moveIcon from '@/public/icons/move-icon.svg';

class GuidedTour extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        children: PropTypes.any,
        steps: PropTypes.array.isRequired,
        activeStep: PropTypes.number.isRequired,
        onClosed: PropTypes.func,
        onChanged: PropTypes.func,
    };

    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        const props = this.props;

        return (
            <div className={props.className}>
                <Draggable>
                    <div className={`guidedTour-content ${(props.activeStep === 0) ? 'guidedTour-content-first-step' : ''}`}>
                        <header className="guidedTour-header">
                            <div className="guidedTour-steps">
                                {props.steps.map((step, i) => (
                                    <a href="#"
                                        key={i}
                                        className={(props.activeStep === i) ? 'activeStep': (i < props.activeStep) ? 'completedStep': ''}
                                        title={props.steps[i]}
                                        onClick={e => this.handleClickGuidedTourStep(e, i)}
                                    >
                                        {props.steps[props.activeStep]}
                                    </a>
                                ))}
                            </div>

                            <button className="guidedTour-close" onClick={this.handleCloseGuidedTour}>&times;</button>
                        </header>

                        <div className="guidedTour-steps-content">
                            <h3>{props.steps[props.activeStep]}</h3>
                            {props.children}
                        </div>

                        <div className="guidedTour-actions">
                            {(props.activeStep > 0) && <button className="guidedTour-prevStep" onClick={this.handleClickPrevStep}>&lsaquo;</button>}
                            {(props.activeStep < props.steps.length - 1) && <button className="guidedTour-nextStep" onClick={this.handleClickNextStep}>&rsaquo;</button>}
                        </div>
                    </div>
                </Draggable>
            </div>
        );
    }

    handleClickGuidedTourStep(e, step) {
        e.preventDefault();
        this.props.onChanged(step);
    }

    handleClickPrevStep() {
        this.props.onChanged(this.props.activeStep-1);
    }

    handleClickNextStep() {
        this.props.onChanged(this.props.activeStep+1);
    }

    handleCloseGuidedTour() {
        if(this.props.onClosed) {
            this.props.onClosed();
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !deepEqual(this.props, nextProps) || !deepEqual(this.state, nextState);
    }
}

//language=SCSS
GuidedTour = Styled(GuidedTour)`
& {
    .guidedTour-content {
        background:${colorPalette.thirdBackground};
        margin-left: 20px;
        border-radius: 15px;
        position: fixed;
        bottom: 25px;
        right: 50px;
        width: 500px;
        min-height:250px;
        border:none;
        box-shadow: 0 3px 6px ${colorPalette.mainBackground};
        z-index: ${zIndices.overlays + 100};
        min-width: 400px;
        max-width: 800px;
        
        header {
            background:${colorPalette.activeColor} url(${moveIcon}) no-repeat 15px center;
            background-size:20px;
            padding:10px 0;
            height:40px;
            border-radius: 15px 15px 0 0;
            cursor:move;
        }
        
        .guidedTour-steps {
            margin: 0 auto;
            width: 200px;
            text-align: center;
            margin-top: 5px;
            a {
                display:inline-block;
                color:transparent;
                text-indent:-10000px;
                border-radius:20px;
                height:10px;
                width:10px;
                background:${colorPalette.frontColor};
                margin-right:5px;
                &:hover {
                    background:${colorPalette.info};
                }
                &.activeStep {
                    outline:2px solid ${colorPalette.info};
                }
            }
        }
        
        .guidedTour-steps-content {
            padding:20px;
            text-align:center;
            h3 {
                font-size:20px;
            }
        }
        
        .guidedTour-actions {
            button {
                height:40px;
                width:40px;
                border-radius:40px;
                background-color: ${colorPalette.activeColor};
                border:none;
                color:${colorPalette.frontColor};
                font-size: 30px;
                outline: none;
                line-height: 0;
                position: absolute;
                top: calc(50% - 20px);
                &:hover {
                    background-color: ${colorPalette.activeColorDarker};
                }
                &.guidedTour-nextStep {
                    right: -15px;
                }
                &.guidedTour-prevStep {
                    left:-15px;
                }
            }
        }
        
        .guidedTour-close {
            border:none;
            background:none;
            width:40px;
            height:40px;
            position:absolute;
            right: 5px;
            top: 0;
            color: ${colorPalette.frontColor};
            font-size: 25px;
            &:hover {
                outline: none;
                color:${colorPalette.clearColor};
            }
        }
    }
}
`;

export { GuidedTour };
