import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';
import {withTranslation} from 'react-i18next';
import PhoneInput, {parsePhoneNumber} from 'react-phone-number-input';
import Styled from 'styled-components';

import {Button} from '@/components/buttons/button';
import {SelectCountry} from '@/components/form/input/select-country';
import {GenderEnum} from '@/enum/gender-enum';
import {GeoCountryService} from '@/services/geocontry-service';

import 'react-phone-number-input/style.css';

class HcpUpdatePersonalDataForm extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        t: PropTypes.func,
        hcp: PropTypes.object,
        loading: PropTypes.bool.isRequired,
        onSubmit: PropTypes.func.isRequired
    };

    state = {
        firstName: '',
        lastName: '',
        gender: '',
        defaultPhoneCountryCode: '',
        phoneCountryCode: '',
        phoneNumber: '',
        addressState: '',
        addressCity: '',
        addressCountry: '',
        legalFlagPrivacyPolicy: true,
        legalFlagTermsOfUse: true,
    };

    constructor(props) {
        super(props);
        autoBind(this);
    }

    componentDidMount() {
        const props = this.props;

        this.getGeoCountry();

        if(props.hcp) {
            this.setState(state => ({
                ...state,
                firstName: props.hcp.personalDetails.firstName || '',
                lastName: props.hcp.personalDetails.lastName || '',
                gender: props.hcp.personalDetails.gender || '',
                phoneCountryCode: props.hcp.personalDetails.phoneNumber?.countryCode || '' ,
                phoneNumber: props.hcp.personalDetails.phoneNumber?.number || '',
                addressState: props.hcp.personalDetails.address?.state || '',
                addressCity: props.hcp.personalDetails.address?.city || '',
                addressCountry: props.hcp.personalDetails.address?.country || '',
            }));
        }
    }

    isFilled() {
        const state = this.state;
        return (
            state.firstName && state.firstName !== '' &&
            state.lastName && state.lastName !== '' &&
            state.gender && state.gender !== '' &&
            state.phoneCountryCode && state.phoneCountryCode !== '' &&
            state.phoneNumber && state.phoneNumber !== '' &&
            state.addressState && state.addressState !== '' &&
            state.addressCity && state.addressCity !== '' &&
            state.addressCountry && state.addressCountry !== '' &&
            state.legalFlagPrivacyPolicy &&
            state.legalFlagTermsOfUse
        );
    }

    render() {
        const props = this.props;
        const { t } = this.props;

        return (
            <div className={props.className}>
                <form onSubmit={this.handleSubmit}>
                    <p><em>{t(['Fields marked with an asterisk (*) are required.', 'global.mandatoryFields'])}</em></p>
                    <div className="multiFieldWrapper">
                        <div className="fieldWrapper">
                            <label htmlFor="hcp-firstName" className={`mandatoryField ${(this.state.firstName === '') ? 'empty' : 'filled'}`}>{t(['First Name', 'hcpUpdatePersonalData.firstName'])}</label>
                            <input name="firstName" maxLength={70} id="hcp-firstName" required aria-required="true" autoFocus value={this.state.firstName} onChange={this.handleChangeFirstName} />
                        </div>
                        <div className="fieldWrapper">
                            <label htmlFor="hcp-lastName" className={`mandatoryField ${(this.state.lastName === '') ? 'empty' : 'filled'}`}>{t(['Last Name', 'hcpUpdatePersonalData.lastName'])}</label>
                            <input name="lastName" maxLength={70} id="hcp-lastName" required aria-required="true" value={this.state.lastName} onChange={this.handleChangeLastName} />
                        </div>
                    </div>
                    <div className="fieldWrapper">
                        <label htmlFor="hcp-gender" className={`mandatoryField ${(this.state.gender === '') ? 'empty' : 'filled'}`}>{t(['Pronoun', 'hcpCreation.gender'])}</label>
                        <select name="gender" id="hcp-gender" required aria-required="true" value={this.state.gender} onChange={this.handleChangeGender}>
                            <option value=""></option>
                            {Object.entries(GenderEnum).map((gender) => (
                                <option key={gender[1]} value={gender[1]}>{t('global.gender.' + gender[0])}</option>
                            ))}
                        </select>
                    </div>
                    <div className="fieldWrapper">
                        <label htmlFor="hcp-phone-number" className={`mandatoryField ${(this.state.addressState === '') ? 'empty' : 'filled'}`}>{t(['Phone number', 'hcpUpdatePersonalData.phoneNumber'])}</label>
                        <PhoneInput
                            value={(this.state.phoneNumber) ? `+${this.state.phoneCountryCode}${this.state.phoneNumber}` : ''}
                            defaultCountry={this.state.defaultPhoneCountryCode}
                            onChange={this.handleChangePhoneNumber}
                        />
                    </div>
                    <div className="multiFieldWrapper">
                        <div className="fieldWrapper">
                            <label htmlFor="hcp-address-state" className={`mandatoryField ${(this.state.addressState === '') ? 'empty' : 'filled'}`}>{t(['State', 'hcpUpdatePersonalData.state'])}</label>
                            <input name="address-state" id="hcp-address-state" required aria-required="true" value={this.state.addressState} onChange={this.handleChangeAddressState} />
                        </div>
                        <div className="fieldWrapper">
                            <label htmlFor="hcp-address-city" className={`mandatoryField ${(this.state.addressCity === '') ? 'empty' : 'filled'}`}>{t(['City', 'hcpUpdatePersonalData.city'])}</label>
                            <input name="address-city" id="hcp-address-city" required aria-required="true" value={this.state.addressCity} onChange={this.handleChangeAddressCity} />
                        </div>
                    </div>
                    <div className="fieldWrapper">
                        <label htmlFor="hcp-address-country" className={`mandatoryField ${(this.state.addressCountry === '') ? 'empty' : 'filled'}`}>{t(['Country', 'hcpUpdatePersonalData.country'])}</label>
                        <SelectCountry
                            name="address-country"
                            id="hcp-address-country"
                            required={true}
                            aria-required="true"
                            value={this.state.addressCountry}
                            onChange={this.handleChangeAddressCountry}
                        />
                    </div>
                    <div className="fieldWrapper">
                        <div className="checkboxFieldWrapper">
                            <input type="checkbox" name="legalFlag-privacyPolicy" id="privacyPolicy" required aria-required="true" checked={true} disabled={true} />
                            <label htmlFor="privacyPolicy" className="mandatoryCheckbox filled">{t(['Privacy policy', 'hcpUpdatePersonalData.privacyPolicy'])}</label>
                        </div>
                    </div>
                    <div className="fieldWrapper">
                        <div className="checkboxFieldWrapper">
                            <input type="checkbox" name="legalFlag-termsOfUse" id="termsOfUse" required aria-required="true" checked={true} disabled={true} />
                            <label htmlFor="termsOfUse" className="mandatoryCheckbox filled">{t(['Terms of use', 'hcpUpdatePersonalData.termsOfUse'])}</label>
                        </div>
                    </div>
                    <div className="step-form-actions">
                        <Button className="button-create" disabled={!this.isFilled()} loading={props.loading}>{t(['Update my data', 'hcpUpdatePersonalData.confirm'])}</Button>
                    </div>
                </form>
            </div>
        );
    }

    handleChangeFirstName(e) {
        this.setState(state => ({
            ...state,
            firstName: e.target.value,
        }));
    }

    handleChangeLastName(e) {
        this.setState(state => ({
            ...state,
            lastName: e.target.value,
        }));
    }

    handleChangeGender(e) {
        this.setState(state => ({
            ...state,
            gender: (e.target.value === '') ? '' : parseInt(e.target.value),
        }));
    }

    handleChangePhoneNumber(data) {
        if(data) {
            const parsedPhoneNumber = parsePhoneNumber(data);

            if(parsedPhoneNumber) {
                this.setState(state => ({
                    ...state,
                    phoneNumber: parsedPhoneNumber.nationalNumber,
                    phoneCountryCode: parsedPhoneNumber.countryCallingCode,
                }));
            }
        } else {
            this.setState(state => ({
                ...state,
                phoneNumber: '',
                phoneCountryCode: '',
            }));
        }
    }

    handleChangeAddressState(e) {
        this.setState(state => ({
            ...state,
            addressState: e.target.value,
        }));
    }

    handleChangeAddressCity(e) {
        this.setState(state => ({
            ...state,
            addressCity: e.target.value,
        }));
    }

    handleChangeAddressCountry(e) {
        this.setState(state => ({
            ...state,
            addressCountry: e.target.value,
            defaultPhoneCountryCode: e.target.value,
        }));
    }
    
    async getGeoCountry() {
        try {
            const countryCode = await GeoCountryService.fetchCountryCode();
            this.setState(state => ({
                ...state,
                defaultPhoneCountryCode: countryCode,
            }));
        }
        catch (error) {
            console.error(error);
        }
    }

    handleSubmit(e) {
        e.preventDefault();

        if(this.props.onSubmit) {
            const hcp = {
                id: this.props.hcp.hcpId,
                personalDetails: {
                    firstName: this.state.firstName,
                    lastName: this.state.lastName,
                    gender: this.state.gender,
                    phoneNumber: {
                        number: this.state.phoneNumber,
                        countryCode: this.state.phoneCountryCode,
                    },
                    address: {
                        state: this.state.addressState,
                        city: this.state.addressCity,
                        country: this.state.addressCountry,
                    },
                },
                legalFlags: {
                    privacyPolicy: this.state.legalFlagPrivacyPolicy,
                    termsOfUse: this.state.legalFlagTermsOfUse,
                }
            };
            this.props.onSubmit(hcp);
        }
    }
}

//language=SCSS
HcpUpdatePersonalDataForm = Styled(HcpUpdatePersonalDataForm)`
& {
    .PhoneInputCountryIcon--border {
        box-shadow: inherit;
        background: none;
    }

    .PhoneInputCountryIcon {
        width:60px;
        height:38px;
    }
}
`;

export default withTranslation()(HcpUpdatePersonalDataForm);
