import React from 'react';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';
import Styled from 'styled-components';
import {colorPalette, medias} from '@/themes/darkmode.js';
import groupsUsersIcon from '@/public/icons/groups-users-icon.svg';
import {withTranslation} from 'react-i18next';

class PatientGroupUsersTooltip extends React.Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        t: PropTypes.func,
        overlayPosition: PropTypes.string, // "bottomRight", "bottomLeft"
        className: PropTypes.string,
        children: PropTypes.any,
        onClosed: PropTypes.func,
    };

    state = {
        showTooltip: false,
    };

    constructor(props) {
        super(props);
        autoBind(this);
        this.ref = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('click', this.handleDocumentClick);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleDocumentClick);
    }

    render() {
        const state = this.state;
        const props = this.props;
        const { t } = this.props;

        return (
            <div className={`${props.className}`} ref={this.ref}>
                <button type="button" className="patient-group-users-tooltip-button" title={t(['Members', 'patientGroup.members'])} onClick={this.toggleOverlay}>{t(['Members', 'patientGroup.members'])}</button>
                {(state.showTooltip &&
                    <div className={`patient-group-users-tooltip-overlay animate__animated animate__fadeIn animate__faster ${props.overlayPosition}`}>
                        <button className="patient-group-users-tooltip-close-button" onClick={this.toggleOverlay}>&times;</button>

                        <h4>{props.title}</h4>
                        {props.children}
                    </div>
                )}
            </div>
        );
    }

    toggleOverlay() {
        this.setState(state => ({
            ...state,
            showTooltip: !state.showTooltip
        }));
    }

    handleDocumentClick(e) {
        if (this.state.showTooltip && !this.ref.current.contains(e.target)){
            this.setState(state => ({
                ...state,
                showTooltip: !state.showTooltip
            }));

            if(this.props.onClosed) {
                this.props.onClosed();
            }
        }
    }

    shouldComponentUpdate() {
        return true; // Layout component, has children
    }
}

//language=SCSS
PatientGroupUsersTooltip = Styled(PatientGroupUsersTooltip)`
& {
    right:15px;
    top:5px;
    display:inline-block;
    
    h4 {
        font-size:14px;
        font-weight:bold;
    }
    
    &:last-child {
        margin-right:0;
    }
    
    .patient-group-users-tooltip-button {
        &, &:link, &:active {
            display:inline-block;
            background-image: url(${groupsUsersIcon});
            background-repeat: no-repeat;
            background-position: center;
            text-indent:-10000px;
            width:30px;
            height:30px;
            border-radius: 20px;
            color:transparent;
            text-transform: uppercase;
            font-size: 14px;
            border: none;
            outline: none;
            cursor:pointer;
            background-color: ${colorPalette.thirdBackground};
        }

        &:hover, &focus {
            background-color:${colorPalette.secondBackground};
        }
    }
    
    .patient-group-users-tooltip-overlay {
        background:${colorPalette.thirdBackground};
        padding: 30px;
        margin-left: 20px;
        border-radius: 5px;
        z-index: 1050;
        position: absolute;
        width: 300px;
        left: 100%;
        top:0;
        &::after {
            content: "";
            position: absolute;
            top: 10px;
            left: -10px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 10px 10px 10px 0;
            border-color: transparent ${colorPalette.thirdBackground} transparent transparent;
        }
        &.bottomRight {
            top: -10px;
            &::after {
                top:10px;
            }
        }
        &.bottomLeft {
            top: -10px;
            left:initial;
            right: 100%;
            margin-right:10px;
            margin-left:0;
            &::after {
                top:10px;
                left:initial;
                right:-10px;
                border-width: 10px 0 10px 10px;
                border-color: transparent transparent transparent ${colorPalette.thirdBackground};
            }
        }
    }

    @media(${medias.xlMin}) {
        .patient-group-users-tooltip-overlay {
            width: 450px;
        }
    }

    .patient-group-users-tooltip-close-button {
        position:absolute;
        right:10px;
        top:5px;
        border:none;
        font-size: 20px;
        color:${colorPalette.frontColor};
        background:transparent;
    }
}`;

export default withTranslation()(PatientGroupUsersTooltip);

