import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';

import {PatientPrescriberForm} from '@/components/business/analytics/patient/common/patient-prescriber-form';
import LoadingView from '@/components/static/loading-view';
import {AlertTypeEnum} from '@/enum/alert-type-enum';
import {BackendApiService} from '@/services/backend-api-service';
import {FeedbackAlertsService} from '@/services/feedback-alerts-service';

class PatientUpdatePrescriber extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        me: PropTypes.object.isRequired,
        patient: PropTypes.object.isRequired,
        t: PropTypes.func,
        onChanged: PropTypes.func,
    };

    state = {
        loading: false,
        prescribers: null,
        prescriberSelected: null,
    };

    componentDidMount() {
        this.fetchPrescriberSelected();
    }

    render() {
        const props = this.props;
        const state = this.state;
        const { t } = this.props;

        return (
            <div className={props.className}>
                <h3>{t(['Patient prescriber', 'updatePrescriber.title'])}</h3>

                {!state.loading && state.prescribers && state.prescriberSelected !== null &&
                    <PatientPrescriberForm
                        me={props.me}
                        patient={props.patient}
                        prescriberSelected={(state.prescriberSelected) ? state.prescriberSelected : ''}
                        prescribers={state.prescribers}
                        loading={state.loading}
                        onChanged={props.onChanged}
                        onSubmit={(prescriber) => this.updatePatientPrescriber(prescriber)}
                    />
                }
                {state.loading &&
                    <LoadingView/>
                }
            </div>
        );
    }

    fetchPrescriberSelected() {
        this.setState(state => ({
            ...state,
            loading: true,
        }));

        BackendApiService.getRequest({
            domain: 'patient',
            modelName: 'getPatientPrescriber',
            data: {
                patientId: this.props.patient.patientId.id,
            }
        }).then(prescriber => {
            // If selected prescriber is connected HCP OR empty, get all prescribers
            if(prescriber.id === this.props.me.sub?.split('|')[1] || prescriber.id === '') {
                this.setState(state => ({
                    ...state,
                    prescriberSelected: prescriber.id || '',
                }), function() {
                    this.fetchPrescribers();
                });
            } else {
                this.setState(state => ({
                    ...state,
                    prescriberSelected: prescriber.id || '',
                }), function() {
                    this.fetchPrescribersDetails([{'id': prescriber.id}]);
                });
            }
        }).catch (error => {
            console.error(error);
        });
    }

    fetchPrescribers() {
        this.setState(state => ({
            ...state,
            loading: true,
        }));

        BackendApiService.getRequest({
            domain: 'group',
            modelName: 'getPrescribers',
        }).then(prescribersIds => {
            // For each prescriber ID, get personal details
            this.fetchPrescribersDetails(prescribersIds);

            return prescribersIds;
        }).catch(error => {
            console.error(error);
        });
    }

    fetchPrescribersDetails(prescribersIds) {
        const prescribers = [];

        prescribersIds.forEach(prescriber => {
            const prescriberId = prescriber.id;
            BackendApiService.getRequest({
                domain: 'hcp',
                modelName: 'getUser',
                data: {
                    id: prescriberId,
                }
            }).then(prescriberDetails => {
                prescribers.push({
                    id: prescriberId,
                    details: prescriberDetails,
                });

                if(prescribers.length === prescribersIds.length) {
                    prescribers.sort((a, b) => a.details?.lastName.localeCompare(b.details?.lastName));

                    this.setState(state => ({
                        ...state,
                        loading: false,
                        prescribers: prescribers,
                    }));
                }

                return prescriberDetails;
            }).catch(error => {
                console.error(error);
            });
        });
    }

    updatePatientPrescriber(prescriber) {
        this.setState(state => ({
            ...state,
            loading: true,
            prescriberSelected: prescriber.id,
        }));

        BackendApiService.getRequest({
            domain: 'patient',
            modelName: 'assignPrescriberToPatient',
            data: {
                patientId: this.props.patient.patientId.id,
                prescriberId: prescriber.id
            }
        }).then((response) => {
            FeedbackAlertsService.add(AlertTypeEnum.SUCCESS, 'assignPrescriberToPatient', 'Prescriber assigned to patient!');
            this.setState(state => ({
                ...state,
                loading: false,
            }));
            return response;
        }).catch(err => {
            console.error(err);
            this.setState(state => ({
                ...state,
                loading: false,
            }));
        });
    }
}

export default withTranslation()(PatientUpdatePrescriber);

