import {InfoMessage} from '@/components/form/info-message.jsx';
import {useTranslation} from 'react-i18next';
import {EventConfigurationForm} from '@/components/business/analytics/common/event-configuration-form';

type PatientCreationEventConfigurationProps = {
    className?: string;
    patientId: string;
    onSaved?: () => void;
};

function PatientCreationEventConfiguration({className, patientId, onSaved}: PatientCreationEventConfigurationProps) {
    const { t } = useTranslation();
    return (
        <div className={`patient-creation-event-configuration ${className ?? ''}`}>
            <h3>{t(['Event configuration', 'createEventConfiguration.title'])}</h3>

            <InfoMessage infoMessage={t(['Health condition information were successfully saved!', 'createEventConfiguration.info'])} text={t(['Please select the patient\'s event configuration.', 'createEventConfiguration.infoText'])} />

            <EventConfigurationForm
                patientId={patientId}
                onCancel={() => { /* do nothing, here to make sure the button is shown */ }}
                onSaved={() => {
                    if (onSaved) {
                        onSaved();
                    }
                }}
            />
        </div>
    );
}

export { PatientCreationEventConfiguration };
