import {SignalEventConfiguration} from 'gabi-api-js/patient/patient_common_pb';
import {
    AggregatedSignalData,
    AggregatedSignalDataBlock,
    AverageForDay,
    HasData,
    StringDate
} from 'gabi-api-js/signal/signal_query_pb';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Line, LineChart, ReferenceLine, Tooltip, XAxis, YAxis} from 'recharts';
import {CategoricalChartFunc} from 'recharts/types/chart/generateCategoricalChart';
import {CategoricalChartState} from 'recharts/types/chart/types';
import {TooltipProps} from 'recharts/types/component/Tooltip';
import styled from 'styled-components';

import {AverageValue} from '@/components/business/analytics/health-report/health-report-average-value';
import {HealthReportStatisticsTrend} from '@/components/business/analytics/health-report/health-report-statistics-trend';
import {ChartAxisTickColored} from '@/components/business/analytics/patient/patient-chart-axis/chart-axis-tick-colored';
import {ChartAxisTickHourTwoDigits} from '@/components/business/analytics/patient/patient-chart-axis/chart-axis-tick-hour-two-digits';
import {getDomainForSignalType} from '@/components/business/analytics/timeline/timeline-chart-domains';
import {ChartContainer} from '@/components/layout/chart-container';
import LoadingView from '@/components/static/loading-view';
import ComponentErrorMessage from '@/components/widgets/component-error-message';
import {getSignalTypeIdentifier, getSignalTypeUnit, SignalTypeEnum} from '@/enum/signal-type-enum';
import {BackendApiService} from '@/services/backend-api-service';
import {colorPalette} from '@/themes/darkmode';
import {roundTo} from '@/util/number-util';

type HealthReportAggregatedSignalProps = {
    patientId: string;
    signalType: number;
    date: object;
    signalEventConfiguration: SignalEventConfiguration.AsObject;
    onClick?: (data: FromattedAggregatedSignalDataBlock) => void;
};

type FromattedAggregatedSignalDataBlock = AggregatedSignalDataBlock.AsObject & {
    at: StringDate.AsObject & {
        hours: number;
    };
}

function HealthReportAggregatedSignal({patientId, signalType, date, signalEventConfiguration, onClick}: HealthReportAggregatedSignalProps) {
    const { t } = useTranslation();
    const [aggregatedSignal, setAggregatedSignal] = useState<null | Array<FromattedAggregatedSignalDataBlock>>(null);
    const [signalAverage, setSignalAverage] = useState<null | AverageForDay.AsObject>(null);
    const [aggregatedSignalLoading, setAggregatedSignalLoading] = useState(true);
    const [signalAverageLoading, setSignalAverageLoading] = useState(true);
    const [signalRange, setSignalRange] = useState([0, 1000]);

    useEffect(() => {
        getAggregatedSignal();
        getSignalAverage();
    }, [signalEventConfiguration]);

    const getAggregatedSignal = () => {
        setAggregatedSignalLoading(true);

        BackendApiService.getRequest({
            domain: 'signal',
            modelName: 'getAggregatedSignal',
            data: {
                patientId: patientId,
                signalType: signalType,
                dateFrom: date,
                dateTo: date,
            }
        })
            .then((response: AggregatedSignalData.AsObject) => {
                setAggregatedSignal(response.blocksList.map(block => {
                    return {
                        ...block,
                        at: {
                            date: block.at!.date,
                            time: block.at!.time,
                            offset: block.at!.offset,
                            hours: Number(block.at!.time.split(':')[0]) + Number(block.at!.time.split(':')[1])/60.0,
                        },
                    };
                }));
                setSignalRange([response.valueMin, response.valueMax]);
                setAggregatedSignalLoading(false);
                return response;
            })
            .catch(err => {
                console.error(err);
            });
    };

    const getSignalAverage = () => {
        setSignalAverageLoading(true);

        BackendApiService.getRequest({
            domain: 'signal',
            modelName: 'getSignalAverageForDay',
            data: {
                patientId: patientId,
                signalType: signalType,
                day: date,
                nbPreviousDays: 14,
            }
        })
            .then((response) => {
                setSignalAverage(response);
                setSignalAverageLoading(false);
                return response;
            })
            .catch(err => {
                console.error(err);
            });
    };

    const signalTypeIdentifier = getSignalTypeIdentifier(signalType);

    const renderTooltip = (data: TooltipProps<number | string | Array<number | string>, string | number>) => {
        if (data?.payload) {
            const value = data?.payload[0]?.payload?.value.value;
            const time = data?.payload[0]?.payload?.at.time;

            return (
                <div className="chart-tooltip">
                    {(data?.payload[0]?.payload?.reliableValue?.value) ?
                        <span className="tooltip-value">
                            {t(`global.${signalTypeIdentifier}`)}: {roundTo(value, 2)}{getSignalTypeUnit(signalType)}
                        </span>
                        : (data?.payload[0]?.payload?.value === 'noData') ?
                            <span className="tooltip-no-data">
                                {t(['No data available', 'global.noDataAvailable'])}
                            </span>
                            :
                            <span className="tooltip-not-reliable">
                                {t(['Not Reliable', 'global.notReliable'])}
                            </span>
                    }
                    <span className="tooltip-time">{time}</span>
                </div>
            );
        }
        return null;
    };

    const domainDefinition = getDomainForSignalType(signalType, signalRange[0], signalRange[1]);

    if (patientId && date && signalEventConfiguration) {
        const title = (() => {
            switch(signalType) {
            case SignalTypeEnum.PULSE_RATE: return t(['Pulse Rate - aggregated signal', 'healthReport.aggregatedSignal.pulseRateTitle']);
            case SignalTypeEnum.SPO2: return t(['SpO2 - aggregated signal', 'healthReport.aggregatedSignal.spo2Title']);
            case SignalTypeEnum.MOVEMENTS: return ''; // not used
            default: return ''; // not used
            }
        })();
        if (!aggregatedSignalLoading && !signalAverageLoading) {
            const topRightContent = (signalAverage && signalAverage.averageValue) ? (
                <div className="average-block">
                    <p>
                        <HealthReportStatisticsTrend
                            hasData={Math.min(
                                signalAverage.averageValue?.hasData ?? HasData.HAS_NO_DATA,
                                signalAverage.averagePrevious?.hasData ?? HasData.HAS_NO_DATA
                            )}
                            trend={signalAverage.trend}
                        />
                        {t(['Last night avg:', 'healthReport.aggregatedSignal.avgRecording'])}{' '}
                        <AverageValue averageObj={signalAverage.averageValue} unit={getSignalTypeUnit(signalType)}/>
                    </p>
                    <p>
                        {t(['Prev. 14 nights avg:', 'healthReport.aggregatedSignal.avgLast14Nights'])}{' '}
                        <AverageValue averageObj={signalAverage.averagePrevious!} unit={getSignalTypeUnit(signalType)}/>
                    </p>
                </div>
            ) : undefined;

            const tooltipText = (
                <p>
                    {signalType === SignalTypeEnum.PULSE_RATE &&
                        <>{t(['This chart shows the last recording Pulse Rate signal as an aggregated view. You can access a specific time in the daily biometrics by clicking on a dot.', 'infoButton.aggregatedSignal.pulseRate'])}&nbsp;</>
                    }
                    {signalType === SignalTypeEnum.SPO2 &&
                        <>{t(['This chart shows the last recording SpO2 signal as an aggregated view. You can access a specific time in the daily biometrics by clicking on a dot.', 'infoButton.aggregatedSignal.spo2'])}&nbsp;</>
                    }
                    {t(['Notice that not reliable data are colored in grey.', 'infoButton.aggregatedSignal.reliableData'])}
                </p>
            );

            const handleClick: CategoricalChartFunc = (data: CategoricalChartState) => {
                if (onClick) {
                    if (data.activePayload && data.activePayload[0] && data.activePayload[0].payload) {
                        onClick(data.activePayload[0].payload as FromattedAggregatedSignalDataBlock);
                    }
                }
            };

            return (
                <StyledWrapper className="health-report-aggregated-signal" color={colorPalette.signalTypeLight[signalTypeIdentifier]}>
                    <ChartContainer
                        title={title}
                        topRightContent={topRightContent}
                        infoTooltipTitle={`${t(`global.${signalTypeIdentifier}`)} - ${t(['aggregated signal', 'infoButton.aggregatedSignal.title'])}`}
                        infoTooltipText={tooltipText}
                        infoTooltipOverlayPosition="bottomRight"
                    >
                        {(aggregatedSignal!.length === 0) ? (
                            <div className="empty-message">{t(['No recording for the last night', 'healthReport.aggregatedSignal.empty'])}</div>
                        ) : (
                            <LineChart data={aggregatedSignal!} margin={{ top: 0, right: 0, bottom: 0, left: 10 }} onClick={handleClick}>
                                <XAxis
                                    dataKey="at.hours"
                                    interval={19}
                                    tick={<ChartAxisTickHourTwoDigits textAnchor="middle" />}
                                    padding={{ right: 20 }}
                                />
                                <YAxis
                                    dataKey="value.value"
                                    tick={(props) => <ChartAxisTickColored {...props} unit={getSignalTypeUnit(signalType)} fill={colorPalette.signalTypeLight[signalTypeIdentifier]} />}
                                    domain={domainDefinition.domain}
                                    ticks={domainDefinition.ticks}
                                    interval={0}
                                    width={56}
                                    padding={{ top: 10, bottom: 10 }}
                                />
                                {signalEventConfiguration && signalType !== SignalTypeEnum.SPO2 &&
                                    <ReferenceLine
                                        y={signalEventConfiguration.high!.value}
                                        label={{
                                            position: (signalEventConfiguration.high!.value >= (domainDefinition.domain[1] - (5))) ? 'bottom' : 'top',
                                            value: `${signalEventConfiguration.high!.value}${getSignalTypeUnit(signalType)}`,
                                            fill: colorPalette.signalTypeClear[signalTypeIdentifier]
                                        }}
                                        stroke={colorPalette.signalTypeClear[signalTypeIdentifier]}
                                        strokeDasharray="4"
                                    />
                                }
                                {signalEventConfiguration &&
                                    <ReferenceLine
                                        y={signalEventConfiguration.low!.value}
                                        label={{
                                            position: (signalEventConfiguration.low!.value <= (domainDefinition.domain[0] + (5))) ? 'top' : 'bottom',
                                            value: `${signalEventConfiguration.low!.value}${getSignalTypeUnit(signalType)}`,
                                            fill: colorPalette.signalTypeClear[signalTypeIdentifier]
                                        }}
                                        stroke={colorPalette.signalTypeClear[signalTypeIdentifier]}
                                        strokeDasharray="4"
                                    />
                                }
                                <Line
                                    type="monotone"
                                    dot={false}
                                    dataKey="value.value"
                                    stroke={colorPalette.clearColor}
                                    strokeWidth={1.5}
                                />
                                <Line
                                    type="monotone"
                                    dot={false}
                                    dataKey="reliableValue.value"
                                    stroke={colorPalette.signalType[signalTypeIdentifier]}
                                    strokeWidth={2}
                                />
                                <Tooltip
                                    content={renderTooltip}
                                    isAnimationActive={false}
                                />
                            </LineChart>
                        )}
                    </ChartContainer>
                </StyledWrapper>
            );
        }
        else {
            return (
                <StyledWrapper className="health-report-aggregated-signal" color={colorPalette.signalTypeLight[signalTypeIdentifier]}>
                    <ChartContainer title={title}>
                        <LoadingView color={colorPalette.signalType[signalTypeIdentifier]} />
                    </ChartContainer>
                </StyledWrapper>
            );
        }
    }
    else {
        return <ComponentErrorMessage component="HealthReportAggregatedSignal" />;
    }
}

//language=SCSS
const StyledWrapper = styled.div`
& {
    min-height: 260px;
    height: 100%;
    
    .tooltip-time {
        color: ${colorPalette.frontColor};
    }
    
    .tooltip-value {
        color: ${props => props.color};
    }
    
    .tooltip-no-data {
        color: ${colorPalette.clearColor};
    }
    
    .tooltip-not-reliable {
        color: ${colorPalette.clearColor};
    }

    svg {
        cursor: pointer;
    }
    
    .average-block {
        p span {
            color: ${props => props.color};
        }

        img.warningFlag {
            width: 15px;
            margin-right: 7px;
            margin-top: -3px;
        }
    }
}
`;

export {HealthReportAggregatedSignal};
