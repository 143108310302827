import deepEqual from 'deep-eql';
import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';
import {withTranslation} from 'react-i18next';
import {connect as redux_connect} from 'react-redux';
import Styled from 'styled-components';

import {HcpTutorialStatus} from '@/components/business/analytics/hcp/hcp-tutorial-status';
import {GuidedTour} from '@/components/layout/guided-tour';
import {GuidedTourStep} from '@/components/layout/guided-tour-step';
import demoBabyHello from '@/public/images/demo_baby_hello.svg';
import patientAccessGif from '@/public/images/guidedTour/patient/access.gif';
import patientMenuGif from '@/public/images/guidedTour/patient/menu.gif';
import patientTrendsGif from '@/public/images/guidedTour/patient/trends.gif';
import {BackendApiService} from '@/services/backend-api-service';
import {tutorialStatus} from '@/store/models/index';

const reduxMapStateToProps = function(state) {
    return {
        tutorialStatus: state.tutorialStatus,
    };
};

const reduxDispatch = function(dispatch) {
    return {
        setTutorialStatus: (status) => {
            dispatch(tutorialStatus.actions.set(status));
        },
    };
};

class GuidedTourPatient extends React.Component {
    static propTypes = {
        t: PropTypes.func,
        tutorialStatus: PropTypes.any,
        setTutorialStatus: PropTypes.func,
        onClosed: PropTypes.func,
    };

    state = {
        steps: [
            this.props.t(['Welcome on the patients dashboard', 'guidedTour.patients.welcome.title']),
            this.props.t(['Identify patients out-of-range', 'guidedTour.patients.trends.title']),
            this.props.t(['Access health report', 'guidedTour.patients.access.title']),
            this.props.t(['Find your patients', 'guidedTour.patients.menu.title']),
        ],
        activeStep: 0,
    };

    constructor(props) {
        super(props);
        autoBind(this);
    }
    
    componentDidMount() {
        [
            patientAccessGif,
            patientMenuGif,
            patientTrendsGif,
        ].forEach((picture) => {
            const img = new Image();
            img.src = picture;
        });
    }

    render() {
        const state = this.state;
        const { t } = this.props;

        return (
            <>
                <GuidedTour steps={state.steps} activeStep={state.activeStep} onChanged={this.handleChangeActiveStep} onClosed={this.handleCloseGuidedTour}>
                    {state.activeStep === 0 &&
                        <GuidedTourStep>
                            <p>{t(['Let’s take your first steps with this dashboard', 'guidedTour.patients.welcome.text'])}</p>
                            <img src={demoBabyHello} width={100} alt={t(['Baby fox waving hello', 'guidedTour.patients.welcome.alt'])} />
                        </GuidedTourStep>
                    }
                    {state.activeStep === 1 &&
                        <GuidedTourStep>
                            <p>{t(['With up and down trends, quickly identify patients whose trends are out of line with their usual values', 'guidedTour.patients.trends.text'])}</p>
                            <img src={patientTrendsGif} width={350} alt={t(['Animated gif showing how to use trends', 'guidedTour.patients.trends.alt'])} />
                        </GuidedTourStep>
                    }
                    {state.activeStep === 2 &&
                        <GuidedTourStep>
                            <p>{t(['Click on the eye icon to access patient\'s health report', 'guidedTour.patients.access.text'])}</p>
                            <img src={patientAccessGif} width={300} alt={t(['Animated gif showing how to access a patient', 'guidedTour.patients.access.alt'])} />
                        </GuidedTourStep>
                    }
                    {state.activeStep === 3 &&
                        <GuidedTourStep>
                            <p>{t(['When a patient hasn\'t used its Gabi system for 7 days, you can find it in the inactive patients list', 'guidedTour.patients.menu.text'])}</p>
                            <img src={patientMenuGif} width={300} alt={t(['Animated gif showing how to use the patients menu', 'guidedTour.patients.menu.alt'])} />
                        </GuidedTourStep>
                    }
                </GuidedTour>
            </>
        );
    }

    handleChangeActiveStep(activeStep) {
        this.setState(state => ({
            ...state,
            activeStep: activeStep,
        }));
    }

    handleCloseGuidedTour() {
        this.changedGuidedTourTutorialStatus();

        if(this.props.onClosed) {
            this.props.onClosed();
        }
    }

    changedGuidedTourTutorialStatus() {
        BackendApiService.getRequest({
            domain: 'hcp',
            modelName: 'updateTutorialStatus',
            status: {
                guidedTourPatient: HcpTutorialStatus.DISABLED,
            },
        }).then((tutorialStatus) => {
            const updatedTutorialStatus = Object.assign({}, this.props.tutorialStatus);
            updatedTutorialStatus.guidedTourPatient = HcpTutorialStatus.DISABLED;
            this.props.setTutorialStatus(updatedTutorialStatus);

            return tutorialStatus;
        }).catch(err => {
            console.error(err);
            this.setState(state => ({
                ...state,
            }));
        });
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !deepEqual(this.props, nextProps) || !deepEqual(this.state, nextState);
    }
}

//language=SCSS
GuidedTourPatient = Styled(GuidedTourPatient)`
& {

}
`;


export default redux_connect(reduxMapStateToProps, reduxDispatch)(withTranslation()(GuidedTourPatient));
