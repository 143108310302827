import React from 'react';
import Styled from 'styled-components';
import ErrorBoundary from '@/components/errors/error-boundary.jsx';
import {Page} from '@/decorators/page.jsx';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import PatientsWelcomeSection from '@/components/business/analytics/patients/patients-welcome-section.jsx';
import PatientsActive from '@/components/business/analytics/patients/patients-active.jsx';
import PatientsDashboardNavigation from '@/components/business/analytics/patients/patients-dashboard-navigation.jsx';
import {PatientDashboardTypeEnum} from '@/enum/patient-dashboard-type-enum.jsx';
import autoBind from 'react-autobind';
import {BackendApiService} from '@/services/backend-api-service.js';
import LoadingView from '@/components/static/loading-view.tsx';
import {HcpTutorialStatus} from '@/components/business/analytics/hcp/hcp-tutorial-status.jsx';
import {connect as redux_connect} from 'react-redux/es/exports';
import GuidedTourPatient from '@/components/business/analytics/guidedTour/guided-tour-patient.jsx';

function wrapWithPage(Component) {
    return Page({
        name: 'Active patients'
    })(Component);
}

const reduxMapStateToProps = function(state) {
    return {
        tutorialStatus: state.tutorialStatus,
    };
};

class PatientsActivePage extends React.Component {
    static propTypes = {
        t: PropTypes.func,
        className: PropTypes.string,
        tutorialStatus: PropTypes.any,
    };
    _mounted = false;

    state = {
        program: null,
        loading: false,
    };

    constructor(props) {
        super(props);
        autoBind(this);
    }
    
    componentDidMount() {
        this._mounted = true;
        
        // Initialize program with HCP configuration
        this.getProgramType();
    }
    
    componentWillUnmount() {
        this._mounted = false;
    }
    
    async getProgramType() {
        this.setState(state => ({
            ...state,
            loading: true,
        }));

        await BackendApiService.getRequest({
            domain: 'hcp',
            modelName: 'getProgramType',
        })
            .then((response) => {
                if(this._mounted) {
                    this.setState(state => ({
                        ...state,
                        loading: false,
                        program: response.programType,
                    }));
                }
                return response;
            })
            .catch(err => {
                console.error(err);
                if(this._mounted) {
                    this.setState(state => ({
                        ...state,
                        loading: false,
                    }));
                }
            });
    }

    render() {
        const props = this.props;
        const state = this.state;

        return (
            <div className={props.className}>
                <ErrorBoundary>
                    {state.loading ?
                        <LoadingView/>
                        :
                        <>
                            {props.tutorialStatus.guidedTourPatient === HcpTutorialStatus.ENABLED &&
                                <GuidedTourPatient />
                            }
                            <PatientsDashboardNavigation activeDashboard={PatientDashboardTypeEnum.RECENT_RECORDING}/>

                            {state.program !== null &&
                                <div>
                                    <PatientsWelcomeSection program={state.program} onChanged={this.handleProgramChanged}/>
                                    <PatientsActive program={state.program}/>
                                </div>
                            }
                        </>
                    }
                </ErrorBoundary>
            </div>
        );
    }

    handleProgramChanged(program) {
        this.setState(state => ({
            ...state,
            program: parseInt(program)
        }));
    }

    shouldComponentUpdate() {
        return true;
    }
}

//language=SCSS
PatientsActivePage = Styled(PatientsActivePage)`
& {
    min-height: 500px;
    width: 95%;
    height: calc(100% - 80px);
    margin: 110px auto 150px auto;
}
`;

export default redux_connect(reduxMapStateToProps)(wrapWithPage(withTranslation()(PatientsActivePage)));

