import PropTypes from 'prop-types';
import ComponentErrorMessage from '@/components/widgets/component-error-message.tsx';

const TableSortableBody = ({ tableData, columns, onShowSignalSample, TableBodyRowComponent }) => {

    const onShowSignalSampleExample = (data, posX, posY) => {
        onShowSignalSample(data, posX, posY);
    };
    
    if(tableData && columns) {
        if (TableBodyRowComponent) {
            return (
                <tbody>
                    {tableData.map((data, index) => {
                        return (
                            <TableBodyRowComponent key={`body-entry-${index}`} data={data} columns={columns} onShowSignalSample={(data, posX, posY) => onShowSignalSampleExample(data, posX, posY)} />
                        );
                    })}
                </tbody>
            );
        }
        else {
            return (
                <tbody>
                    {tableData.map((data, index) => {
                        return (
                            <tr key={`row-entry-${index}`}>
                                {columns.map(({accessor}) => {
                                    const tData = data[accessor] ? data[accessor] : '——';
                                    return <td key={accessor}>{tData}</td>;
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            );
        }
    } else {
        return <ComponentErrorMessage component="TableSortableBody" />;
    }
};

TableSortableBody.propTypes = {
    tableData: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    onShowSignalSample: PropTypes.func,
    TableBodyRowComponent: PropTypes.elementType,
};

export default TableSortableBody;
