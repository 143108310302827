import React from 'react';
import PropTypes from 'prop-types';
import RatioPlaceholder from '@/components/layout/ratio-placeholder';
import Styled from 'styled-components';
import { medias, defaultUserPic } from '@/themes/darkmode.js';

class UserPic extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
        alt: PropTypes.string,
        pic: PropTypes.string,
        letter: PropTypes.string,
        width: PropTypes.number,
    };
    
    getPic(props) {
        if (props.pic) {
            if (props.pic.startsWith('http') || props.pic.startsWith('/')) {
                return <img src={props.pic} alt={props.alt || ''} style={{width: props.width}}/>;
            }
            else if (props.pic.startsWith('data:image')) {
                return <img src={props.pic} alt={props.alt || ''} style={{width: props.width}}/>;
            }
            else {
                return <img src={`data:image;base64,${props.pic}`} alt={props.alt || ''} style={{width: props.width}}/>;
            }
        }
        else if (props.letter) {
            return (
                <div className="letter-pic" style={{fontSize: props.width/1.38, lineHeight: `${Math.ceil(props.width*1.01)}px`, height: Math.ceil(props.width)}}>
                    {props.letter}
                </div>
            );
        }
        else {
            return <img src={defaultUserPic} alt={props.alt || ''}/>;
        }
    }
    
    render() {
        const props = this.props;
        const pic = this.getPic(props);
        
        return (
            <div className={props.className} style={{width: props.width}}>
                <RatioPlaceholder ratio="1:1">
                    <div className="user-pic-sub">
                        {pic}
                    </div>
                </RatioPlaceholder>
            </div>
        );
    }
}

//language=SCSS
UserPic = Styled(UserPic)`
& {
    border-radius: 100%;
    overflow: hidden;
    line-height: 0;
    background: ${props => props.theme.colorPalette.thirdBackground};
    max-width: 128px;
    display: inline-block;
    width: 100%;
    font-size: 0;
    @media(${medias.mdMin}) {
        max-width: 150px;
    }
    
    .letter-pic {
        line-height: 100%;
        font-weight: 600;
        color: #888;
        text-align: center;
        cursor: default;
    }
    
    .user-pic-sub > img {
        width: 100%;
        display: inline-block;
    }
}
`;

export { UserPic };
