import {subDays} from 'date-fns';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';

import {HcpTutorialStatus} from '@/components/business/analytics/hcp/hcp-tutorial-status';
import {DemoPatient} from '@/components/business/analytics/patient/common/demo-patient';
import PatientsActiveTable from '@/components/business/analytics/patients/patients-active-table';
import LoadingView from '@/components/static/loading-view';
import ComponentErrorMessage from '@/components/widgets/component-error-message';
import {BackendApiService} from '@/services/backend-api-service';
import {NewDateUtil} from '@/util/new-date-util';

const PatientsActive = ({program}) => {
    const [activePatientsData, setActivePatientsData] = useState(null);
    const [loading, setLoading] = useState(false);
    const tutorialStatus = useSelector(state => state.tutorialStatus);

    useEffect(() => {
        if(tutorialStatus) {
            getActivePatientsData();
        }
    }, [tutorialStatus]);

    const getActivePatientsData = () => {
        setLoading(true);

        const dateFrom = subDays(NewDateUtil(), 6);
        const dateTo = NewDateUtil();

        BackendApiService.getRequest({
            modelName: 'getActivePatients',
            domain: 'patient',
            data: {
                dateFrom: dateFrom,
                dateTo: dateTo
            }
        })
            .then((response) => {
                const activePatientsList = response.patientsList;

                if(tutorialStatus.demoPatient === HcpTutorialStatus.ENABLED) {
                    activePatientsList.unshift(DemoPatient);
                }

                activePatientsList.forEach(patient => {
                    const lastRecordingDateTime = new Date(
                        patient.patient.lastRecordingDataTime.date.year,
                        patient.patient.lastRecordingDataTime.date.month-1,
                        patient.patient.lastRecordingDataTime.date.day,
                        patient.patient.lastRecordingDataTime.time.hour,
                        patient.patient.lastRecordingDataTime.time.minutes,
                        patient.patient.lastRecordingDataTime.time.seconds
                    );
                    patient.lastRecordingDataTimeSortable = lastRecordingDateTime.getTime();
                });

                setActivePatientsData(activePatientsList);

                setLoading(false);
                return response;
            })
            .catch(err => {
                console.error(err);
            });
    };

    if(program !== null) {
        if(!loading && activePatientsData) {
            return (
                <StyledWrapper>
                    <PatientsActiveTable
                        patients={activePatientsData}
                        program={program}
                    />
                </StyledWrapper>
            );
        } else {
            return <LoadingView size="large" />;
        }
    } else {
        return <ComponentErrorMessage component="PatientsActive" />;
    }
};

PatientsActive.propTypes = {
    program: PropTypes.number.isRequired,
};

//language=SCSS
const StyledWrapper = styled.div`
  && {
      padding-bottom: 50px;
  }
`;

export default PatientsActive;
