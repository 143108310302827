import React from 'react';
import Styled from 'styled-components';
import PropTypes from 'prop-types';
import GroupUserDetails from '@/components/business/analytics/group/group-user-details.jsx';
import {colorPalette} from '@/themes/darkmode.js';
import autoBind from 'react-autobind';
import {GroupUserRole} from '@/components/business/analytics/group/group-user-role.jsx';
import {medias} from '@/themes/darkmode.js';

class GroupUsersList extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        users: PropTypes.array.isRequired,
        groupId: PropTypes.string.isRequired,
        isMeGroupAdmin: PropTypes.bool.isRequired,
        onUserRemoved: PropTypes.func,
        onRoleChanged: PropTypes.func,
    };

    state = {
        showGroupRemoveUserModal: false,
    };

    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        const props = this.props;

        let adminCount = 0;
        props.users.forEach(user => {
            if(user.role === GroupUserRole.ADMIN) {
                adminCount++;
            }
        });

        return (
            <div className={props.className}>
                {props.users.map(user => (
                    <GroupUserDetails
                        key={`${props.groupId}-${user.id.id}`}
                        groupId={props.groupId}
                        user={user}
                        adminCount={adminCount}
                        isMeGroupAdmin={props.isMeGroupAdmin}
                        onUserRemoved={this.handleOnUserRemoved}
                        onRoleChanged={this.handleOnRoleChanged}
                    />
                ))}
            </div>
        );
    }

    handleOnUserRemoved() {
        if(this.props.onUserRemoved) {
            this.props.onUserRemoved();
        }
    }

    handleOnRoleChanged() {
        if(this.props.onRoleChanged) {
            this.props.onRoleChanged();
        }
    }
}

//language=SCSS
GroupUsersList = Styled(GroupUsersList)`
& {
    display:flex;
    flex-wrap: wrap;
    
    .user-block {
        position:relative;
        padding:15px;
        background:${colorPalette.secondBackground};
        margin:0 20px 20px 0;
        border-radius: 3px;
        width: calc(100% / 2 - 20px);
        @media(${medias.xlMin}) {
            width: calc(100% / 3 - 20px);
        }
    }
}
`;

export { GroupUsersList };
