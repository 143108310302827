import React from 'react';
import autoBind from 'react-autobind';
import {BackendApiService} from '@/services/backend-api-service.js';
import PropTypes from 'prop-types';
import ConfirmDeleteForm from '@/components/form/confirm-delete-form.jsx';
import {withTranslation} from 'react-i18next';

class GroupDelete extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        t: PropTypes.func,
        group: PropTypes.object.isRequired,
        onDeleted: PropTypes.func,
        onCancelled: PropTypes.func,
    };

    state = {
        loading: false,
    };

    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        const props = this.props;
        const state = this.state;
        const { t } = this.props;

        return (
            <div className={props.className}>
                <ConfirmDeleteForm
                    loading={state.loading}
                    itemName={props.group.name}
                    deleteLabel={t(['Delete group', 'deleteGroup.confirm'])}
                    onCancelled={this.handleCancel}
                    onSubmit={this.handleSubmit}
                />
            </div>
        );
    }

    handleSubmit() {
        this.deleteGroup();
    }

    handleCancel() {
        if(this.props.onCancelled) {
            this.props.onCancelled();
        }
    }

    async deleteGroup() {
        try {
            this.setState(state => ({
                ...state,
                loading: true,
            }));

            const group = await BackendApiService.getRequest({
                domain: 'group',
                modelName: 'deleteGroup',
                data: {
                    id: this.props.group.groupId.id
                }
            });

            this.setState(state => ({
                ...state,
                loading: false,
            }));
            if(this.props.onDeleted) {
                this.props.onDeleted(group);
            }
        }
        catch (err) {
            console.error(err);
            this.setState(state => ({
                ...state,
                loading: false,
            }));
        }
    }
}

export default withTranslation()(GroupDelete);
