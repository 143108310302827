import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';
import {connect as redux_connect} from 'react-redux';
import {Link} from 'react-router-dom';
import Styled from 'styled-components';

import {AppLogoFull} from '@/components/static/app-logo-full';
import {Breadcrumb} from '@/components/widgets/breadcrumb';
import ChangelogGift from '@/components/widgets/changelog-gift';
import HelpCenter from '@/components/widgets/help-center';
import {TimetravelWidget} from '@/components/widgets/timetravel-widget';
import {UserMenu} from '@/components/widgets/user-menu';
import {Loader} from '@/decorators/loader.new';
import homeImage from '@/public/icons/home-icon.svg';
import {tutorialStatus} from '@/store/models';
import {colorPalette, zIndices} from '@/themes/darkmode';
import userChangelog from '@/user-changelog.json';

const reduxMapStateToProps = function(state) {
    return {
        me: state.me,
        currentPage: state.currentPage,
        pagePath: state.pagePath,
        tutorialStatus: state.tutorialStatus,
    };
};

const reduxDispatch = function(dispatch) {
    return {
        setTutorialStatus: (status) => {
            dispatch(tutorialStatus.actions.set(status));
        },
    };
};

function wrapWithLoader(Component) {
    return Loader({
        models: {
            tutorialStatusFromApi: () => ({name: 'getTutorialStatus', domain: 'hcp', cached: false}),
            giftNotification: () => ({name: 'getGiftNotificationLastSeen', domain: 'hcp', cached: false}),
        }
    })(Component);
}

class MainTopbarFull extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        tutorialStatus: PropTypes.any,
        tutorialStatusFromApi: PropTypes.object,
        setTutorialStatus: PropTypes.func,
        giftNotification: PropTypes.object,
        pagePath: PropTypes.array,
        currentPage: PropTypes.any,
        me: PropTypes.object,
        children: PropTypes.any,
    };

    state = {
        showChangelogGift: true,
    };

    constructor(props) {
        super(props);
        autoBind(this);
    }

    componentDidMount() {
        const props = this.props;

        // Put alerts in redux
        props.setTutorialStatus(props.tutorialStatusFromApi);

        // Check if change log entries have been seen by user@
        if(props.giftNotification && props.giftNotification.time) {
            const jsonChangelog = userChangelog.changelog;
            const toTimestamp = new Date(props.giftNotification.time.seconds * 1000).getTime();
            const updatedChangelog = [];

            jsonChangelog.forEach(changelogEntry => {
                const releaseDateTimestamp = new Date(changelogEntry.releaseDate).getTime();
                if(releaseDateTimestamp > toTimestamp) {
                    updatedChangelog.push(changelogEntry);
                }
            });

            this.setState((state) => ({
                ...state,
                showChangelogGift: updatedChangelog.length > 0,
            }));
        }
    }

    render() {
        const props = this.props;
        const state = this.state;

        if(props.tutorialStatus && props.giftNotification) {
            return (
                <header className={props.className}>
                    <Link className="topbar-home-link" to="/patients"/>
                    <div className="topbar-app-logo">
                        <AppLogoFull />
                    </div>
                    <h1><Breadcrumb path={props.pagePath}/> {props.currentPage}</h1>
                    {state.showChangelogGift && <ChangelogGift className="topbar-right-icon" giftNotification={props.giftNotification} onClosed={this.handleChangelogGiftClosed} />}
                    {__GABI_ENV__ !== 'production' && (<TimetravelWidget className="topbar-right-icon" onClosed={() => {}}/>)}
                    <HelpCenter className="topbar-right-icon" currentPage={props.currentPage} tutorialStatus={props.tutorialStatus} />
                    <UserMenu className="topbar-user-menu" user={props.me.data} direction="bottom" picLocation="right" />
                </header>
            );
        } else {
            return null;
        }
    }

    handleChangelogGiftClosed() {
        this.setState(state => ({
            ...state,
            showChangelogGift: false,
        }));
    }

    shouldComponentUpdate() {
        return true; // Layout component, has children
    }
}

//language=SCSS
MainTopbarFull = Styled(MainTopbarFull)`
    & {
        position: fixed;
        top:0;
        left:0;
        height:80px;
        width:100%;
        display: flex;
        background:${colorPalette.thirdBackground};
        z-index: ${zIndices.overlays};
        
        > * {
            flex-grow: 0;
            flex-shrink: 0;
        }
        > h1 {
            flex-grow: 1;
        }

        .topbar-home-link {
            background: url(${homeImage}) no-repeat center;
            display: inline-block;
            width: 80px;
            height: 80px;
            border-right: 3px solid ${colorPalette.mainBackground};
            &:hover, &:active, &:visited {
                background-color:${colorPalette.activeColor};
            }
        }
        
        .topbar-app-logo, h1 {
            padding-left: 20px;
            padding-right: 18px;
            padding-top: 18px;
        }
        .topbar-app-logo {
            padding-top: 15px;
            border-right: 3px solid ${colorPalette.mainBackground};
        }
        
        .topbar-right-icon {
            width:70px;
        }

        .topbar-user-menu {
            width: 350px;
        }
    }
`;

export default redux_connect(reduxMapStateToProps, reduxDispatch)(wrapWithLoader(MainTopbarFull));
