import {
    AlertType,
    AverageForDay,
    EventsForHour,
    EventsPerHour,
    HasData,
    Trend
} from 'gabi-api-js/signal/signal_query_pb';
import {TFunction} from 'i18next';
import React, {JSX, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Bar, BarChart, ReferenceLine, Tooltip, XAxis, YAxis} from 'recharts';
import {CategoricalChartFunc} from 'recharts/types/chart/generateCategoricalChart';
import {CategoricalChartState} from 'recharts/types/chart/types';
import {TooltipProps} from 'recharts/types/component/Tooltip';
import styled from 'styled-components';

import {AverageValue} from '@/components/business/analytics/health-report/health-report-average-value';
import {
    HealthReportStatisticsTrend
} from '@/components/business/analytics/health-report/health-report-statistics-trend';
import {ChartAxisTickColored} from '@/components/business/analytics/patient/patient-chart-axis/chart-axis-tick-colored';
import {
    ChartAxisTickHourTwoDigits
} from '@/components/business/analytics/patient/patient-chart-axis/chart-axis-tick-hour-two-digits';
import {getDomainForEvents} from '@/components/business/analytics/timeline/timeline-chart-domains';
import {ChartDualLabel, ChartDualLabelProps} from '@/components/charts/chart-dual-label';
import {ChartContainer} from '@/components/layout/chart-container';
import LoadingView from '@/components/static/loading-view';
import ComponentErrorMessage from '@/components/widgets/component-error-message';
import {getSignalTypeIdentifier, getSignalTypeUnit, SignalTypeEnum} from '@/enum/signal-type-enum';
import {BackendApiService} from '@/services/backend-api-service';
import {colorPalette} from '@/themes/darkmode';
import {EventThresholdType} from '@/types/event-threshold-type';
import {formatDateUniversal} from '@/util/date-utils';
import {formatHourTwoDigits} from '@/util/time-utils';

type HealthReportEventsPerHourProps = {
    patientId: string,
    signalType: SignalTypeEnum,
    thresholdTypes: EventThresholdType[];
    date: Date,
    subtitle: string | React.JSX.Element,
    signalEventConfiguration: {
        low: { value: number },
        high: { value: number },
    },
    onClick?: (data: EventPerHourMerged) => void,
};

type EventPerHourMerged = EventsForHour.AsObject & {
    above: number,
    below: number,
};

function getAlertType(thresholdType: EventThresholdType): AlertType {
    switch(thresholdType) {
    case EventThresholdType.HIGH:
        return AlertType.ALERT_HIGH;
    case EventThresholdType.LOW:
        return AlertType.ALERT_LOW;
    }
}

function HealthReportEventsPerHour({patientId, signalType, thresholdTypes, date, subtitle, signalEventConfiguration, onClick}: HealthReportEventsPerHourProps) {
    const { t } = useTranslation();
    const [eventsPerHourAbove, setEventsPerHourAbove] = useState<EventsForHour.AsObject[]>([]);
    const [eventsPerHourBelow, setEventsPerHourBelow] = useState<EventsForHour.AsObject[]>([]);
    const [averageEventsPerHoursForDayAbove, setAverageEventsPerHoursForDayAbove] = useState<AverageForDay.AsObject | null>(null);
    const [averageEventsPerHoursForDayBelow, setAverageEventsPerHoursForDayBelow] = useState<AverageForDay.AsObject | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetch = async () => {
            setLoading(true);
            const getEventsPerHourPromises = thresholdTypes.map(thresholdType => {
                return getEventsPerHour(thresholdType);
            });
            await Promise.all([
                ...getEventsPerHourPromises,
                getAverageEventsPerHoursForDay(),
            ]);
            setLoading(false);
        };
        fetch();
    }, [signalEventConfiguration, thresholdTypes, date]);

    const getEventsPerHour = async (thresholdType: EventThresholdType) => {
        try {
            const response = (await BackendApiService.getRequest({
                domain: 'signal',
                modelName: thresholdType === EventThresholdType.HIGH ? 'getEventsHighPerHour' : 'getEventsLowPerHour',
                data: {
                    patientId: patientId,
                    signalType: signalType,
                    date: date,
                }
            })) as EventsPerHour.AsObject;

            if (thresholdType === EventThresholdType.HIGH) {
                setEventsPerHourAbove(response.eventsPerHourList);
            }
            if (thresholdType === EventThresholdType.LOW) {
                setEventsPerHourBelow(response.eventsPerHourList);
            }
            return response;
        }
        catch (err) {
            console.error(err);
        }
    };

    const getAverageEventsPerHoursForDay = async () => {
        try {
            const alertTypes = thresholdTypes.map(getAlertType);
            for (const alertType of alertTypes) {
                const response = (await BackendApiService.getRequest({
                    domain: 'signal',
                    modelName: 'getAverageEventsPerHoursForDay',
                    data: {
                        patientId: patientId,
                        signalType: signalType,
                        alertType: alertType,
                        day: date,
                        nbPreviousDays: 14,
                    }
                }));

                if (alertType === AlertType.ALERT_HIGH) {
                    setAverageEventsPerHoursForDayAbove(response);
                }
                if (alertType === AlertType.ALERT_LOW) {
                    setAverageEventsPerHoursForDayBelow(response);
                }
            }
        }
        catch(err) {
            console.error('getAverageEventsPerHoursForDay ERROR');
            console.error(err);
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const renderTooltip = (data: TooltipProps<any, any>, t: TFunction) => {
        const unit = signalType === SignalTypeEnum.SPO2 ? '%' : ` ${t(['events', 'global.events'])}`;
        if (data?.payload) {
            const renderValue = (payload: {hasRecording: boolean, reliableDurationInSeconds: number}, dataAvailableRender: () => JSX.Element) => {
                if (!payload || !(payload.hasRecording)) {
                    return (
                        <span>{t('global.noDataAvailable')}</span>
                    );
                }
                else if (payload.reliableDurationInSeconds === 0) {
                    return (
                        <span>{t('global.noReliableDataAvailable')}</span>
                    );
                }
                else {
                    return dataAvailableRender();
                }
            };

            if (thresholdTypes.length > 1) {
                const eventsAbove = data?.payload[0]?.payload?.above;
                const eventsBelow = Math.abs(data?.payload[0]?.payload?.below);

                return (
                    <div className="chart-tooltip">
                        <strong>{formatDateUniversal(data?.payload[0]?.payload?.time)} - {formatHourTwoDigits(data?.payload[0]?.payload?.hour)}</strong><br />
                        {renderValue(data?.payload[0]?.payload, () => {
                            return (<>
                                <span>{t(['Above', 'global.above'])}{': '}{eventsAbove}{unit}</span>
                                <span>{t(['Below', 'global.below'])}{': '}{eventsBelow}{unit}</span>
                            </>);
                        })}
                    </div>
                );
            }
            else {
                const events = data?.payload[0]?.payload?.value;

                return (
                    <div className="chart-tooltip">
                        <strong>{formatDateUniversal(data?.payload[0]?.payload?.time)} - {formatHourTwoDigits(data?.payload[0]?.payload?.hour)}</strong><br />
                        {renderValue(data?.payload[0]?.payload, () => {
                            return (<>
                                <span>{events}{unit}</span>
                            </>);
                        })}
                    </div>
                );
            }
        }
        return null;
    };

    if (patientId && date && signalEventConfiguration) {
        const title = (() => {
            switch(signalType) {
            case SignalTypeEnum.PULSE_RATE: return t(['Pulse Rate - events', 'healthReport.events.last24h.pulseRateTitle']);
            case SignalTypeEnum.SPO2: return t(['SpO2 - percentage', 'healthReport.events.last24h.spo2Title']);
            case SignalTypeEnum.MOVEMENTS: return ''; // not used
            }
        })();

        if (!loading) {
            // Merge events per hour above and below as one array
            const eventsPerHour: EventPerHourMerged[] = [];
            for (let i=0; i < 24; ++i) {
                const baseEvent = eventsPerHourAbove[i] || eventsPerHourBelow[i];
                eventsPerHour[i] = {
                    ...baseEvent,
                    value: (eventsPerHourAbove[i]?.value ?? 0) + (eventsPerHourBelow[i]?.value ?? 0),
                    above: eventsPerHourAbove[i]?.value ?? 0,
                    below: -1 * (eventsPerHourBelow[i]?.value ?? 0),
                };
            }

            // Define domain by taking the min/max amount of event per hour for symetry
            let maxAmount = 9;
            for (let i=0; i < 24; ++i) {
                if (eventsPerHour[i].above > maxAmount) {
                    maxAmount = eventsPerHour[i].above;
                }
                if (-1 * eventsPerHour[i].below > maxAmount) {
                    maxAmount = -1 * eventsPerHour[i].below;
                }
            }

            const domainForEvents = getDomainForEvents(maxAmount, thresholdTypes.length > 1);

            const eventsPerHourNotZero = eventsPerHour.filter(x => x.above !== 0 || x.below !== 0).length;
            const hasDataForDayAbove = averageEventsPerHoursForDayAbove?.averageValue?.hasData ?? HasData.HAS_NO_DATA;
            const hasDataForDayBelow = averageEventsPerHoursForDayBelow?.averageValue?.hasData ?? HasData.HAS_NO_DATA;
            const bestHasDataForDay: HasData = Math.max(hasDataForDayAbove, hasDataForDayBelow);

            const averageUnit = (() => {
                switch(signalType) {
                case SignalTypeEnum.PULSE_RATE: return <span>{' '}{t(['events/h', 'global.eventsPerHour'])}</span>;
                case SignalTypeEnum.SPO2: return '%';
                case SignalTypeEnum.MOVEMENTS: return ''; // not used
                }
            })();

            const averageEventsPerHoursForDayToDisplay = thresholdTypes[0] === EventThresholdType.LOW ? averageEventsPerHoursForDayBelow : averageEventsPerHoursForDayAbove;

            const topRightContent = (
                <div className="average-block">
                    <p>
                        <HealthReportStatisticsTrend
                            hasData={Math.min(
                                averageEventsPerHoursForDayToDisplay?.averageValue?.hasData ?? HasData.HAS_NO_DATA,
                                averageEventsPerHoursForDayToDisplay?.averagePrevious?.hasData ?? HasData.HAS_NO_DATA
                            )}
                            trend={averageEventsPerHoursForDayToDisplay?.trend ?? Trend.NORMAL}
                        />
                        {t(['Last night avg:', 'healthReport.aggregatedSignal.avgRecording'])}{' '}
                        <AverageValue averageObj={averageEventsPerHoursForDayToDisplay?.averageValue ?? null} unit={averageUnit} precision={2} />
                    </p>
                    <p>
                        {t(['Prev. 14 nights avg:', 'healthReport.aggregatedSignal.avgLast14Nights'])}{' '}
                        <AverageValue averageObj={averageEventsPerHoursForDayToDisplay?.averagePrevious ?? null} unit={averageUnit} precision={2} />
                    </p>
                </div>
            );

            const tooltipText = <>
                <p>
                    {signalType === SignalTypeEnum.PULSE_RATE &&
                        <>{t(['This chart shows the number of events per hour for this night.', 'infoButton.events.pulseRate'])}&nbsp;</>
                    }
                    {signalType === SignalTypeEnum.SPO2 &&
                        <>{t(['This chart shows the percentage of time for which the SpO2 measurements are outside the defined thresholds.', 'infoButton.events.spo2'])}&nbsp;</>
                    }
                    {t(['Notice that only reliable data are considered.', 'infoButton.events.reliableData'])}
                </p>
            </>;

            const handleClick: CategoricalChartFunc = (data: CategoricalChartState) => {
                if (onClick) {
                    if (data.activePayload && data.activePayload[0] && data.activePayload[0].payload) {
                        onClick(data.activePayload[0].payload as EventPerHourMerged);
                    }
                }
            };

            return (
                <StyledWrapper className="health-report-events-per-hour" color={colorPalette.signalTypeLight[getSignalTypeIdentifier(signalType)]}>
                    <ChartContainer
                        title={title}
                        subtitle={subtitle}
                        topRightContent={thresholdTypes.length === 1 ? topRightContent : undefined}
                        infoTooltipTitle={`${t(`global.${getSignalTypeIdentifier(signalType)}`)} - ${t(['events', 'infoButton.events.title'])}`}
                        infoTooltipText={tooltipText}
                        infoTooltipOverlayPosition="bottomLeft"
                    >
                        {bestHasDataForDay < HasData.HAS_MIN_RELIABLE_DATA ? (
                            <div className="empty-message">
                                {(bestHasDataForDay === HasData.HAS_NO_DATA) && (
                                    t('healthReport.events.last24h.noRecording')
                                )}
                                {(bestHasDataForDay === HasData.HAS_DATA) && (
                                    t('healthReport.events.last24h.noReliableData')
                                )}
                                {(bestHasDataForDay === HasData.HAS_RELIABLE_DATA) && (
                                    t('healthReport.events.last24h.notEnoughReliableData')
                                )}
                            </div>
                        ) : (eventsPerHourNotZero <= 0) ? (
                            <div className="empty-message">
                                {t('healthReport.events.last24h.noEvent')}
                            </div>
                        ) : (thresholdTypes.length > 1) ? (
                            <BarChart data={eventsPerHour} margin={{ top: 0, right: 0, bottom: 0, left: 0 }} onClick={handleClick} stackOffset="sign">
                                <XAxis
                                    dataKey="hour" interval={3}
                                    tick={<ChartAxisTickHourTwoDigits textAnchor="middle" />}
                                />
                                <YAxis
                                    width={46} dataKey="value"
                                    domain={domainForEvents.domain}
                                    ticks={domainForEvents.ticks}
                                    interval={0}
                                    tick={(props) => <ChartAxisTickColored {...props} alignUpTick fill={colorPalette.signalTypeLight[getSignalTypeIdentifier(signalType)]} />}
                                    label={({viewBox}: ChartDualLabelProps) => (
                                        <ChartDualLabel
                                            viewBox={viewBox}
                                            fill={colorPalette.signalTypeLight[getSignalTypeIdentifier(signalType)]}
                                            aboveText={`${t(['Above', 'global.above'])} ${signalEventConfiguration.high.value}${getSignalTypeUnit(signalType)}`}
                                            belowText={`${t(['Below', 'global.below'])} ${signalEventConfiguration.low.value }${getSignalTypeUnit(signalType)}`}
                                        />
                                    )}
                                />
                                <Tooltip content={(data) => renderTooltip(data, t)} isAnimationActive={false} />
                                <Bar
                                    dataKey="below"
                                    stackId="stack"
                                    fill={colorPalette.signalTypeLight[getSignalTypeIdentifier(signalType)]}
                                    minPointSize={(_, index) => eventsPerHour[index].reliableDurationInSeconds <= 0 ? 0 : -1}
                                />
                                <Bar
                                    dataKey="above"
                                    stackId="stack"
                                    fill={colorPalette.signalType[getSignalTypeIdentifier(signalType)]}
                                    minPointSize={(_, index) => eventsPerHour[index].reliableDurationInSeconds <= 0 ? 0 : 1}
                                />
                                <ReferenceLine
                                    y={0}
                                    stroke={'rgba(255,255,255, 0.2)'}
                                />
                            </BarChart>
                        ) : (
                            <BarChart height={250} data={eventsPerHour} margin={{ top: 0, right: 0, bottom: 0, left: 0 }} onClick={handleClick}>
                                <XAxis dataKey="hour" interval={3} tick={<ChartAxisTickHourTwoDigits textAnchor="middle" />} />
                                <YAxis
                                    width={46}
                                    domain={domainForEvents.domain}
                                    ticks={domainForEvents.ticks}
                                    interval={0}
                                    dataKey="value"
                                    tick={(props) => <ChartAxisTickColored {...props} alignUpTick fill={colorPalette.signalTypeLight[getSignalTypeIdentifier(signalType)]} />}
                                    allowDecimals={false}
                                />
                                <Tooltip content={(data) => renderTooltip(data, t)} isAnimationActive={false} />
                                <Bar
                                    dataKey="value"
                                    fill={colorPalette.signalType[getSignalTypeIdentifier(signalType)]}
                                    minPointSize={(_, index) => eventsPerHour[index].reliableDurationInSeconds <= 0 ? 0 : 1}
                                />
                            </BarChart>
                        )}
                    </ChartContainer>
                </StyledWrapper>
            );
        }
        else {
            return (
                <ChartContainer
                    title={title}
                    subtitle={subtitle}
                >
                    <LoadingView color={colorPalette.signalType[getSignalTypeIdentifier(signalType)]} />
                </ChartContainer>
            );
        }
    }
    else {
        return (
            <ComponentErrorMessage component="HealthReportEventsPerHour" />
        );
    }
}

//language=SCSS
const StyledWrapper = styled.div`
& {
    min-height: 260px;
    height: 100%;
    
    .chart-tooltip span {
        color: ${props => props.color};
    }
    
    .empty-message {
        margin-top: 25px;
    }

    svg {
        cursor: pointer;
    }
    
    .average-block {
        position: relative;
        font-size: 13px;

        p {
            margin-bottom: 5px;
            text-align: right;
            white-space: nowrap;
        }

        p span {
            color: ${props => props.color};
        }

        img.warningFlag {
            width: 15px;
            margin-right: 7px;
            margin-top: -3px;
        }
    }
}
`;

export {HealthReportEventsPerHour};
