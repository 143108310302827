import Styled from 'styled-components';
import {colorPalette} from '@/themes/darkmode';
import React from 'react';

type LongtextInputProps = React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> & {
    value: string;
    className?: string;
    maxLength?: number;
};

function UnstyledLongtextInput(props: LongtextInputProps) {
    const textLength = (props.value ?? '').toString().length;

    return (
        <div className={`longtext-input ${props.className ?? ''} ${(props.maxLength && textLength+10 >= props.maxLength) ? 'longtext-input__warning' : ''}`}>
            <textarea {...props} />
            {props.maxLength && (
                <div className="longtext-input__counter">{textLength} / {props.maxLength}</div>
            )}
        </div>
    );
}

//language=SCSS
const LongtextInput = Styled(UnstyledLongtextInput)`
&.longtext-input {
    padding: 0;
    line-height: 0;
    
    > * {
        line-height: 1.5em;
    }
    
    .longtext-input__counter {
        position: absolute; 
        display: none;
        right: 0;
        bottom: 5px;
        text-align: right;
        padding: 5px 10px 5px 10px;
        line-height: 1em;
        background-color: rgba(0,0,0, 0.15);
        pointer-events: none;
        border-top: solid 2px ${colorPalette.activeColor};
        border-left: solid 2px ${colorPalette.activeColor};
        border-bottom: solid 2px ${colorPalette.activeColor};
        border-top-left-radius: 5px;
    }
    &:focus-within .longtext-input__counter {
        display: block;
    }

    &:focus-within.longtext-input__warning {
        outline: solid 2px ${colorPalette.warning};
        
        textarea {
          border-bottom-color: ${colorPalette.warning};
        }
        
        .longtext-input__counter {
            color: ${colorPalette.warning};
            border-color: ${colorPalette.warning};
        }
    }
}`;

export { LongtextInput };
