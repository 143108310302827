const singlePromiseMap: Record<string, Promise<any> | null> = {}; // eslint-disable-line @typescript-eslint/no-explicit-any

export function singlePromise<T>(cb: () => Promise<T>, key: string) {
    let promise = singlePromiseMap[key];
    if (!promise) {
        promise = cb().finally(() => {
            delete singlePromiseMap[key];
            promise = null;
        });
        singlePromiseMap[key] = promise;
    }
    else {
        console.debug(`singlePromise: ${key} already running`);
    }
    return promise;
}
