import PropTypes from 'prop-types';
import ComponentErrorMessage from '@/components/widgets/component-error-message.tsx';
import {BackendApiService} from '@/services/backend-api-service.js';
import PatientRecordingDuration from '@/components/business/analytics/patients/patient-recording-duration.jsx';
import {useEffect, useState} from 'react';

const PatientRecordingGetDuration = ({patientId, recordingDate}) => {
    const [recordingDuration, setRecordingDuration] = useState(null);

    useEffect(() => {
        getRecordingDuration();
    }, []);
    
    const getRecordingDuration = () => {
        BackendApiService.getRequest({
            modelName: 'getRecordingDuration',
            domain: 'signal',
            data: {
                patientId: patientId,
                date: recordingDate
            }
        })
            .then((response) => {
                setRecordingDuration(response.durationInSeconds);

                return response;
            })
            .catch(err => {
                console.error(err);
            });
    };

    if(patientId && recordingDate) {
        if(recordingDuration) {
            return <PatientRecordingDuration duration={recordingDuration} />;
        }
    } else {
        return <ComponentErrorMessage component="PatientRecordingGetDuration" />;
    }
};

PatientRecordingGetDuration.propTypes = {
    patientId: PropTypes.string.isRequired,
    recordingDate: PropTypes.object.isRequired,
};

export default PatientRecordingGetDuration;
