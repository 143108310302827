import PropTypes from 'prop-types';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {useState} from 'react';
import {colorPalette} from '@/themes/darkmode.js';
import searchIcon from '@/public/icons/search-icon.svg';

const PatientsSearchForm = ({onChanged}) => {
    const {t} = useTranslation();
    const [search, setSearch] = useState('');

    const handleChangeSearch = (e) => {
        setSearch(e.target.value);
        onChanged(e.target.value.toLowerCase());
    };

    const handleClickClearSearch = () => {
        setSearch('');
        onChanged('');
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
    };

    return (
        <StyledWrapper>
            <form method="post" action="#" onSubmit={handleFormSubmit}>
                <label
                    htmlFor="patients-search"
                    className="hidden"
                >
                    {t(['Search patient', 'pages.patients.searchPatient'])}
                </label>
                <input
                    type="text"
                    name="patients-search"
                    id="patients-search"
                    value={search}
                    placeholder={t(['Search patient', 'pages.patients.searchPatient'])}
                    autoFocus={true}
                    onChange={handleChangeSearch}
                />
                {search &&
                    <button type="button" className="clear-button" onClick={handleClickClearSearch}>&times;</button>
                }
            </form>
        </StyledWrapper>
    );
};

PatientsSearchForm.propTypes = {
    t: PropTypes.func,
    onChanged: PropTypes.func.isRequired
};

//language=SCSS
const StyledWrapper = styled.div`
  && {
      form {
          position:relative;
      }
      input {
          height: 40px;
          border-radius: 5px;
          background: ${colorPalette.clearColor} url(${searchIcon}) no-repeat right 10px center;;
          background-size:20px;
          color: ${colorPalette.frontColor};
          border: 1px solid transparent;
          padding: 5px 10px;
          &::placeholder {
              color: ${colorPalette.frontColor};
          }
          &:-internal-autofill-selected {
              background-color: ${colorPalette.clearColor};
          }
      }
      .search-button {
        background: url(${searchIcon}) no-repeat right 10px center;
      }
      .clear-button {
          position:absolute;
          right: 8px;
          top: 8px;
          z-index:20;
          background-color: ${colorPalette.secondBackground};
          padding: 0;
          border-radius: 20px;
          width: 25px;
          height: 25px;
          color: ${colorPalette.frontColor};
          border: none;
          text-align: center;
          font-size: 17px;
          &:hover {
              background-color: ${colorPalette.thirdBackground};
          }
      }
  }
`;

export default PatientsSearchForm;
