import React from 'react';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';
import {withTranslation} from 'react-i18next';

import {BackendApiService} from '@/services/backend-api-service';
import {FeedbackAlertsService} from '@/services/feedback-alerts-service';
import {PatientCaregiverForm} from '@/components/business/common/patient/patient-caregiver-form';
import LoadingView from '@/components/static/loading-view';
import {AlertTypeEnum} from '@/enum/alert-type-enum';

class PatientUpdateCaregiver extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        t: PropTypes.func,
        patient: PropTypes.object.isRequired,
        onChanged: PropTypes.func,
    };

    state = {
        loading: false,
        caregiverId: null,
        caregiver: null,
    };

    constructor(props) {
        super(props);
        autoBind(this);
    }

    componentDidMount() {
        this.fetchCaregiver();
    }

    render() {
        const props = this.props;
        const state = this.state;
        const { t } = this.props;

        return (
            <div className={props.className}>
                <h3>{t(['Caregiver information', 'updateCaregiver.title'])}</h3>

                {!state.loading &&
                    <PatientCaregiverForm
                        patient={props.patient}
                        caregiver={state.caregiver}
                        loading={state.loading}
                        onChanged={this.handleFormChanged}
                        onSubmit={(caregiver) => this.setPatientCaregiver(caregiver)}
                    />
                }

                {state.loading &&
                    <LoadingView />
                }
            </div>
        );
    }

    handleFormChanged(notSavedDataCaregiver) {
        if(this.props.onChanged) {
            this.props.onChanged(notSavedDataCaregiver);
        }
    }

    setPatientCaregiver(caregiver) {
        if(this.state.caregiver) {
            this.updatePatientCaregiver(caregiver);
        }
        else {
            this.createPatientCaregiver(caregiver);
        }
    }

    fetchCaregiver() {
        this.setState(state => ({
            ...state,
            loading: true,
        }));

        BackendApiService.getRequest({
            domain: 'patient',
            modelName: 'getPatientCaregiver',
            data: {
                patientId: this.props.patient.patientId.id,
            }
        }).then((caregiver) => {
            this.setState(state => ({
                ...state,
                caregiverId: caregiver.id,
            }));

            if(caregiver.id) {
                return BackendApiService.getRequest({
                    domain: 'caregiver',
                    modelName: 'getCaregiver',
                    data: {
                        caregiverId: caregiver.id,
                    }
                });
            } else {
                return null;
            }
        }).then((caregiver) => {
            this.setState(state => ({
                ...state,
                loading: false,
                caregiver: caregiver,
            }));

            return caregiver;
        }).catch(err => {
            console.error(err);
            this.setState(state => ({
                ...state,
                loading: false,
            }));
        });
    }

    updatePatientCaregiver(caregiver) {
        this.setState(state => ({
            ...state,
            loading: true,
            caregiver: caregiver,
        }));

        BackendApiService.getRequest({
            domain: 'caregiver',
            modelName: 'updateCaregiver',
            data: {
                caregiverId: this.state.caregiverId,
                caregiver: caregiver
            }
        }).then(() => {
            FeedbackAlertsService.add(AlertTypeEnum.SUCCESS, 'updateCaregiver', 'Caregiver updated!');
            this.setState(state => ({
                ...state,
                loading: false,
            }));
            return caregiver;
        }).catch(err => {
            console.error(err);
            this.setState(state => ({
                ...state,
                loading: false,
            }));
        });
    }

    createPatientCaregiver(caregiver) {
        this.setState(state => ({
            ...state,
            loading: true,
            caregiver: caregiver,
        }));

        BackendApiService.getRequest({
            domain: 'caregiver',
            modelName: 'createCaregiver',
            data: {
                caregiver: caregiver
            }
        }).then((caregiver) => BackendApiService.getRequest({
            domain: 'patient',
            modelName: 'assignCaregiverToPatient',
            data: {
                patientId: this.props.patient.patientId.id,
                caregiverId: caregiver.id,
            }
        })).then((caregiver) => {
            this.setState(state => ({
                ...state,
                loading: false,
            }));
            return caregiver;
        }).catch(err => {
            console.error(err);
            this.setState(state => ({
                ...state,
                loading: false,
            }));
        });
    }
}

export default withTranslation()(PatientUpdateCaregiver);

