import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {PatientChildSexLabel} from '@/enum/patient-child-sex-enum.jsx';
import {Button} from '@/components/buttons/button.jsx';
import {InfoMessage} from '@/components/form/info-message.jsx';
import PatientUpdateOnboarding from '@/components/business/analytics/patient/patient-update/patient-update-onboarding.jsx';
import {colorPalette, zIndices} from '@/themes/darkmode.js';
import styled from 'styled-components';
import PatientAvatar from '@/components/business/analytics/patient/common/patient-avatar.jsx';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {BackendApiService} from '@/services/backend-api-service.js';
import {formatDateUniversal} from '@/util/date-utils';
import {formatApiDateToJSDate} from '@/services/api-requests/requests-utils.js';
import {GenderEnumLabel} from '@/enum/gender-enum.jsx';

const PatientSummary = ({patient, currentPage}) => {
    const { t } = useTranslation();
    let navigate = useNavigate();
    
    const [showPatientExtendedData, setShowPatientExtendedData] = useState(null);
    const [showUpdateOnboarding, setShowUpdateOnboarding] = useState(null);
    const [caregiverLoading, setCaregiverLoading] = useState(false);
    const [caregiverData, setCaregiverData] = useState(false);

    // Initialize patient flags
    const isDemoPatient = patient.patientId.id === 'demo';
    const isStudyPatient = patient.trialId?.id;
    
    useEffect(() => {
        if(patient.patientId.id !== 'demo') {
            getCaregiverData();
        }
    }, [patient]);

    const handleClickExtendPatient = () => {
        setShowPatientExtendedData(!showPatientExtendedData);
    };

    const handleClickUpdateOnboarding = () => {
        setShowUpdateOnboarding(true);
    };

    const handleCloseUpdateOnboarding = () => {
        setShowUpdateOnboarding(false);
    };

    const handleClickEditPatient = () => {
        let returnToDate = '';
        if(currentPage.page === 'timeline') {
            returnToDate = currentPage.date;
        }

        let url = `/patient-update/${patient.patientId.id}/${currentPage.page}/${returnToDate}`;

        navigate(url);
    };

    const getCaregiverData = () => {
        setCaregiverLoading(true);

        BackendApiService.getRequest({
            domain: 'patient',
            modelName: 'getPatientCaregiver',
            data: {
                patientId: patient.patientId.id,
            }
        }).then((caregiver) => {
            if(caregiver.id) {
                return BackendApiService.getRequest({
                    domain: 'caregiver',
                    modelName: 'getCaregiver',
                    data: {
                        caregiverId: caregiver.id,
                    }
                });
            } else {
                return null;
            }
        }).then((caregiver) => {
            setCaregiverLoading(false);
            setCaregiverData(caregiver);

            return caregiver;
        }).catch(err => {
            console.error(err);
            setCaregiverLoading(false);
        });
    };

    return (
        <StyledWrapper>
            <div className="patient-pic">
                <PatientAvatar skinType={patient.skinType} />
            </div>

            <div className="patient-info">
                <span className="patient-name-cropped">{patient.firstName || patient.externalSource?.idInSource || patient.patientId.id} {patient.lastName}</span>
                {patient.birthDate && <>{formatDateUniversal(formatApiDateToJSDate(patient.birthDate))}</>}
                {patient.sex !== 0 && <span className="patient-sex">{t('patient.sexLabel.' + PatientChildSexLabel[patient.sex])}</span>}
                <span className="patient-trial-id">{patient.trialId?.id}</span>
            </div>

            {(!isStudyPatient && !isDemoPatient) &&
                <div className="patient-extend-data-action">
                    <Button className="button-short-extend" displayIcon={true} onClick={handleClickExtendPatient}>{t(['Extend', 'patient.extend'])}</Button>
                </div>
            }

            {(!isStudyPatient && !isDemoPatient && showPatientExtendedData) &&
            <div className="patient-extended-data">
                <div className="patient-minimize-data-action">
                    <Button className="button-short-minimize" displayIcon={true} onClick={handleClickExtendPatient}>{t(['Minimize', 'patient.minimize'])}</Button>
                </div>

                <div className="patient-pic">
                    <PatientAvatar skinType={patient.skinType} />
                </div>

                <div className="patient-info">
                    <span className="patient-name">{patient.firstName || patient.externalSource?.idInSource || patient.patientId.id} {patient.lastName}</span>
                    {patient.birthDate && <>{formatDateUniversal(formatApiDateToJSDate(patient.birthDate))}</>}
                    <span className="patient-sex">{t('patient.sexLabel.' + PatientChildSexLabel[patient.sex])}</span>
                    <span className="patient-trial-id">{patient.trialId?.id}</span>
                </div>

                {!caregiverLoading &&
                    <div className="patient-caregiver">
                        {caregiverData &&
                            <div>
                                <h3>{t(['Caregiver', 'caregiver.title'])}</h3>
                                <span>{caregiverData.firstName}&nbsp;{caregiverData.lastName}</span> {caregiverData.gender ? `(${t('global.gender.' + GenderEnumLabel[caregiverData.gender])})` : ''}<br />
                                <span>{caregiverData.phoneNumber.countryCode}&nbsp;{caregiverData.phoneNumber.number}</span><br />
                                <span>{caregiverData.email}</span>
                            </div>
                        }
                        {!caregiverData &&
                            <InfoMessage infoMessage={t(['Please update patient to provide caregiver information.', 'caregiver.provideInfo'])} />
                        }
                    </div>
                }

                {showUpdateOnboarding &&
                    <PatientUpdateOnboarding patientId={patient.patientId.id} showModalOnboarding={true} onClosed={handleCloseUpdateOnboarding} />
                }

                <div className="patient-update-actions">
                    <Button className="button-edit" onClick={handleClickEditPatient}>{t(['Update patient information', 'patient.update'])}</Button>
                    {caregiverData &&
                        <Button className="button-active" onClick={handleClickUpdateOnboarding}>{t(['Onboarding information', 'patient.onboardingInfo'])}</Button>
                    }
                </div>
            </div>
            }
        </StyledWrapper>
    );
};

PatientSummary.propTypes = {
    patient: PropTypes.object.isRequired,
    currentPage: PropTypes.object.isRequired,
};

//language=SCSS
const StyledWrapper = styled.div`
  && {
    display:flex;
    width: 200px;
    margin: 0 auto;
    margin-top:10px;
    text-align: left;
    
    .patient-caregiver h3 {
        font-size: 12px;
        margin-bottom: 5px;
        margin-top: 15px;
        text-transform: uppercase;
    }
    
    .patient-pic {
        width:60px;
        margin-right:15px;
        display:inline-block;
        vertical-align: top;
    }
    .patient-info {
        display:inline-block;
        vertical-align: top;
        width: 175px;
        span {
            display:block;
            &.patient-name-cropped {
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 150px;
                overflow: hidden;
            }
            &.patient-sex {
                font-size:12px;
                color:${colorPalette.clearColor};
            }
            &.patient-birth-date {
                font-size:12px;
            }
            &.patient-trial-id {
                font-size:12px;
                color:${colorPalette.clearColor};
            }            
        }
    }
    
    .patient-extend-data-action {
        position: absolute;
        bottom: 10px;
        right: 10px;
    }
    
    .patient-extended-data {
        position: absolute;
        top: 3px;
        left: 0;
        padding: 20px;
        background: ${colorPalette.thirdBackground};
        width: 325px;
        z-index: ${zIndices.overlays};
        border-radius:0 5px 5px 0;

        .patient-info {
          display: inline-block;
          vertical-align: top;
          padding-top: 7px;

          span {
            &.patient-name {
              word-break: break-word;
            }
          }
        }
        
        .patient-caregiver {
            margin:15px 0;
            border-top:1px solid ${colorPalette.secondBackground};
            padding:10px;
        }

        .patient-minimize-data-action {
           position: absolute;
           top: 8px;
           right: 8px;
        }
        
        .patient-update-actions {
            border-top:1px solid ${colorPalette.secondBackground};
            padding-top:20px;
            margin-top: 15px;
            button {
                margin-bottom:10px;
            }
        }
    }
}
`;

export default PatientSummary;
