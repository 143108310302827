import React from 'react';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';
import {withTranslation} from 'react-i18next';

import {PatientGroupsForm} from '@/components/business/analytics/patient/common/patient-groups-form';
import LoadingView from '@/components/static/loading-view';
import {InfoMessage} from '@/components/form/info-message';
import {AlertTypeEnum} from '@/enum/alert-type-enum';
import {BackendApiService} from '@/services/backend-api-service';
import {FeedbackAlertsService} from '@/services/feedback-alerts-service';

class PatientUpdateGroups extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        t: PropTypes.func,
        onChanged: PropTypes.func,
        patient: PropTypes.object.isRequired,
    };

    state = {
        loading: false,
        groups: null,
    };

    constructor(props) {
        super(props);
        autoBind(this);
    }

    componentDidMount() {
        this.fetchGroups();
    }

    render() {
        const props = this.props;
        const state = this.state;
        const { t } = this.props;

        return (
            <div className={props.className}>
                <h3>{t(['Access groups', 'patientGroups.title'])}</h3>

                {!state.loading && state.groups && state.groups.length > 0 && (
                    <PatientGroupsForm
                        updateMode={true}
                        loading={state.loading}
                        groups={state.groups}
                        onChanged={props.onChanged}
                        onSubmit={this.handleSubmit}
                    />
                )}
                {!state.loading && state.groups && state.groups.length === 0 && (
                    <div className="no-group-block">
                        <InfoMessage infoMessage={t(['No group available.', 'patientGroups.noGroup.info'])} text={t(['You must create a group first, and then update the patient to assign him to this group.', 'patientGroups.noGroup.infoText'])} />
                    </div>
                )}
                {state.loading && (
                    <LoadingView />
                )}
            </div>
        );
    }

    fetchGroups() {
        this.setState(state => ({
            ...state,
            loading: true,
        }));

        BackendApiService.getRequest({
            domain: 'group',
            modelName: 'groups',
        }).then((groups) => {
            groups.groupList.forEach(group => {
                group.groupId = group.groupId.id;
                group.selected = false;
            });

            this.setState(state => ({
                ...state,
                groups: groups.groupList,
            }));

            return groups.groupList;
        }).then(() => BackendApiService.getRequest({
            domain: 'patientPermission',
            modelName: 'getPatientGroups',
            data: {
                patientId: this.props.patient.patientId.id,
            }
        })).then((selectedGroups) => {
            const updatedGroups = this.state.groups;
            selectedGroups.groupsList?.forEach(selectedGroup => {
                updatedGroups.forEach(group => {
                    if(group.groupId === selectedGroup.groupId.id) {
                        group.selected = true;
                    }
                });
            });

            this.setState(state => ({
                ...state,
                loading: false,
                groups: updatedGroups,
            }));
            return updatedGroups;
        }).catch(err => {
            console.error(err);
            this.setState(state => ({
                ...state,
                loading: false,
            }));
        });
    }

    patientGroupGrantAccess(groupId) {
        return BackendApiService.getRequest({
            domain: 'patientPermission',
            modelName: 'patientGroupGrantAccess',
            data: {
                patientId: this.props.patient.patientId.id,
                groupId: groupId,
            }
        }).catch(err => {
            console.error(err);
        });
    }

    patientGroupRevokeAccess(groupId) {
        return BackendApiService.getRequest({
            domain: 'patientPermission',
            modelName: 'patientGroupRevokeAccess',
            data: {
                patientId: this.props.patient.patientId.id,
                groupId: groupId,
            }
        }).catch(err => {
            console.error(err);
        });
    }

    async handleSubmit(selectedGroups) {
        const updatedGroups = this.state.groups.map(g => ({...g}));
        this.setState(state => ({
            ...state,
            loading: true,
        }));
        const promises = updatedGroups.map(group => {
            const isGroupSelected = selectedGroups[group.groupId];

            if (group.selected !== isGroupSelected) {
                group.selected = isGroupSelected;
                if (isGroupSelected) {
                    return this.patientGroupGrantAccess(group.groupId);
                }
                else {
                    return this.patientGroupRevokeAccess(group.groupId);
                }
            }
        });
        
        await Promise.all(promises);
        
        FeedbackAlertsService.add(AlertTypeEnum.SUCCESS, 'updatePatient', this.props.t(['Access groups saved!', 'patientGroups.updated']));
        
        this.setState(state => ({
            ...state,
            groups: updatedGroups,
            loading: false,
        }));
    }
}

export default withTranslation()(PatientUpdateGroups);

