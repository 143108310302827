import {withAuth0} from '@auth0/auth0-react';
import deepEqual from 'deep-eql';
import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';
import {withTranslation} from 'react-i18next';
import Styled from 'styled-components';

import HcpCreation from '@/components/business/analytics/hcp/hcp-creation';
import {Button} from '@/components/buttons/button';
import {AppLogoFull} from '@/components/static/app-logo-full';
import doctors from '@/public/images/doctors.svg';
import emailSent from '@/public/images/email_sent.svg';
import {colorPalette} from '@/themes/darkmode';

class LoginView extends React.Component {
    static propTypes = {
        auth0: PropTypes.object,
        t: PropTypes.func,
        className: PropTypes.string,
    };

    state = {
        displayLogin: true,
        displayCreateAccount: false,
        displayCreateAccountSuccessMessage: false,
    };

    constructor(props) {
        super(props);
        // check invitation email in url
        const urlParams = document.location.search.substr(1);
        if(document.location.href.indexOf('invitation') > 0) {
            if (urlParams.split('=')[0] === 'invitation') {
                this.state.displayLogin = false;
                this.state.displayCreateAccount = true;
            }
        }
        autoBind(this);
    }

    render() {
        const props = this.props;
        const state = this.state;
        const { t } = this.props;
        const { loginWithRedirect } = this.props.auth0;

        return (
            <div className={props.className}>
                {state.displayLogin &&
                    <div className="text-center">
                        <AppLogoFull height={80} className="login-logo" />
                    </div>
                }
                {state.displayLogin &&
                    <div className="login-content">
                        <h1>Gabi Analytics</h1>
                        <img src={doctors} width={250} alt={t(['Team of doctors', 'pages.login.alt'])}/>
                        <Button className="button-active" onClick={loginWithRedirect}>{t(['Sign in to your account', 'pages.login.title'])}</Button>
                        <hr />
                        <Button className="button-create" onClick={this.handleCreateAccountClick}>{t(['Create account', 'pages.login.createAccount'])}</Button>
                    </div>
                }
                {state.displayCreateAccount &&
                    <div className="create-account-content">
                        <HcpCreation onCreated={this.handleHcpCreated} />
                    </div>
                }
                {state.displayCreateAccountSuccessMessage &&
                    <div className="create-account-success">
                        <img src={emailSent} width={300} alt={t(['Email sent', 'pages.login.emailSentAlt'])}/>
                        <p>
                            {t(['Congratulations, your account has been created!', 'pages.login.emailSent.firstLine'])}
                            <br />
                            {t(['We sent you an email with the next instructions.', 'pages.login.emailSent.secondLine'])}
                        </p>
                        <p>{t(['You can safely close this window.', 'pages.login.close'])}</p>
                    </div>
                }
            </div>
        );
    }

    handleCreateAccountClick() {
        this.setState(state => ({
            ...state,
            displayLogin: false,
            displayCreateAccount: true,
            displayCreateAccountSuccessMessage: false,
        }));
    }

    handleHcpCreated() {
        this.setState(state => ({
            ...state,
            displayLogin: false,
            displayCreateAccount: false,
            displayCreateAccountSuccessMessage: true,
        }));
    }
    
    shouldComponentUpdate(nextProps, nextState) {
        return !deepEqual(this.props, nextProps) || !deepEqual(this.state, nextState);
    }
}

//language=SCSS
LoginView = Styled(LoginView)`
& {
    margin: 100px auto 0 auto;
    
    .login-logo {
        margin-bottom: 20px;
    }
    
    .login-content {
        background: ${colorPalette.thirdBackground};
        border-radius: 10px;
        padding:35px 70px 55px 70px;
        text-align:center;
        width:400px;
        margin:0 auto;

        h1 {
            font-size: 18px;
            margin-bottom: 15px;
        }

        img {
            margin-bottom:20px;
        }
        
        .button-active {
            margin-top:15px;
        }
    }
    
    .create-account-content,
    .create-account-success {
        width:1000px;
        margin: 0 auto 50px;
        
        &.create-account-success {
            text-align:center;
            font-size:18px;
            img {
                margin-bottom:40px;
            }
        }
    }
}`;

LoginView = withAuth0(withTranslation()(LoginView));
export {LoginView};
