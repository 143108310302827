import {subMinutes} from 'date-fns';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

import {HealthReportEventsListTableRow} from '@/components/business/analytics/health-report/health-report-events-list-table-row';
import HealthReportSignalSample from '@/components/business/analytics/health-report/health-report-signal-sample';
import ColoredContainer from '@/components/layout/colored-container';
import {Modal} from '@/components/layout/modal';
import LoadingView from '@/components/static/loading-view';
import TableSortable from '@/components/table/table-sortable';
import ComponentErrorMessage from '@/components/widgets/component-error-message';
import {getSignalTypeIdentifier, SignalTypeEnum} from '@/enum/signal-type-enum';
import {formatApiFullDateToJSDate} from '@/services/api-requests/requests-utils';
import {BackendApiService} from '@/services/backend-api-service';
import {colorPalette} from '@/themes/darkmode';

function HealthReportEventsList({className, patientId, date, eventConfiguration}) {
    const { t } = useTranslation();
    const [ showSignalSample, setShowSignalSample ] = useState(false);
    const [ signalSampleData, setSignalSampleData ] = useState(null);
    const [eventsList, setEventsList] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getEventsList();
    }, [eventConfiguration]);

    const getEventsList = () => {
        setLoading(true);

        BackendApiService.getRequest({
            domain: 'signal',
            modelName: 'getTypedEventsList',
            data: {
                patientId: patientId,
                date: date,
            }
        })
            .then((response) => {
                const eventsListFormatted = [];
                response.eventList.forEach(event => {
                    if (event.signal === SignalTypeEnum.PULSE_RATE || event.signal === SignalTypeEnum.SPO2) {
                        const jsDate = formatApiFullDateToJSDate(event.timestamp);
                        eventsListFormatted.push({
                            patientId: event.patientId,
                            alertType: event.alertType,
                            durationInSeconds: event.durationInSeconds,
                            signal: event.signal,
                            timestamp: event.timestamp,
                            timestampNumber: jsDate.getTime()
                        });
                    }
                });

                setEventsList(eventsListFormatted);
                setLoading(false);
                return response;
            })
            .catch(err => {
                console.error(err);
            });
    };

    const tableSortableHeaderColumn = [
        {
            label: '',
            accessor: 'id',
            sortable: false,
        },
        {
            label: t(['Event time', 'healthReport.events.list.eventTime']),
            accessor: 'timestampNumber',
            className: 'durationTableHeader',
            sortable: true,
        },
        {
            label: t(['Duration', 'healthReport.events.list.eventDuration']),
            accessor: 'durationInSeconds',
            className: 'signalTableHeader',
            sortable: true,
        },
    ];

    const getSignalEventConfiguration = (signalType) => {
        switch(signalType) {
        case SignalTypeEnum.PULSE_RATE:
            return eventConfiguration.configuration.hr;
        case SignalTypeEnum.SPO2:
            return eventConfiguration.configuration.spo2;
        default:
            return eventConfiguration.configuration.hr;
        }
    };

    const handleShowSignalSample = (rowData) => {
        setShowSignalSample(true);
        setSignalSampleData(rowData);
    };

    const onCloseSignalSample = () => {
        setShowSignalSample(false);
    };

    if (patientId && date && eventConfiguration) {
        if (!loading) {
            return (
                <StyledWrapper className={`health-report-events-list ${className ?? ''}`}>
                    {(showSignalSample && signalSampleData) && (
                        <Modal title={''} onClosed={onCloseSignalSample}>
                            <ColoredContainer
                                color={colorPalette.signalType[getSignalTypeIdentifier(signalSampleData.signal)]}
                                title={`${t(`global.${getSignalTypeIdentifier(signalSampleData.signal)}`)} - ${t(['event - signal sample', 'healthReport.events.list.signalSampleTitle'])}`}
                            >
                                <HealthReportSignalSample
                                    patientId={patientId}
                                    signalType={signalSampleData.signal}
                                    eventTimestamp={signalSampleData.timestamp}
                                    dateFrom={subMinutes(formatApiFullDateToJSDate(signalSampleData.timestamp), 1)}
                                    duration={300}
                                    signalEventConfiguration={getSignalEventConfiguration(signalSampleData.signal)}
                                />
                            </ColoredContainer>
                        </Modal>
                    )}
                    {(eventsList.length > 0) && (
                        <div className="events-list-scrollable">
                            <TableSortable
                                data={eventsList}
                                columns={tableSortableHeaderColumn}
                                defaultSorting={{sortByOrder: 'asc', accessor: 'time'}}
                                onShowSignalSample={handleShowSignalSample}
                                TableBodyRowComponent={HealthReportEventsListTableRow}
                            />
                        </div>
                    )}
                    {(eventsList.length === 0) && (
                        <div className="empty-message">{t(['No events for the last night.', 'healthReport.events.list.empty'])}</div>
                    )}
                </StyledWrapper>
            );
        }
        else {
            return (
                <StyledLoadingWrapper className={`health-report-events-list ${className ?? ''}`}>
                    <LoadingView />
                </StyledLoadingWrapper>
            );
        }
    }
    else {
        return <ComponentErrorMessage component="HealthReportEventsList" />;
    }
}

HealthReportEventsList.propTypes = {
    className: PropTypes.string,
    patientId: PropTypes.string.isRequired,
    date: PropTypes.object.isRequired,
    eventConfiguration: PropTypes.object.isRequired,
};

//language=SCSS
const StyledWrapper = styled.div`
& {
    height: 100%;
    position: relative;
    h3 {
        font-size: 12px;
        margin-bottom: 25px;
        color: ${props => props.theme.colorPalette.clearColor};
    }
    
    .events-list-scrollable {
        @supports (scroll-timeline: --scrollTimeline y) {
            scroll-timeline: --scrollTimeline y;
        }
        
        overflow-y: scroll;
        overflow-x: hidden;
        height: 100%;
        border-radius: 5px;
        
        ::-webkit-scrollbar {
            display: none;
        }
        
        table {
            background: ${colorPalette.secondBackground};
            border-radius: 5px;
            overflow-x: hidden;
        }
        
        thead {
            position: sticky;
            top: 0;
            z-index: 2;
            tr {
                position: relative;
    
                @supports (scroll-timeline: --scrollTimeline y) {
                    clip-path: inset(0 1px -10px 1px);
                    animation-name: health-report-events-list__scroll-animation;
                    animation-duration: 1ms; /* Firefox requires this to apply the animation */
                    animation-timing-function: linear;
                    animation-timeline: --scrollTimeline;
                }
                
                &:after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 1px;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    background: ${colorPalette.mainBackground};
                }
            }
            th {
                position: relative;
                box-shadow: 0 0 0 1px ${colorPalette.mainBackground};
                z-index: 2;
                background-color: ${colorPalette.secondBackground};
                margin: 0;
                border-left: none;
                border-right: none;
                
                &:not(:last-child) {
                    &:after {
                        content: "";
                        position: absolute;
                        width: 1px;
                        height: 100%;
                        top: 0;
                        right: 0;
                        background: ${colorPalette.mainBackground};
                    }
                }
            }
        }
        
        tbody {
            position: relative;
            z-index: 1;
            
            td {
              position: relative;
              z-index: 1;
            }
        }
    }
}
`;

//language=SCSS
const StyledLoadingWrapper = styled.div`
& {
    background: ${colorPalette.secondBackground};
    border-radius: 5px;
    height: 100%;
    padding-top: 50px;
}
`;

export {HealthReportEventsList};
