import {addDays} from 'date-fns';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';

import EventConfigurationButton from '@/components/business/analytics/common/event-configuration-button';
import PatientSummary from '@/components/business/analytics/patient/common/patient-summary';
import PatientHealthCondition from '@/components/business/analytics/patient/patient-health-condition/patient-health-condition';
import {PopupMenu} from '@/components/buttons/popup-menu';
import LoadingView from '@/components/static/loading-view';
import ComponentErrorMessage from '@/components/widgets/component-error-message';
import calendarIcon from '@/public/icons/calendar-icon.svg';
import {BackendApiService} from '@/services/backend-api-service';
import {colorPalette, medias, zIndices} from '@/themes/darkmode';
import {formatDateUniversal} from '@/util/date-utils';
import {NewDateUtil} from '@/util/new-date-util';
import {formatDateForUrl} from '@/util/url-util';

const TimelineSidebar = ({patient, daysWithData, selectedDate, onDateSelected, onEventConfigurationChanged}) => {
    const [patientData, setPatientData] = useState(null);
    const [loading, setLoading] = useState(false);
    const patientId = patient.patientId.id;

    const currentDate = NewDateUtil();

    useEffect(() => {
        getPatientData();
    }, []);

    const handleHealthConditionChanged = () => {
        getPatientData();
    };
    
    const getPatientData = () => {
        setLoading(true);

        BackendApiService.getRequest({
            domain: 'patient',
            modelName: 'getPatientDetails',
            data: {
                patientId: patientId
            }
        })
            .then((response) => {
                setPatientData(response);
                setLoading(false);
                return response;
            })
            .catch(err => {
                console.error(err);
            });
    };

    const onChanged = (selectedEventConfiguration) => {
        onEventConfigurationChanged(selectedEventConfiguration);
    };

    if(patientId) {
        return (
            <StyledWrapper>
                <div className="timeline-sidebar">
                    <div className="patient-summary-wrapper">
                        {!patientData || loading ?
                            <LoadingView />
                            :
                            <PatientSummary
                                patient={patientData}
                                currentPage={{page: 'timeline', date: formatDateForUrl(selectedDate)}}
                            />
                        }
                    </div>
                    <div className="patient-health-condition-wrapper">
                        {!patientData || loading ?
                            <LoadingView/>
                            :
                            <PatientHealthCondition
                                patient={patientData}
                                onChanged={handleHealthConditionChanged}
                            />
                        }
                    </div>
                    <div className="timeline-date-selection-wrapper">
                        <PopupMenu className="popupMenu-calendar" buttonText={(<>
                            {formatDateUniversal(selectedDate)} <br/>
                            {formatDateUniversal(addDays(selectedDate, 1))}
                        </>)}>
                            <DatePicker className="form-control"
                                selected={selectedDate}
                                maxDate={currentDate}
                                highlightDates={daysWithData}
                                onChange={onDateSelected}
                                selectsRange={false}
                                inline
                                dateFormat="dd-MMM-yyyy"
                            />
                        </PopupMenu>
                    </div>
                    <div className="timeline-event-configuration">
                        <EventConfigurationButton
                            patientId={patient.patientId.id}
                            onSaved={selectedEventConfiguration => onChanged(selectedEventConfiguration)}
                        />
                    </div>
                </div>
            </StyledWrapper>
        );
    } else {
        return <ComponentErrorMessage component="TimelineSidebar" />;
    }
};

TimelineSidebar.propTypes = {
    patient: PropTypes.object.isRequired,
    eventConfiguration: PropTypes.object.isRequired,
    daysWithData: PropTypes.array.isRequired,
    selectedDate: PropTypes.object.isRequired,
    onDateSelected: PropTypes.func.isRequired,
    onEventConfigurationChanged: PropTypes.func.isRequired,
};

//language=SCSS
const StyledWrapper = styled.div`
  && {
      .timeline-sidebar {
          background:${colorPalette.secondBackground};
          position:fixed;
          height:100%;
          width:250px;
          border-right: 1px solid ${colorPalette.darkColor};
          top:80px;
          z-index:${zIndices.overlays};

          div.patient-summary-wrapper,
          div.patient-health-condition-wrapper {
              position:relative;
          }

          > div {
              border-bottom:1px solid ${colorPalette.darkColor};
              padding:20px;
          }
          
          div.timeline-date-selection-wrapper {
              text-align:center;
          }

          .popup-menu-button {
              text-indent: -10000px;
              color: transparent;
              padding-left: 28px;
              font-size:12px;

              @media screen and (${medias.lgMin}) {
                  text-indent: initial;
                  color: inherit;
                  padding-left: 33px;
                  padding-right: 10px;
              }
          }
          
          .popupMenu-calendar {
              width: 85%;
          }

          .popupMenu-calendar .react-datepicker {
              background:inherit;
              border:none;
              .react-datepicker__header {
                  background-color: inherit;
              }
          }

          .popupMenu-calendar .popup-menu-button {
              background-image: url(${calendarIcon});
              background-repeat: no-repeat;
              background-position: 14px center;
              background-size: 18px;
              width: 100%;
          }
          
          .timeline-event-configuration {
              .button-event-configuration {
                  border-radius: 5px;
                  right: auto;
                  top: auto;
                  position:relative;
                  margin: 0 auto;
                  display:block;
              }
          }
      }
  }
`;

export default TimelineSidebar;
