import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';
import {withTranslation} from 'react-i18next';
import PhoneInput, {parsePhoneNumber} from 'react-phone-number-input';
import Styled from 'styled-components';

import PatientsProgramSelection from '@/components/business/analytics/patients/patients-program-selection';
import {Button} from '@/components/buttons/button';
import {ErrorMessage} from '@/components/form/error-message';
import {SelectCountry} from '@/components/form/input/select-country';
import {FlexChild, FlexLayout} from '@/components/layout/flex-layout';
import {AppLogoSmall} from '@/components/static/app-logo-small';
import {GenderEnum} from '@/enum/gender-enum';
import {HcpProgramEnum} from '@/enum/hcp-program-enum';
import doctors from '@/public/images/doctors.svg';
import {GeoCountryService} from '@/services/geocontry-service';
import {colorPalette} from '@/themes/darkmode';

import 'react-phone-number-input/style.css';

class HcpCreationForm extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        t: PropTypes.func,
        loading: PropTypes.bool.isRequired,
        errorMessage: PropTypes.string,
        onSubmit: PropTypes.func.isRequired
    };

    state = {
        firstName: '',
        lastName: '',
        gender: '',
        defaultPhoneCountryCode: '',
        phoneCountryCode: '',
        phoneNumber: '',
        addressState: '',
        addressCity: '',
        addressCountry: '',
        email: '',
        emailValid: true,
        program: HcpProgramEnum.RESEARCH,
        legalFlagPrivacyPolicy: false,
        legalFlagTermsOfUse: false,
    };

    constructor(props) {
        super(props);
        autoBind(this);

        // check invitation email in url
        const urlParams = document.location.search.substr(1);
        if(document.location.href.indexOf('invitation') > 0) {
            if (urlParams.split('=')[0] === 'invitation') {
                this.state.email = urlParams.split('=')[1];
            }
        }
    }

    componentDidMount() {
        this.getGeoCountry();
    }

    isFilled() {
        const state = this.state;
        return (
            state.firstName && state.firstName !== '' &&
            state.lastName && state.lastName !== '' &&
            state.gender && state.gender !== '' &&
            state.phoneCountryCode && state.phoneCountryCode !== '' &&
            state.phoneNumber && state.phoneNumber !== '' &&
            state.addressState && state.addressState !== '' &&
            state.addressCity && state.addressCity !== '' &&
            state.addressCountry && state.addressCountry !== '' &&
            state.legalFlagPrivacyPolicy &&
            state.legalFlagTermsOfUse &&
            state.email && state.email !== '' &&
            state.emailValid
        );
    }

    render() {
        const props = this.props;
        const state = this.state;
        const { t } = this.props;

        return (
            <div className={props.className}>
                <FlexLayout direction="row">
                    <FlexChild grow={1} className="create-hcp-illustration">
                        <div className="create-hcp-header">
                            <AppLogoSmall />
                            <h1>{t(['Create account', 'hcpCreation.title'])}</h1>
                        </div>
                        <img src={doctors} width={350} alt={t(['Team of doctors', 'hcpCreation.doctorsAlt'])} />
                    </FlexChild>
                    <FlexChild grow={1} className="create-hcp-form">
                        {state.errorMessage && (
                            <ErrorMessage text={t(['Cannot create account', 'hcpCreation.error'])} errorMessage={props.errorMessage} />
                        )}

                        <form onSubmit={this.handleSubmit}>
                            <p><em>{t(['Fields marked with an asterisk (*) are required.', 'global.mandatoryFields'])}</em></p>
                            <h3><span>1</span> {t(['Personal information', 'hcpCreation.steps.personalInformation'])}</h3>
                            <div className="multiFieldWrapper">
                                <div className="fieldWrapper">
                                    <label htmlFor="hcp-firstName" className={`mandatoryField ${(this.state.firstName === '') ? 'empty' : 'filled'}`}>{t(['First Name', 'hcpCreation.firstName'])}</label>
                                    <input name="firstName" maxLength={70} id="hcp-firstName" required autoFocus aria-required="true" value={this.state.firstName} onChange={this.handleChangeFirstName} />
                                </div>
                                <div className="fieldWrapper">
                                    <label htmlFor="hcp-lastName" className={`mandatoryField ${(this.state.lastName === '') ? 'empty' : 'filled'}`}>{t(['Last Name', 'hcpCreation.lastName'])}</label>
                                    <input name="lastName" maxLength={70} id="hcp-lastName" required aria-required="true" value={this.state.lastName} onChange={this.handleChangeLastName} />
                                </div>
                            </div>

                            <div className="fieldWrapper">
                                <label htmlFor="hcp-gender" className={`mandatoryField ${(this.state.gender === '') ? 'empty' : 'filled'}`}>{t(['Pronoun', 'hcpCreation.gender'])}</label>
                                <select name="gender" id="hcp-gender" required aria-required="true" value={this.state.gender} onChange={this.handleChangeGender}>
                                    <option value=""></option>
                                    {Object.entries(GenderEnum).map((gender) => (
                                        <option key={gender[1]} value={gender[1]}>{t('global.gender.' + gender[0])}</option>
                                    ))}
                                </select>
                            </div>

                            <div className="multiFieldWrapper">
                                <div className="fieldWrapper">
                                    <label htmlFor="hcp-address-state" className={`mandatoryField ${(this.state.addressState === '') ? 'empty' : 'filled'}`}>{t(['State', 'hcpCreation.state'])}</label>
                                    <input name="address-state" id="hcp-address-state" required aria-required="true" value={this.state.addressState} onChange={this.handleChangeAddressState} />
                                </div>
                                <div className="fieldWrapper">
                                    <label htmlFor="hcp-address-city" className={`mandatoryField ${(this.state.addressCity === '') ? 'empty' : 'filled'}`}>{t(['City', 'hcpCreation.city'])}</label>
                                    <input name="address-city" id="hcp-address-city" required aria-required="true" value={this.state.addressCity} onChange={this.handleChangeAddressCity} />
                                </div>
                            </div>
                            <div className="fieldWrapper">
                                <label htmlFor="hcp-address-country" className={`mandatoryField ${(this.state.addressCountry === '') ? 'empty' : 'filled'}`}>{t(['Country', 'hcpCreation.country'])}</label>
                                <SelectCountry
                                    name="address-country"
                                    id="hcp-address-country"
                                    required={true}
                                    aria-required="true"
                                    value={this.state.addressCountry}
                                    onChange={this.handleChangeAddressCountry}
                                />
                            </div>
                            <div className="fieldWrapper">
                                <label htmlFor="hcp-phone-number" className={`mandatoryField ${(this.state.phoneNumber === '') ? 'empty' : 'filled'}`}>{t(['Phone number', 'hcpCreation.phoneNumber'])}</label>
                                <PhoneInput
                                    value={(this.state.phoneNumber) ? `+${this.state.phoneCountryCode}${this.state.phoneNumber}` : ''}
                                    defaultCountry={this.state.defaultPhoneCountryCode}
                                    onChange={this.handleChangePhoneNumber}
                                />
                            </div>
                            <div className="fieldWrapper">
                                <label htmlFor="hcp-email" className={`mandatoryField ${(this.state.email === '') ? 'empty' : 'filled'}`}>{t(['Email', 'hcpCreation.email'])}</label>
                                <input name="email" maxLength={320} id="hcp-email" required aria-required="true" value={this.state.email} onBlur={this.handleValidateEmail} onChange={this.handleChangeEmail} />
                            </div>
                            {state.email && !state.emailValid &&
                                <div className="errorWrapper">
                                    <ErrorMessage text={t(['Invalid email', 'hcpCreation.emailError'])} errorMessage="Please enter a valid email address" />
                                </div>
                            }
                            <h3><span>2</span> {t(['Program configuration', 'hcpCreation.steps.programConfiguration'])}</h3>
                            <div className="fieldWrapper programTypeWrapper">
                                <p>{t(['Please select a program type, this will update the columns displayed in your active patients dashboard.', 'hcpUpdateConfiguration.program.text'])}</p>

                                <PatientsProgramSelection onChanged={(selectedProgram) => this.handleChangeProgram(selectedProgram)} program={state.program} />
                            </div>
                            <h3><span>3</span> {t(['Terms and conditions', 'hcpCreation.steps.termsAndConditions'])}</h3>
                            <div className="fieldWrapper">
                                <div className="checkboxFieldWrapper">
                                    <input type="checkbox" name="legalFlag-privacyPolicy" id="privacyPolicy" required aria-required="true" value={this.state.legalFlagPrivacyPolicy.toString()} onChange={this.handleLegalFlagPrivacyPolicy} />
                                    <label htmlFor="privacyPolicy" className={`mandatoryCheckbox ${(!this.state.legalFlagPrivacyPolicy) ? 'empty' : 'filled'}`}>
                                        {t(['I agree with the', 'hcpCreation.iAgree'])}{' '}
                                        <a target="_blank" rel="noreferrer" href={`https://help.gabismartcare.com/hcp-privacy-policy-${__APP_VERSION__}`}>
                                            {t(['Privacy policy', 'hcpCreation.privacyPolicy'])}
                                        </a>
                                    </label>
                                </div>
                            </div>
                            <div className="fieldWrapper">
                                <div className="checkboxFieldWrapper">
                                    <input type="checkbox" name="legalFlag-termsOfUse" id="termsOfUse" required aria-required="true" value={this.state.legalFlagTermsOfUse.toString()} onChange={this.handleLegalFlagTermsOfUse} />
                                    <label htmlFor="termsOfUse" className={`mandatoryCheckbox ${(!this.state.legalFlagTermsOfUse) ? 'empty' : 'filled'}`}>
                                        {t(['I agree with the', 'hcpCreation.iAgree'])}{' '}
                                        <a target="_blank" rel="noreferrer" href={`https://help.gabismartcare.com/hcp-tou-${__APP_VERSION__}`}>
                                            {t(['Terms of use', 'hcpCreation.termsOfUse'])}
                                        </a>
                                    </label>
                                </div>
                            </div>
                            <div className="step-form-actions">
                                <Button className="button-create" disabled={!this.isFilled()} loading={props.loading}>{t(['Create my account', 'hcpCreation.confirm'])}</Button>
                            </div>
                        </form>
                    </FlexChild>
                </FlexLayout>
            </div>
        );
    }

    handleChangeFirstName(e) {
        this.setState(state => ({
            ...state,
            firstName: e.target.value,
        }));
    }

    handleChangeLastName(e) {
        this.setState(state => ({
            ...state,
            lastName: e.target.value,
        }));
    }

    handleChangeGender(e) {
        this.setState(state => ({
            ...state,
            gender: (e.target.value === '') ? '' : parseInt(e.target.value),
        }));
    }

    handleChangePhoneNumber(data) {
        if(data) {
            const parsedPhoneNumber = parsePhoneNumber(data);

            if(parsedPhoneNumber) {
                this.setState(state => ({
                    ...state,
                    phoneNumber: parsedPhoneNumber.nationalNumber,
                    phoneCountryCode: parsedPhoneNumber.countryCallingCode,
                }));
            }
        } else {
            this.setState(state => ({
                ...state,
                phoneNumber: '',
                phoneCountryCode: '',
            }));
        }
    }

    handleChangeAddressState(e) {
        this.setState(state => ({
            ...state,
            addressState: e.target.value,
        }));
    }

    handleChangeAddressCity(e) {
        this.setState(state => ({
            ...state,
            addressCity: e.target.value,
        }));
    }

    handleChangeAddressCountry(e) {
        this.setState(state => ({
            ...state,
            addressCountry: e.target.value,
            defaultPhoneCountryCode: e.target.value,
        }));
    }

    handleLegalFlagPrivacyPolicy() {
        this.setState(state => ({
            ...state,
            legalFlagPrivacyPolicy: !this.state.legalFlagPrivacyPolicy,
        }));
    }

    handleLegalFlagTermsOfUse() {
        this.setState(state => ({
            ...state,
            legalFlagTermsOfUse: !this.state.legalFlagTermsOfUse,
        }));
    }

    handleChangeEmail(e) {
        this.setState(state => ({
            ...state,
            email: e.target.value,
        }));
    }

    handleValidateEmail(e) {
        const email = e.target.value;
        const pattern = /^\S{1,60}@{1}[a-z0-9_-]{1,60}(\.[a-z0-9_-]{2,60})+$/;

        this.setState(state => ({
            ...state,
            emailValid: pattern.test(email),
        }));
    }

    handleChangeProgram(program) {
        this.setState(state => ({
            ...state,
            program: program,
        }));
    }

    async getGeoCountry() {
        try {
            const countryCode = await GeoCountryService.fetchCountryCode();
            this.setState(state => ({
                ...state,
                defaultPhoneCountryCode: countryCode,
            }));
        }
        catch (error) {
            console.error(error);
        }
    }

    handleSubmit(e) {
        e.preventDefault();

        if(this.props.onSubmit) {
            const hcp = {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                gender: this.state.gender,
                email: this.state.email,
                phone: {
                    number: this.state.phoneNumber,
                    countryCode: this.state.phoneCountryCode,
                },
                address: {
                    state: this.state.addressState,
                    city: this.state.addressCity,
                    country: this.state.addressCountry,
                },
                legalFlags: {
                    privacyPolicy: this.state.legalFlagPrivacyPolicy,
                    termsOfUse: this.state.legalFlagTermsOfUse,
                },
                program: this.state.program
            };
            this.props.onSubmit(hcp);
        }
    }
}

//language=SCSS
HcpCreationForm = Styled(HcpCreationForm)`
    & {
        .PhoneInputCountryIcon--border {
            box-shadow: inherit;
            background: none;
        }

        .PhoneInputCountryIcon {
            width:60px;
            height:38px;
        }
        
        .create-hcp-illustration {
            width:400px;
            padding-right:40px;
            > img {
                margin-top:70px;
            }
        }
        
        .create-hcp-header {
            display:flex;
            margin-bottom: 20px;
            img {
                margin-right:20px;
            }
            h1 {
                margin-top: 10px;
            }
        }
        
        .create-hcp-form {
            width: 400px;
            h3 {
                font-size: 16px;
                margin-top: 30px;
                margin-bottom: 20px;
                position: relative;
                padding-left: 40px;
                padding-top: 7px;
                span {
                    display:block;
                    padding:6px 5px;
                    color:${colorPalette.frontColor};
                    border-radius:50px;
                    width:30px;
                    height:30px;
                    text-align:center;
                    background-color:${colorPalette.activeColor};
                    position:absolute;
                    left:0;
                    top:0;
                }
            }
            .programTypeWrapper > div {
                margin-left: 10px;
            }
            
            label a {
                color: #fff;
                text-decoration: underline;
            }
        }
    }
`;

export default withTranslation()(HcpCreationForm);
