import React from 'react';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';
import {withTranslation} from 'react-i18next';

import {BackendApiService} from '@/services/backend-api-service';
import LoadingView from '@/components/static/loading-view';
import {FeedbackAlertsService} from '@/services/feedback-alerts-service';
import {AlertTypeEnum} from '@/enum/alert-type-enum';
import {PatientHealthConditionDetailsUpdate} from '@/components/business/analytics/patient/patient-health-condition/patient-health-condition-details-update';

class PatientUpdateHealthCondition extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        t: PropTypes.func,
        patient: PropTypes.object.isRequired,
        onChanged: PropTypes.func,
    };

    state = {
        loading: false,
        loadingTypes: false,
        loadingTypesSelected: false,
        healthConditionDetails: null,
        healthConditionTypes: null,
        healthConditionTypesSelected: null,
    };

    constructor(props) {
        super(props);
        autoBind(this);
    }

    componentDidMount() {
        this.listHealthConditionTypes();
        this.getHealthCondition();
    }

    render() {
        const props = this.props;
        const state = this.state;
        const { t } = this.props;

        return (
            <div className={props.className}>
                <h3>{t(['Patient health condition', 'createHealthCondition.title'])}</h3>

                {!state.loading && !state.loadingTypes && !state.loadingTypesSelected && state.healthConditionTypes && (
                    <PatientHealthConditionDetailsUpdate
                        patient={props.patient}
                        healthConditionDetails={state.healthConditionDetails}
                        healthConditionTypes={state.healthConditionTypes}
                        healthConditionTypesSelected={state.healthConditionTypesSelected}
                        onSaved={this.handleFormSaved}
                        onChanged={this.handleFormChanged}
                    />
                )}
                {state.loading &&
                    <LoadingView/>
                }
            </div>
        );
    }

    handleFormChanged(notSavedDataChild) {
        if (this.props.onChanged) {
            this.props.onChanged(notSavedDataChild);
        }
    }
    
    handleFormSaved() {
        this.getHealthCondition();
        if (this.props.onChanged) {
            this.props.onChanged(false);
        }
        FeedbackAlertsService.add(AlertTypeEnum.SUCCESS, 'updateHealthConditionDetails', 'Health condition updated!');
    }

    listHealthConditionTypes() {
        this.setState(state => ({
            ...state,
            loadingTypes: true,
        }));

        BackendApiService.getRequest({
            domain: 'patient',
            modelName: 'listHealthConditionTypes',
        }).then((response) => {
            this.setState(state => ({
                ...state,
                loadingTypes: false,
                healthConditionTypes: response.healthConditionTypesList,
            }));
            return response;
        }).catch(err => {
            console.error(err);
        });
    }
    
    getHealthCondition() {
        this.setState(state => ({
            ...state,
            loadingTypesSelected: true,
        }));
        BackendApiService.getRequest({
            domain: 'patient',
            modelName: 'getHealthCondition',
            data: {
                patientId: this.props.patient.patientId.id
            }
        }).then((response) => {
            this.setState(state => ({
                ...state,
                loadingTypesSelected: false,
                healthConditionDetails: response.healthConditionDetail,
                healthConditionTypesSelected: response.healthConditionTypes.healthConditionTypesList,
            }));
            return response;
        }).catch(err => {
            console.error(err);
        });
    }
}

export default withTranslation()(PatientUpdateHealthCondition);

