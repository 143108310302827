import PropTypes from 'prop-types';
import styled from 'styled-components';
import {BackendApiService} from '@/services/backend-api-service.js';
import {useEffect, useState} from 'react';
import LoadingView from '@/components/static/loading-view.tsx';
import PatientsInactiveTable from '@/components/business/analytics/patients/patients-inactive-table.jsx';
import {NewDateUtil} from '@/util/new-date-util.js';
import {subDays} from 'date-fns';

const PatientsInactive = () => {
    const [patientsInactiveData, setPatientsInactiveData] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getPatientsInactiveData();
    }, []);

    const getPatientsInactiveData = () => {
        setLoading(true);

        const noRecordingSinceDate = subDays(NewDateUtil(), 6);
        const hasRecordingAfterDate = subDays(NewDateUtil(), 90);
        const createdAfterDate = subDays(NewDateUtil(), 90);

        BackendApiService.getRequest({
            modelName: 'getInactivePatients',
            domain: 'patient',
            data: {
                noRecordingSince : noRecordingSinceDate,
                hasRecordingAfter: hasRecordingAfterDate,
                createdAfter: createdAfterDate,
            }
        })
            .then((response) => {
                const inactivePatientsList = response.patientList;

                inactivePatientsList.forEach(patient => {
                    if(patient.lastRecordingDataTime) {
                        const lastRecordingDateTime = new Date(
                            patient.lastRecordingDataTime.date.year,
                            patient.lastRecordingDataTime.date.month - 1,
                            patient.lastRecordingDataTime.date.day,
                            patient.lastRecordingDataTime.time.hour,
                            patient.lastRecordingDataTime.time.minutes,
                            patient.lastRecordingDataTime.time.seconds
                        );
                        patient.lastRecordingDataTimeSortable = lastRecordingDateTime.getTime();
                    } else {
                        patient.lastRecordingDataTimeSortable = 10E15; // Create fake timestamp with huge value
                    }
                });

                setPatientsInactiveData(inactivePatientsList);
                setLoading(false);
                return response;
            })
            .catch(err => {
                console.error(err);
            });
    };

    if(!loading && patientsInactiveData) {
        return (
            <StyledWrapper>
                <PatientsInactiveTable patients={patientsInactiveData} />
            </StyledWrapper>
        );
    } else {
        return <LoadingView size="large" />;
    }
};

PatientsInactive.propTypes = {
    t: PropTypes.func,
};

//language=SCSS
const StyledWrapper = styled.div`
  && {
      padding-bottom: 50px;
  }
`;

export default PatientsInactive;
