import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import ComponentErrorMessage from '@/components/widgets/component-error-message.tsx';
import {roundDurationHoursToQuarterPrecision} from '@/util/time-utils';

const PatientRecordingDuration = ({duration}) => {
    const { t } = useTranslation();

    if(duration) {
        const durationHours = roundDurationHoursToQuarterPrecision(duration);

        if(!isNaN(duration)) {
            return (
                <>
                    {durationHours}h
                </>
            );
        } else {
            return <em className="invalid-duration-message">{t(['Invalid duration', 'patient.invalidDuration'])}</em>;
        }
    } else {
        return <ComponentErrorMessage component="PatientRecordingDuration" />;
    }
};

PatientRecordingDuration.propTypes = {
    duration: PropTypes.number.isRequired,
};

export default PatientRecordingDuration;
