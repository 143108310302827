import {subDays} from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';
import {getI18n, withTranslation} from 'react-i18next';
import {connect as redux_connect} from 'react-redux/es/exports';
import {Link} from 'react-router-dom';
import Styled from 'styled-components';

import GuidedTourHealthReport from '@/components/business/analytics/guidedTour/guided-tour-health-report';
import {HcpTutorialStatus} from '@/components/business/analytics/hcp/hcp-tutorial-status';
import {HealthReportAggregatedSignal} from '@/components/business/analytics/health-report/health-report-aggregated-signal';
import HealthReportDuration from '@/components/business/analytics/health-report/health-report-duration';
import {HealthReportEventsList} from '@/components/business/analytics/health-report/health-report-events-list';
import {HealthReportEventsPerHour} from '@/components/business/analytics/health-report/health-report-events-per-hour';
import HealthReportNoRecording from '@/components/business/analytics/health-report/health-report-no-recording';
import HealthReportWrapper from '@/components/business/analytics/health-report/health-report-wrapper';
import ErrorBoundary from '@/components/errors/error-boundary';
import ColoredContainer from '@/components/layout/colored-container';
import {Loader} from '@/decorators/loader.new';
import {Page} from '@/decorators/page';
import withRouter from '@/decorators/withRouter';
import {HealthReportDashboardTypeEnum} from '@/enum/health-report-dashboard-type-enum';
import {getSignalTypeUnit, SignalTypeEnum} from '@/enum/signal-type-enum';
import timelineIcon from '@/public/icons/timeline-icon.svg';
import {formatApiDateToJSDate} from '@/services/api-requests/requests-utils';
import {colorPalette, colors, medias} from '@/themes/darkmode';
import {EventThresholdType} from '@/types/event-threshold-type';
import {NewDateUtil} from '@/util/new-date-util';
import {formatApiDateForUrl, formatDateForUrl} from '@/util/url-util';

function getPagePath() {
    return [
        {
            route: 'patients',
            name: 'Patients'
        },
    ];
}

function wrapWithPage(Component) {
    return Page({
        name: () => getI18n().t(['Health Report - last night', 'healthReport.lastnight.title']),
        pagePath: props => (
            getPagePath(props)
        )
    })(Component);
}

function wrapWithLoader(Component) {
    return Loader({
        models: {
            eventConfiguration: props => ({ name: 'getEventConfiguration', domain: 'patient', id: props.params.id_patient, cached: true }),
            patientData: props => ({ name: 'getPatientDetails', domain: 'patient', data: {patientId: props.params.id_patient}, cached: true }),
        },
    })(Component);
}

const reduxMapStateToProps = function(state) {
    return {
        tutorialStatus: state.tutorialStatus,
    };
};

class HealthReportLastAvailableNightPage extends React.Component {
    static propTypes = {
        eventConfiguration: PropTypes.object.isRequired,
        patientData: PropTypes.object.isRequired,
        t: PropTypes.func,
        params: PropTypes.object,
        className: PropTypes.string,
        tutorialStatus: PropTypes.any,
        navigate: PropTypes.func,
    };

    state = {
        eventConfiguration: this.props.eventConfiguration,
    };

    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        const props = this.props;
        const patientId = props.params.id_patient;
        const eventConfiguration = this.state.eventConfiguration;
        const t = this.props.t;

        let dateFrom = null;
        let dateTo = null;
        let dashboardHasContent = false;
        let timelineDate = null;
        if(props.patientData.lastRecordingDate) {
            dateFrom = formatApiDateToJSDate(props.patientData.lastRecordingDate);
            dateTo = formatApiDateToJSDate(props.patientData.lastRecordingDate);
            timelineDate = formatApiDateForUrl(props.patientData.lastRecordingDate);
            const lastRecordingDateLimit = subDays(NewDateUtil(), 30);
            dashboardHasContent = dateFrom.getTime() > lastRecordingDateLimit.getTime();
        }

        return (
            <div className={props.className}>
                <ErrorBoundary>
                    <HealthReportWrapper
                        patient={props.patientData}
                        activeDashboard={HealthReportDashboardTypeEnum.LAST_24H}
                        eventConfiguration={eventConfiguration}
                        onChanged={this.handleEventConfigurationChanged}
                    >

                        {props.tutorialStatus.guidedTourHealthReport === HcpTutorialStatus.ENABLED &&
                            <GuidedTourHealthReport />
                        }

                        {dashboardHasContent && (
                            <div className="health-report-switch-wrapper">
                                <HealthReportDuration patientId={patientId} dateFrom={dateFrom} dateTo={dateTo}/>
                                <Link
                                    to={`/patients/${patientId}/timeline/${timelineDate}`}
                                    id="button-timeline"
                                    className="button-link"
                                >
                                    {t(['Daily biometrics', 'healthReportDashboardNavigation.dailyBiometrics'])}
                                </Link>
                            </div>
                        )}

                        {dashboardHasContent && (
                            <div className={'health-report-content health-report-content-view'}>
                                <ColoredContainer className="widget-aggregated-signal" color={colorPalette.signalType.pulseRate}>
                                    <HealthReportAggregatedSignal
                                        patientId={patientId}
                                        signalType={SignalTypeEnum.PULSE_RATE}
                                        date={dateFrom}
                                        signalEventConfiguration={eventConfiguration.configuration.hr}
                                        onClick={this.handleAggregatedSignalChartClick}
                                    />
                                </ColoredContainer>

                                <ColoredContainer className="widget-events" color={colorPalette.signalType.pulseRate}>
                                    <HealthReportEventsPerHour
                                        patientId={patientId}
                                        signalType={SignalTypeEnum.PULSE_RATE}
                                        thresholdTypes={[ EventThresholdType.HIGH, EventThresholdType.LOW ]}
                                        date={dateFrom}
                                        subtitle={<span>
                                            {t(['#events/h with aduration of X seconds', 'healthReport.events.last24h.subtitle'], {duration: eventConfiguration.configuration.hr.sensibility.seconds})}
                                        </span>}
                                        signalEventConfiguration={eventConfiguration.configuration.hr}
                                        onClick={this.handleEventsPerHourChartClick}
                                    />
                                </ColoredContainer>
                                
                                <ColoredContainer className="widget-aggregated-signal" color={colorPalette.signalType.spo2}>
                                    <HealthReportAggregatedSignal
                                        patientId={patientId}
                                        signalType={SignalTypeEnum.SPO2}
                                        date={dateFrom}
                                        signalEventConfiguration={eventConfiguration.configuration.spo2}
                                        onClick={this.handleAggregatedSignalChartClick}
                                    />
                                </ColoredContainer>

                                <ColoredContainer className="widget-events" color={colorPalette.signalType.spo2}>
                                    <HealthReportEventsPerHour
                                        patientId={patientId}
                                        signalType={SignalTypeEnum.SPO2}
                                        thresholdTypes={[ EventThresholdType.LOW ]}
                                        date={dateFrom}
                                        subtitle={`
                                            ${t(['Below', 'global.below'])} ${eventConfiguration.configuration.spo2.low.value}${getSignalTypeUnit(SignalTypeEnum.SPO2)}
                                        `}
                                        signalEventConfiguration={eventConfiguration.configuration.spo2}
                                        onClick={this.handleEventsPerHourChartClick}
                                    />
                                </ColoredContainer>

                                <HealthReportEventsList
                                    patientId={patientId}
                                    date={dateFrom}
                                    eventConfiguration={eventConfiguration}
                                />
                            </div>
                        )}
                        {!dashboardHasContent && (
                            <HealthReportNoRecording patient={props.patientData} activeDashboard={HealthReportDashboardTypeEnum.LAST_24H} />
                        )}
                    </HealthReportWrapper>
                </ErrorBoundary>
            </div>
        );
    }

    handleAggregatedSignalChartClick(data) {
        const selectedHour = data.at.time;
        let url = `/patients/${this.props.params.id_patient}/timeline/${data.at.date}/${selectedHour}`;
        this.props.navigate(url);
    }

    handleEventsPerHourChartClick(data) {
        const selectedHour = (data.hour < 10) ? `0${data.hour}:00` : `${data.hour}:00`;
        let url = `/patients/${this.props.params.id_patient}/timeline/${formatDateForUrl(data.time)}/${selectedHour}`;
        this.props.navigate(url);
    }

    handleEventConfigurationChanged(selectedEventConfiguration) {
        this.setState(state => ({
            ...state,
            eventConfiguration: selectedEventConfiguration,
        }));
    }
}

//language=SCSS
HealthReportLastAvailableNightPage = Styled(HealthReportLastAvailableNightPage)`
& {
    .health-report-switch-wrapper {
        position:relative;
        margin-bottom:20px;
        #button-timeline {
            background: ${colors.buttonCreate.background} url(${timelineIcon}) no-repeat 15px center;
            background-size: 25px;
            position: absolute;
            right: 0;
            top: 0;
            padding: 10px 15px 10px 50px;
            border-radius: 5px;
            &:hover, &:focus {
                background-color: ${colors.buttonCreate.backgroundHover};
            }
        }
    }
    
    .health-report-content {
        display: grid;
        grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
        @media screen and (${medias.xlMin}) {
          grid-template-columns: minmax(0, 2fr) minmax(250px, 1fr) minmax(260px, 1fr);
        }
        
        grid-auto-rows: 340px;
        flex-wrap: nowrap;
        gap: 10px;
    }

    .health-report-events-list {
        display: none;
        @media screen and (${medias.xlMin}) {
            display: block;
        }
        grid-column: 3;
        grid-row: 1;
        grid-row-end: span 2;
    }
    
    .widget-aggregated-signal {
        grid-column: 1;
    }
    
    .widget-events {
        grid-column: 2;
    }
}
`;

export default redux_connect(reduxMapStateToProps)(withRouter(wrapWithPage(wrapWithLoader(withTranslation()(HealthReportLastAvailableNightPage)))));
