import deepEqual from 'deep-eql';
import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';
import {CartesianGrid, Line, LineChart, ReferenceArea, ReferenceLine, Tooltip, XAxis, YAxis} from 'recharts';

import {ChartAxisTickHourMinutesFromTimestamp} from '@/components/business/analytics/patient/patient-chart-axis/chart-axis-tick-hour-minutes-from-timestamp';
import {EmptyTooltip} from '@/components/business/analytics/timeline/empty-tooltip';
import {getDomainForSignalType} from '@/components/business/analytics/timeline/timeline-chart-domains';
import {TimelineTick} from '@/components/business/analytics/timeline/timeline-tick';
import {Autosized} from '@/components/layout/autosized';
import {FlexChild, FlexLayout} from '@/components/layout/flex-layout';
import {getSignalTypeIdentifier, SignalTypeEnum} from '@/enum/signal-type-enum';
import {colorPalette} from '@/themes/darkmode';

//Empty div used to remove React warnings due to unknown props
function EmptyDiv() { return (<div/>); }

export const subchartsPropType = PropTypes.arrayOf((propValue) => {
    const values = [
        SignalTypeEnum.PULSE_RATE,
        SignalTypeEnum.SPO2,
        SignalTypeEnum.MOVEMENTS,
    ];
    for (let i=0; i<propValue.length; ++i) {
        if (!values.includes(propValue[i])) {
            return new Error(`Invalid value ${propValue[i]}; should be one of ${values}`);
        }
    }
});

let renderStartTime = null;

export class TimelineChartPlot extends React.Component {
    static propTypes = {
        baseDate: PropTypes.instanceOf(Date),
        chartData: PropTypes.object,
        className: PropTypes.string,
        domainX: PropTypes.array.isRequired,
        eventConfiguration: PropTypes.object.isRequired,
        height: PropTypes.number.isRequired,
        medicalEvents: PropTypes.object.isRequired,
        ranges: PropTypes.object.isRequired,
        subcharts: subchartsPropType,
        width: PropTypes.number.isRequired,
        yaxisWidth: PropTypes.number.isRequired,
        onScroll: PropTypes.func,
        onMouseDown: PropTypes.func.isRequired,
        onMouseMove: PropTypes.func.isRequired,
        onMouseUp: PropTypes.func.isRequired,
    };
    
    constructor(props) {
        super(props);
        autoBind(this);
    }
    
    componentDidUpdate() {
        const renderEndTime = Date.now();
        console.debug(`Time to Render: ${renderEndTime - renderStartTime} ms`);
    }
    
    componentDidMount() {
        const renderEndTime = Date.now();
        console.debug(`Time to Render 1: ${renderEndTime - renderStartTime} ms`);
    }
    
    render() {
        const props = this.props;

        const hasSubcharts = props.subcharts.reduce((acc, item) => {
            acc[item] = true;
            return acc;
        }, {});
        
        renderStartTime = Date.now();
        const ranges = props.ranges;
        const chartData = props.chartData;
        const medicalEvents = props.medicalEvents;
        const eventConfiguration = props.eventConfiguration;
        
        return (
            <div className="fullh" /*onWheel={this.handleWheel}*/>
                <FlexLayout direction="column" className="fullh">
                    {hasSubcharts[SignalTypeEnum.PULSE_RATE] && this.renderSubChart(SignalTypeEnum.PULSE_RATE, chartData.hr,         medicalEvents.hr,   eventConfiguration.configuration.hr,   colorPalette.signalType[getSignalTypeIdentifier(SignalTypeEnum.PULSE_RATE)], getDomainForSignalType(SignalTypeEnum.PULSE_RATE, ranges.hr[0],         ranges.hr[1]),       )}
                    {hasSubcharts[SignalTypeEnum.SPO2]       && this.renderSubChart(SignalTypeEnum.SPO2,       chartData.spo2,       medicalEvents.spo2, eventConfiguration.configuration.spo2, colorPalette.signalType[getSignalTypeIdentifier(SignalTypeEnum.SPO2)],       getDomainForSignalType(SignalTypeEnum.SPO2,       ranges.spo2[0],       ranges.spo2[1]),     )}
                    {hasSubcharts[SignalTypeEnum.MOVEMENTS]  && this.renderSubChart(SignalTypeEnum.MOVEMENTS,  chartData.actigraphy, [],                 null,                                  colorPalette.signalType[getSignalTypeIdentifier(SignalTypeEnum.MOVEMENTS)],  getDomainForSignalType(SignalTypeEnum.MOVEMENTS,  ranges.actigraphy[0], ranges.actigraphy[1]))}
                </FlexLayout>
            </div>
        );
    }
    
    renderSubChart(subchartType, data, medicalEvents, eventConfiguration, color, domainDefinition) {
        const props = this.props;
        const tick = props.subcharts[props.subcharts.length-1] === subchartType ? (
            (tickProps) => <TimelineTick {...tickProps} baseDate={props.baseDate} />
        ) : (
            <EmptyDiv/>
        );
        const tickHeight = props.subcharts[props.subcharts.length-1] === subchartType ? undefined : 1;
        const colorKey = (
            subchartType === SignalTypeEnum.PULSE_RATE ? 'hr' :
                subchartType === SignalTypeEnum.SPO2 ? 'spo2' :
                    subchartType === SignalTypeEnum.MOVEMENTS ? 'actigraphy' :
                        'unknownRange'
        );

        // Computing overview blocks
        const dataWithOneEmptySecond = this.createNonSparseArray(data, subchartType);
        
        return (
            <FlexChild className={`patient-timeline-subchart-${subchartType}`} grow={1} height={50}>
                <Autosized component={({height}) => {
                    return (
                        <LineChart
                            width={props.width}
                            height={height}
                            data={dataWithOneEmptySecond}
                            syncId="anyId"
                            margin={{
                                top: 0, right: 0, left: 0, bottom: 5,
                            }}
                            style={{display: 'inline-block', float: 'left'}}
                            cursor="pointer"
                            onMouseDown={this.handleChartMouseDown}
                            onMouseMove={this.handleChartMouseMove}
                            onMouseUp={this.handleChartMouseUp}
                        >
                            <CartesianGrid strokeDasharray="1 3" />

                            <YAxis
                                dataKey={`${colorKey}Range`}
                                type="number"
                                name="value"
                                hide={true}
                                tickCount={4}
                                domain={domainDefinition.domain}
                                ticks={domainDefinition.ticks}
                                allowDataOverflow={true}
                                interval={0}
                            />
                            <XAxis
                                dataKey="secondsSince12pm"
                                type="number"
                                name="t"
                                domain={[0, 24*3600]}
                                //ticks={ticks}
                                tickCount={24*3600/(60*5) + 1}
                                interval={0}
                                tick={tick}
                                height={tickHeight}
                                //interval="preserveStart"
                            />

                            {medicalEvents.map(block => {
                                if (block.fromSecondsSince12pm === block.toSecondsSince12pm) {
                                    return (
                                        <ReferenceLine
                                            key={`eventBlock-${subchartType}-${block.fromSecondsSince12pm}`}
                                            x={block.fromSecondsSince12pm}
                                            stroke={color}
                                            strokeWidth={0.5}
                                        />
                                    );
                                }
                                else {
                                    return (
                                        <ReferenceArea
                                            key={`eventBlock-${subchartType}-${block.fromSecondsSince12pm}`}
                                            x1={block.fromSecondsSince12pm}
                                            x2={block.toSecondsSince12pm}
                                            fill={color}
                                            strokeOpacity={0.0}
                                            fillOpacity={0.35}
                                        />
                                    );
                                }
                            })}
                            <Line
                                isAnimationActive={false}
                                type="linear"
                                dot={false}
                                activeDot={false}
                                dataKey="value"
                                stroke={'#4f5b74'}
                                strokeWidth={1.5}
                                connectNulls={false}
                            />
                            <Line
                                isAnimationActive={false}
                                type="linear"
                                dot={false}
                                activeDot={false}
                                dataKey="reliableValue.value"
                                stroke={color}
                                strokeWidth={2}
                                connectNulls={false}
                            />
                            {eventConfiguration && subchartType !== SignalTypeEnum.SPO2 &&
                                <ReferenceLine
                                    y={eventConfiguration.high.value}
                                    /*label={{
                                        position: (eventConfiguration.high.value >= (domainY[1] - (5))) ? 'bottom' : 'top',
                                        value: `${eventConfiguration.high.value}${getSignalTypeUnit(subchartType)}`,
                                        fill: colorPalette.signalTypeClear[getSignalTypeIdentifier(subchartType)]
                                    }}*/
                                    stroke={colorPalette.signalTypeClear[getSignalTypeIdentifier(subchartType)]}
                                    strokeDasharray="4"
                                />
                            }
                            {eventConfiguration &&
                                <ReferenceLine
                                    y={eventConfiguration.low.value}
                                    /*label={{
                                        position: (eventConfiguration.low.value <= (domainY[0] + (5))) ? 'top' : 'bottom',
                                        value: `${eventConfiguration.low.value}${getSignalTypeUnit(subchartType)}`,
                                        fill: colorPalette.signalTypeClear[getSignalTypeIdentifier(subchartType)]
                                    }}*/
                                    stroke={colorPalette.signalTypeClear[getSignalTypeIdentifier(subchartType)]}
                                    strokeDasharray="4"
                                />
                            }
                            <Tooltip
                                cursor={{ stroke: colorPalette.activeColorDarker, strokeWidth: 1 }}
                                content={<EmptyTooltip />}
                            />
                        </LineChart>
                    );
                }} />
            </FlexChild>
        );
    }
    
    createNonSparseArray(inputArray, subchartType) {
        const totalSeconds = 24 * 3600;
        const nonSparseArray = new Array(totalSeconds + 1);
        const inputLength = inputArray.length;
        
        let inputIndex = 0;
        
        for (let t = 0; t <= totalSeconds; t++) {
            const item = inputArray[inputIndex];
            if (inputIndex < inputLength && item.secondsSince12pm === t) {
                nonSparseArray[t] = {
                    secondsSince12pm: item.secondsSince12pm,
                    value: item.value,
                    reliableValue: item.reliableValue,
                    reliability: item.reliability,
                    index: item.index,
                    subchartType: subchartType,
                };
                
                // Looping more than once should not happen but this is there to ensure no data gets shifted
                while (inputArray[inputIndex] && inputArray[inputIndex].secondsSince12pm === t) {
                    ++inputIndex;
                }
            }
            else {
                nonSparseArray[t] = {
                    secondsSince12pm: t,
                    subchartType: subchartType,
                };
            }
        }
        
        return nonSparseArray;
    }

    handleChartMouseDown(entry) {
        this.props.onMouseDown(entry);
    }

    handleChartMouseMove(entry, e) {
        this.props.onMouseMove(entry, e);
    }

    handleChartMouseUp(entry) {
        this.props.onMouseUp(entry);
    }
    
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    shouldComponentUpdate(nextProps, nextState, _) {
        return (
            !deepEqual(this.props.width, nextProps.width) ||
            !deepEqual(this.props.subcharts, nextProps.subcharts) ||
            !deepEqual(this.props.medicalEvents, nextProps.medicalEvents) ||
            !deepEqual(this.state, nextState)
        );
    }
}

export { ChartAxisTickHourMinutesFromTimestamp };
