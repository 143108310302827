import React from 'react';
import PropTypes from 'prop-types';
import {BackendApiService} from '@/services/backend-api-service.js';
import LoadingView from '@/components/static/loading-view.tsx';
import autoBind from 'react-autobind';
import {GroupUserRole} from '@/components/business/analytics/group/group-user-role.jsx';
import HcpUpdateGroupsForm from '@/components/business/analytics/hcp/hcp-update-groups-form.jsx';
import Styled from 'styled-components';
import {InfoMessage} from '@/components/form/info-message.jsx';
import {withTranslation} from 'react-i18next';

class HcpUpdateGroups extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        t: PropTypes.func,
        hcp: PropTypes.object.isRequired,
        onSaved: PropTypes.func
    };

    state = {
        loading: false,
        groups: null,
    };

    constructor(props) {
        super(props);
        autoBind(this);
    }

    componentDidMount() {
        this.fetchGroups();
    }

    render() {
        const props = this.props;
        const state = this.state;
        const { t } = this.props;

        return (
            <div className={props.className}>
                <h3>Groups access</h3>

                {!state.loading && state.groups &&
                    <div className="groups-wrapper">
                        {state.groups.map(group => (
                            <HcpUpdateGroupsForm key={group.groupId.id} group={group} loading={state.loading} onLeaveGroup={this.handleLeaveGroup} />
                        ))}
                        {state.groups.length === 0 &&
                            <InfoMessage className="info-message" infoMessage={t(['You are not part of any group.', 'hcpUpdateGroups.empty'])} />
                        }
                    </div>
                }
                {state.loading &&
                    <LoadingView />
                }
            </div>
        );
    }

    handleLeaveGroup(group) {
        this.leaveGroup(group);
    }

    async leaveGroup(group) {
        this.setState(state => ({
            ...state,
            loading: true,
        }));

        BackendApiService.getRequest({
            domain: 'group',
            modelName: (group.requesterRole === GroupUserRole.ADMIN) ? 'removeUserAdminFromGroup' : 'removeUserMemberFromGroup',
            data: {
                groupId: group.groupId.id,
                userId: this.props.hcp.sub.split('|')[1]
            }
        })
            .then((response) => {
                const updatedGroups = this.state.groups.filter(function(stateGroup) {
                    return stateGroup.groupId.id !== group.groupId.id;
                });

                this.setState(state => ({
                    ...state,
                    loading: false,
                    groups: updatedGroups,
                }));

                return response;
            })
            .catch(err => {
                console.error(err);
                this.setState(state => ({
                    ...state,
                    loading: false,
                }));
            });
    }

    async fetchGroups() {
        this.setState(state => ({
            ...state,
            loading: true,
        }));

        BackendApiService.getRequest({
            domain: 'group',
            modelName: 'groups',
        })
            .then((groups) => {
                this.setState(state => ({
                    ...state,
                    loading: false,
                    groups: groups.groupList,
                }));
                return groups;
            })
            .catch(err => {
                console.error(err);
                this.setState(state => ({
                    ...state,
                    loading: false,
                }));
            });
    }
}

//language=SCSS
HcpUpdateGroups = Styled(HcpUpdateGroups)`
    & {
        .groups-wrapper {
            display:flex;
            flex-wrap: wrap;
            .info-message {
                width: 100%;
            }
        }
    }
`;

export default withTranslation()(HcpUpdateGroups);

