import React from 'react';
import Styled from 'styled-components';
import PropTypes from 'prop-types';
import {colorPalette, medias} from '@/themes/darkmode.js';
import GroupPendingUserDetails from '@/components/business/analytics/group/group-pending-user-details.jsx';

class GroupPendingUsersList extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        emails: PropTypes.array.isRequired,
        groupId: PropTypes.string.isRequired,
    };

    state = {};

    render() {
        const props = this.props;

        return (
            <div className={props.className}>
                {props.emails.map(email => (
                    <GroupPendingUserDetails
                        key={`${props.groupId}-${email}`}
                        groupId={props.groupId}
                        email={email}
                    />
                ))}
            </div>
        );
    }
}

//language=SCSS
GroupPendingUsersList = Styled(GroupPendingUsersList)`
& {
    display:flex;
    flex-wrap: wrap;
    
    .user-block {
        position:relative;
        padding:15px;
        background:${colorPalette.secondBackground};
        margin:0 20px 20px 0;
        border-radius: 3px;
        width: calc(100% / 2 - 20px);
        @media(${medias.xlMin}) {
            width: calc(100% / 3 - 20px);
        }
    }
}
`;

export { GroupPendingUsersList };
