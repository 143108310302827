import React from 'react';
import PropTypes from 'prop-types';
import deepEqual from 'deep-eql';
import Styled from 'styled-components';
import { Link } from 'react-router-dom';
import { colorPalette } from '@/themes/darkmode.js';

class Breadcrumb extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        path: PropTypes.array,
    };
    
    render() {
        const props = this.props;
        const path = props.path;

        return (
            <div className={props.className}>
                {path && (
                    <span>
                        {path.map((item,index) => (
                            <span key={index} className="item">
                                <Link to={item.route}>{item.name}</Link>
                            </span>
                        ))}
                        {path.length > 0 ? ' >': ''}
                    </span>
                )}
            </div>
        );
    }
    
    shouldComponentUpdate(nextProps) {
        return !deepEqual(this.props, nextProps);
    }
}

//language=SCSS
Breadcrumb = Styled(Breadcrumb)`
& {
    white-space: nowrap;
    overflow: hidden;
    height: 24px;
    display: inline;
    
    .item {
        a {
            color: ${colorPalette.activeColor};
            display: inline-block;
            max-width: 20vw;
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: bottom;
        }
        &:not(:last-child):after {
            content: ">";
            padding: 0 7px;
            display: inline-block;
        }
    }
}
`;

export { Breadcrumb };
