import PropTypes from 'prop-types';
import styled from 'styled-components';
import HealthReportNavigation from '@/components/business/analytics/health-report/health-report-navigation.jsx';
import HealthReportSidebar from '@/components/business/analytics/health-report/health-report-sidebar.jsx';

const HealthReportWrapper = ({patient, activeDashboard, eventConfiguration, onChanged, children}) => {

    return (
        <StyledWrapper>
            <HealthReportSidebar
                patientId={patient.patientId.id}
                patient={patient}
                eventConfiguration={eventConfiguration}
                onEventConfigurationChanged={selectedEventConfiguration => onChanged(selectedEventConfiguration)}
            />

            <div className="health-report-widgets-wrapper">
                <div className="health-report-header">
                    <HealthReportNavigation
                        activeDashboard={activeDashboard}
                        patientId={patient.patientId.id}
                    />
                </div>

                {children}
            </div>
        </StyledWrapper>
    );
};

HealthReportWrapper.propTypes = {
    patient: PropTypes.object.isRequired,
    activeDashboard: PropTypes.number.isRequired,
    eventConfiguration: PropTypes.object.isRequired,
    onChanged: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
};

//language=SCSS
const StyledWrapper = styled.div`
  && {
      .health-report-widgets-wrapper {
          padding: 30px;
          padding-bottom: 160px;
          width: calc(100% - 250px);
          height: calc(100vh - 80px);
          overflow-y: scroll;
          position: absolute !important;
          top: 80px;
          left: 250px;
          overflow-x: hidden;
          z-index: -500;
      }
      
      .health-report-header {
          display:flex;
          position:relative;
      }
  }
`;

export default HealthReportWrapper;
