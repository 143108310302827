import PropTypes from 'prop-types';
import React from 'react';

import {Loader} from '@/decorators/loader.new';
import withRouter from '@/decorators/withRouter';
import {NewDateUtil} from '@/util/new-date-util';

function transformDaysWithData(data) {
    const out = [];
    data.dataIntervalList.forEach(line => {
        if (line.numberOfRecords && line.numberOfRecords > 0 && line.from) {
            out.push(new Date(line.from));
        }
    });
    return out;
}

function wrapWithLoader(Component) {
    return Loader({
        models: {
            daysWithData: props => ({ domain: 'signal', name: 'daysWithData', id: props.params.id_patient, transform: transformDaysWithData}),
        },
    })(Component);
}

class PatientTimelineRedirectPage extends React.Component {
    static propTypes = {
        daysWithData: PropTypes.array,
        params: PropTypes.object,
        navigate: PropTypes.func,
    };
    
    componentDidMount() {
        const props = this.props;

        let lastDayWithData = NewDateUtil();
        if(props.daysWithData.length > 0) {
            lastDayWithData = props.daysWithData[props.daysWithData.length - 1];
        }

        const dateYear = lastDayWithData.getFullYear();
        const dateMonth = (lastDayWithData.getMonth() < 10) ? `0${lastDayWithData.getMonth() + 1}` : lastDayWithData.getMonth() + 1;
        const dateDay = (lastDayWithData.getDate() < 10) ? `0${lastDayWithData.getDate()}` : lastDayWithData.getDate();
        const formattedDate = `${dateYear}-${dateMonth}-${dateDay}`;

        let url = `/patients/${this.props.params.id_patient}/timeline/${formattedDate}`;
        this.props.navigate(url, { replace: true });
    }
    
    render() {
        return null;
    }
}

export default withRouter(wrapWithLoader(PatientTimelineRedirectPage));

