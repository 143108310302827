import React from 'react';
import Styled from 'styled-components';
import autoBind from 'react-autobind';
import {Button} from '@/components/buttons/button.jsx';
import {BackendApiService} from '@/services/backend-api-service.js';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';

class GroupInviteUser extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        t: PropTypes.func,
        group: PropTypes.object.isRequired,
        email: PropTypes.string.isRequired,
        onInvited: PropTypes.func,
        onCancelled: PropTypes.func,
    };

    state = {
        userEmail: '',
        emailSent: false,
        loading: false,
    };

    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        const props = this.props;
        const state = this.state;
        const { t } = this.props;

        return (
            <div className={props.className}>
                {!state.emailSent &&
                    <div>
                        <p>{props.email} {t(['was not found in Gabi Analytics.', 'inviteUser.notFound'])} <br />{t(['Do you want to invite this user to create an account?', 'inviteUser.notFoundQuestion'])}</p>

                        <div>
                            <Button className="button-create" loading={state.loading} onClick={this.handleInviteUserToGroup}>{t(['Invite user to group', 'inviteUser.confirm'])}</Button>
                            <Button className="button-cancel" onClick={this.handleCancel}>{t(['Cancel', 'inviteUser.cancel'])}</Button>
                        </div>
                    </div>
                }

                {state.emailSent &&
                    <div>
                        <p>{props.email} {t(['has received an invitation to join your group!', 'inviteUser.invitationSent'])}</p>

                        <div>
                            <Button className="button-create" onClick={this.handleClickCloseButton}>{t(['Close', 'global.close'])}</Button>
                        </div>
                    </div>
                }
            </div>
        );
    }

    handleClickCloseButton() {
        if(this.props.onInvited) {
            this.props.onInvited(this.props.group.groupId.id);
        }
    }

    handleInviteUserToGroup() {
        this.inviteUserToGroup();
    }

    handleCancel(e) {
        e.preventDefault();
        if(this.props.onCancelled) {
            this.props.onCancelled();
        }
    }

    inviteUserToGroup() {
        this.setState(state => ({
            ...state,
            loading: true,
        }));

        BackendApiService.getRequest({
            domain: 'hcp',
            modelName: 'inviteUserToGroup',
            data: {
                groupId: this.props.group.groupId.id,
                email: this.props.email,
            }
        })
            .then((user) => {
                this.setState(state => ({
                    ...state,
                    loading: false,
                    emailSent: true,
                }));

                return user;
            })
            .catch(err => {
                console.error(err);
                this.setState(state => ({
                    ...state,
                    loading: false,
                }));
            });
    }
}

//language=SCSS
GroupInviteUser = Styled(GroupInviteUser)`
& {
    
}
`;

export default withTranslation()(GroupInviteUser);
