import {useEffect, useState} from 'react';
import sorter from 'sort-nested-json';

function getDefaultSorting(defaultTableData, columns, defaultSorting) {
    if(defaultTableData) {
        let defaultSortedTable;
        if(defaultSorting) {
            if(defaultSorting.sortByOrder === 'desc') {
                defaultSortedTable = sorter.sort(defaultTableData).desc(defaultSorting.accessor);
            } else {
                defaultSortedTable = sorter.sort(defaultTableData).asc(defaultSorting.accessor);
            }
        } else {
            defaultSortedTable = sorter.sort(defaultTableData).asc('id');
        }
        return defaultSortedTable;
    } else {
        return [];
    }
}

export const useSortableTable = (data, columns, defaultSorting) => {
    const [tableData, setTableData] = useState(getDefaultSorting(data, columns, defaultSorting));

    useEffect(() => {
        setTableData(data);
    }, [data]);

    const handleSorting = (sortField, sortOrder) => {
        if (sortField) {
            let sorted;
            if(sortOrder === 'desc') {
                sorted = sorter.sort(tableData).desc(sortField);
            } else {
                sorted = sorter.sort(tableData).asc(sortField);
            }
            setTableData(sorted);
        }
        return sortField;
    };

    return [tableData, handleSorting];
};
