import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {BackendApiService} from '@/services/backend-api-service.js';
import LoadingView from '@/components/static/loading-view.tsx';
import ComponentErrorMessage from '@/components/widgets/component-error-message.tsx';
import styled from 'styled-components';
import PatientGroupUserDetails from '@/components/business/analytics/patient/common/patient-group-user-details.jsx';

const PatientGroupUsers = ({groupId}) => {
    const [users, setUsers] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getGroupUsers();
    }, []);

    const getGroupUsers = () => {
        setLoading(true);

        BackendApiService.getRequest({
            domain: 'group',
            modelName: 'getGroup',
            data: {
                id: groupId
            }
        }).then(group => {
            setLoading(false);
            setUsers(group.usersList);

            return group;
        }).catch(err => {
            console.error(err);
            setLoading(false);
        });
    };

    if(groupId) {
        if(!loading && users) {
            return (
                <StyledWrapper className="patient-group-users">
                    {users.map(user => (
                        <PatientGroupUserDetails
                            key={`${groupId}-${user.id.id}`}
                            userId={user.id.id}
                            userRole={user.role}
                        />
                    ))}
                </StyledWrapper>
            );
        } else {
            return <LoadingView />;
        }
    } else {
        return <ComponentErrorMessage component="PatientGroupUsers" />;
    }
};

PatientGroupUsers.propTypes = {
    groupId: PropTypes.string.isRequired,
};

//language=SCSS
const StyledWrapper = styled.div`
  && {
      margin-top:15px;
  }
`;

export default PatientGroupUsers;
