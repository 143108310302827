import {useTranslation} from 'react-i18next';

import {getSignalTypeIdentifier, getSignalTypeLabel, getSignalTypeUnit, SignalTypeEnum} from '@/enum/signal-type-enum';
import {colorPalette} from '@/themes/darkmode';
import {formatDateTimeUniversal} from '@/util/date-utils';

type TimelineTooltipPropTypes = {
    active: boolean;
    baseDate: number;
    payload: {
        secondsSince12pm: number,
        reliability: number,
        reliableValue: { value: number | 'noData' };
    }
};

type TimelineGenericTooltipPropTypes = TimelineTooltipPropTypes & {
    subchartType: SignalTypeEnum;
};

function TimelineGenericTooltip(props: TimelineGenericTooltipPropTypes) {
    const {t} = useTranslation();
    if (props.active && props.payload) {
        const payload = props.payload;
        const color = (payload.reliability && payload.reliableValue.value && payload.reliableValue.value !== 'noData')
            ? colorPalette.signalTypeLight[getSignalTypeIdentifier(props.subchartType)]
            : colorPalette.clearColor;
        let tooltipValue: number | string | null = payload.reliableValue?.value;

        return (
            <div className="chart-tooltip">
                <strong style={{color: color}}>
                    {getSignalTypeLabel(props.subchartType)}:&nbsp;
                    {(() => {
                        if (tooltipValue === 'noData' || (payload.reliableValue && payload.reliableValue.value === 'noData')) {
                            return (
                                <strong style={{color: color}}>{t('global.noDataAvailable')}</strong>
                            );
                        }
                        else if (!payload.reliability) {
                            return (
                                <strong style={{color: color}}>{t('global.notReliable')}</strong>
                            );
                        }
                        else {
                            tooltipValue = tooltipValue as number;
                            if (props.subchartType === SignalTypeEnum.MOVEMENTS) {
                                tooltipValue = tooltipValue.toFixed(2);
                            }
                            else if (props.subchartType === SignalTypeEnum.SPO2) {
                                tooltipValue = Math.round(tooltipValue*100) / 100;
                            }
                            else {
                                tooltipValue = Math.round(tooltipValue);
                            }

                            return (
                                <strong style={{color: color}}>
                                    {tooltipValue}{getSignalTypeUnit(props.subchartType)}
                                </strong>
                            );
                        }
                    })()}
                </strong>
                <br/>

                {/*{formatDateUniversal(new Date(props.baseDate + payload.secondsSince12pm*1000))}*/}
                {/*{' '}*/}
                {/*{formatTimeUniversal(new Date(12*3600*1000 + payload.secondsSince12pm*1000))}*/}
                {formatDateTimeUniversal(new Date(props.baseDate + payload.secondsSince12pm*1000))}
            </div>
        );
    }
    else {
        return null;
    }
}

export function TimelineTooltipHR(props: TimelineTooltipPropTypes) {
    return (
        <TimelineGenericTooltip {...props} subchartType={SignalTypeEnum.PULSE_RATE}/>
    );
}

export function TimelineTooltipSPO2(props: TimelineTooltipPropTypes) {
    return (
        <TimelineGenericTooltip {...props} subchartType={SignalTypeEnum.SPO2}/>
    );
}

export function TimelineTooltipActigraphy(props: TimelineTooltipPropTypes) {
    return (
        <TimelineGenericTooltip {...props} subchartType={SignalTypeEnum.MOVEMENTS}/>
    );
}
