import React from 'react';
import Styled from 'styled-components';
import {colorPalette} from '@/themes/darkmode.js';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';

class NoInternetView extends React.PureComponent {
    static propTypes = {
        t: PropTypes.func,
        className: PropTypes.string,
    };
    
    render() {
        const props = this.props;
        const { t } = this.props;

        return (
            <div className={props.className}>
                <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 246.6 226.6" style={{enableBackground: 'new 0 0 246.6 226.6'}} xmlSpace="preserve">
                    <g id="Groupe_2355" transform="translate(-154 -243.306)">
                        <g id="Groupe_199" transform="translate(256.172 243.306)">
                            <g id="Groupe_129" transform="translate(0 0)">
                                <g id="Groupe_119" transform="translate(119.549 66.293)">
                                    <path id="Tracé_1187" style={{fillRule:'evenodd', clipRule:'evenodd', fill: '#F0D0B0'}} d="M18.2,2.4c-4.5-3.7-11-3-14.7,1.5s-3,11,1.5,14.7c4.5,3.7,11,3,14.7-1.5
                                        C23.3,12.6,22.6,6,18.2,2.4z"/>
                                    <path id="Tracé_1188" style={{fillRule:'evenodd', clipRule:'evenodd', fill: '#F0D0B0'}} d="M12.5,4.9C9.3,2.2,4.4,2.7,1.7,6C-1,9.2-0.5,14.1,2.8,16.8c3.3,2.7,8.1,2.2,10.8-1.1
                                        C16.3,12.4,15.8,7.6,12.5,4.9z"/>
                                </g>
                                <g id="Groupe_120" transform="translate(2.968 66.293)">
                                    <path id="Tracé_1189" style={{fillRule:'evenodd', clipRule:'evenodd', fill: '#F0D0B0'}} d="M3.8,2.4c4.5-3.7,11-3,14.7,1.5s3,11-1.5,14.7c-4.5,3.7-11,3-14.7-1.5
                                        C-1.3,12.6-0.6,6,3.8,2.4z"/>
                                    <path id="Tracé_1190" style={{fillRule:'evenodd', clipRule:'evenodd', fill: '#F0D0B0'}} d="M9.5,4.9c3.3-2.7,8.1-2.2,10.8,1.1c2.7,3.3,2.2,8.1-1.1,10.8c-3.3,2.7-8.1,2.2-10.8-1.1
                                        c0,0,0,0,0,0C5.7,12.4,6.2,7.6,9.5,4.9L9.5,4.9z"/>
                                </g>
                                <path id="Tracé_1191" style={{fillRule:'evenodd', clipRule:'evenodd', fill: '#F0D0B0'}} d="M72.2,105.5l-33.3,3.8c0,0-16.7,14.9-15.1,43.2c0,0-3,2.7-0.8,9.8
                                    c2.1,7.2,24.8,35.8,49.8,35.6c25.2,0.3,46.4-28.4,48.6-35.6s-0.8-9.8-0.8-9.8c1.6-28.3-15.2-43.2-15.2-43.2L72.2,105.5z"/>
                                <path id="Tracé_1192" style={{fillRule:'evenodd', clipRule:'evenodd', fill: '#F0D0B0'}} d="M72.2,156.8c0,0-16.9-0.2-23.2-25.5c0,0,2.8,20.5,3.4,30.5c0.5,10.1-9.6,13.8-14.2,6.5
                                    s-10.3-35.7-10.3-35.7s2.4,21.2,0.5,30.6s10.3,14.3,10.3,14.3h67.1c0,0,12.1-4.9,10.3-14.3c-1.9-9.4,0.5-30.6,0.5-30.6
                                    s-5.7,28.3-10.3,35.7s-14.7,3.6-14.2-6.5c0.6-10,3.4-30.5,3.4-30.5C89.2,156.6,72.2,156.8,72.2,156.8L72.2,156.8z"/>
                                <path id="Tracé_1193" style={{fillRule:'evenodd', clipRule:'evenodd', fill: '#F0D0B0'}} d="M117.6,161.7l3.9,0.7c0,0,16.7,8.9,22,26.2c5.2,17.3-10.9,24.1-19.7,25.2
                                    c-8.8,1.1-20.8,4-20.8,4s2.5,6.1-3.4,8.4c-5.9,2.3-28.5-5.3-20.3-24.6c1.4-1.7,3.4-2.8,5.5-3c0,0,10.6-8.9,22-13.8L117.6,161.7z"
                                />
                                <path id="Tracé_1194" style={{fillRule:'evenodd', clipRule:'evenodd', fill: '#F0D0B0'}} d="M120.2,161l1.3,1.4l0.4,0.2c0,0-4.4,11.8,6.8,16c-7.3,0.9-14.4,2.7-21.2,5.4l-0.3-0.1
                                    l6.1-16.4C115.7,165.2,118.2,162.9,120.2,161L120.2,161z"/>
                                <path id="Tracé_1195" style={{fillRule:'evenodd', clipRule:'evenodd', fill: '#F0D0B0'}} d="M143.9,190.5c3.6,15.9-11.7,22.2-20.1,23.2c-8.8,1.1-20.8,4-20.8,4
                                    c3-5.4-5.4-15.9-5.4-15.9c4.5,2.7,8.7,6.4,26.8,4.3C142.3,204.1,143.9,190.9,143.9,190.5z"/>
                                <path id="Tracé_1196" style={{fillRule:'evenodd', clipRule:'evenodd', fill: '#F0D0B0'}} d="M28.4,163.1l-5.4-0.7c0,0-16.7,8.9-22,26.2s10.9,24.1,19.7,25.2c8.8,1.1,20.8,4,20.8,4
                                    s-2.5,6.1,3.4,8.4c5.9,2.3,28.5-5.3,20.3-24.6c-1.4-1.7-3.4-2.8-5.5-3c0,0-8.8-10.5-20.2-15.5L28.4,163.1z"/>
                                <path id="Tracé_1197" style={{fillRule:'evenodd', clipRule:'evenodd', fill: '#F0D0B0'}} d="M24.3,161l-1.3,1.4l-0.4,0.2c0,0,4.3,11.8-6.8,16c0,0,0.4,0.1,1,0.1
                                    c3,0.4,13.6,0.7,22.1,4.1l0.3-0.1l-7.9-15.2C28.8,165.2,26.4,162.9,24.3,161L24.3,161z"/>
                                <path id="Tracé_1198" style={{fillRule:'evenodd', clipRule:'evenodd', fill: '#F0D0B0'}} d="M0.5,190.5c-3.6,15.9,11.7,22.2,20.2,23.2c8.8,1.1,20.8,4,20.8,4c-3-5.4,5.4-15.9,5.4-15.9
                                    c-4.6,2.7-8.7,6.4-26.9,4.3C2.2,204.1,0.6,190.9,0.5,190.5L0.5,190.5z"/>
                                <path id="Tracé_1199" style={{fillRule:'evenodd', clipRule:'evenodd', fill: '#DEF3E1'}} d="M23.8,152.6c0,0-3,2.7-0.8,9.8c2.1,7.2,24.8,35.8,49.8,35.6c25.2,0.3,43.1-17.8,48.6-35.6
                                    c2.2-7.2-0.8-9.8-0.8-9.8s-8.9,26.1-48.5,23.9C32.7,178.7,23.8,152.6,23.8,152.6L23.8,152.6z"/>
                                <path id="Tracé_1200" style={{fillRule:'evenodd', clipRule:'evenodd', fill: '#F0D0B0'}} d="M62.5,201.9L59,178.5l-10.1-47.3l-14.4-4.8l-6.6,6.1l4,22.7l9.6,31.2l0,0"/>
                                <path id="Tracé_1201" style={{fillRule:'evenodd', clipRule:'evenodd', fill: '#F0D0B0'}} d="M81.9,201.9l3.6-23.4l10-47.3l14.4-4.8l6.7,6.1l-4,22.7l-7.8,33.8"/>
                                <path id="Tracé_1202" style={{fillRule:'evenodd', clipRule:'evenodd', fill: '#F0D0B0'}} d="M126.5,59.5c6.4,46.8-13.9,67.7-54.3,69.4c-40.4-1.7-60.6-22.6-54.2-69.4
                                    C25.6,3.6,118.9,3.6,126.5,59.5L126.5,59.5z"/>
                                <path id="Tracé_1203" style={{fillRule:'evenodd', clipRule:'evenodd', fill: '#F0D0B0'}} d="M117.6,95.8c-7.5,19.1-25.2,28.3-51.4,29.4c-12.3-0.5-22.7-2.8-31.1-7.1
                                    c9.2,6.7,21.7,10.1,37.1,10.7c26.2-1.1,43.9-10.3,51.3-29.2c2.8-7,4.1-15.3,4.1-25c0-5.1-0.4-10.1-1.1-15.2
                                    c-2.3-16.6-12.1-28.3-25-35c10.5,7.3,17.5,18.7,19,31.4c0.9,6.7,1.3,13.4,1,20.1C121.3,82.8,120,89.5,117.6,95.8L117.6,95.8z"/>
                                <g id="Groupe_128" transform="translate(31.521 0)">
                                    <g id="Groupe_121" transform="translate(5.507 56.543)">
                                        <path id="Tracé_1204" style={{fill: '#FFFFFF'}} d="M19.8,9.6c0.2,5.5-4.1,10-9.6,10.2S0.2,15.7,0,10.2C-0.2,4.7,4.1,0.2,9.6,0
                                            C15.1-0.2,19.6,4.1,19.8,9.6z"/>
                                        <path id="Tracé_1205" style={{fill: '#382417'}} d="M18.2,10.3c0.1,4.1-3.1,7.5-7.1,7.6C7,18,3.6,14.8,3.5,10.7c-0.1-4.1,3.1-7.5,7.1-7.6
                                            c0,0,0,0,0,0C14.7,3,18.1,6.2,18.2,10.3C18.2,10.3,18.2,10.3,18.2,10.3z"/>
                                        <path id="Tracé_1206" style={{fill: '#FFFFFF'}} d="M14.5,8.6c0,1.1-0.8,2.1-2,2.1c-1.1,0-2.1-0.8-2.1-2c0-1.1,0.8-2.1,2-2.1c0,0,0,0,0,0
                                            C13.5,6.6,14.5,7.5,14.5,8.6z"/>
                                    </g>
                                    <g id="Groupe_122" transform="translate(54.27 55.036)">
                                        <path id="Tracé_1207" style={{fill: '#FFFFFF'}} d="M19.8,9.6c0.2,5.5-4.1,10-9.6,10.2C4.7,20,0.2,15.7,0,10.2C-0.2,4.7,4.1,0.2,9.6,0
                                            C15.1-0.2,19.6,4.1,19.8,9.6C19.8,9.6,19.8,9.6,19.8,9.6z"/>
                                        <path id="Tracé_1208" style={{fill: '#382417'}} d="M18.2,10.3c0.1,4.1-3.1,7.5-7.1,7.6C7,18,3.6,14.8,3.5,10.7s3.1-7.5,7.1-7.6c0,0,0,0,0,0
                                            C14.7,3,18.1,6.2,18.2,10.3z"/>
                                        <path id="Tracé_1209" style={{fill: '#FFFFFF'}} d="M14.5,8.6c0,1.1-0.8,2.1-2,2.1c-1.1,0-2.1-0.8-2.1-2c0-1.1,0.8-2.1,2-2.1h0
                                            C13.5,6.6,14.5,7.5,14.5,8.6z"/>
                                    </g>
                                    <path id="Tracé_1210" style={{fill: '#E2B586'}} d="M46.2,75.8c0,0,0.5-5.5-4.7-5.4c-5.4,0.1-4.3,6.3-4.3,6.3L46.2,75.8z"/>
                                    <path id="Tracé_1214" style={{fill: '#382417'}} d="M13.8,44.9c-3.1,0.2-5.6,1.5-8.2,3.1c-2.4,1.5-5,3.6-5.6,6.5c-0.1,0.2-0.1,1.1,0.2,1.1
                                        c0.8,0.1,1.5-0.7,2-1.2c1.2-1.1,2.5-2.1,3.9-2.9c2.7-1.6,5.3-3.4,7.8-5.3C14.2,46,14.2,44.9,13.8,44.9z"/>
                                    <path id="Tracé_1215" style={{fill: '#382417'}} d="M77.8,48c-3.8-4.2-8.9-7-14.5-7.9c-0.2,0-0.1,0.9,0,1c2.4,1.6,4.9,3,7.4,4.5
                                        c2.3,1.3,4.5,2.8,7.1,3.5C78.1,49.1,77.9,48.1,77.8,48z"/>
                                    <path id="Tracé_1216" style={{fill: '#E5B242'}} d="M48.8,4.8C45.9-5.7,35.1,3.3,33.5,10c-1.2,5.2-0.8,10.6,1,15.6c-1.6,1.4-3.5,2.6-5.4,3.6
                                        c-0.4,0.2,0.2,1.9,0.6,1.9c2.3,0.2,4.6-0.5,6.4-1.9c0.6,1.2,1.4,2.4,2.2,3.5c4.7,6.3,14.6,10.1,19.9,2c0.2-0.4-0.3-2-0.8-1.9
                                        c-5.7,1.4-9.8,4.3-15.4,0.7c-2-1.3-3.6-3.1-4.7-5.2c2.3-2.3,4.4-4.8,6.1-7.6C46.6,16,50.5,10.7,48.8,4.8z M35.6,24.6
                                        c-0.9-2.8-1.2-5.8-1-8.8c0.2-2.7,1-5.3,2.4-7.6c1-1.7,2.4-3.1,4-4.2c3-2.2,8.7-2.3,5.7,5.1c-1.5,3.8-4.7,7.3-7,10.6
                                        C38.6,21.5,37.2,23.1,35.6,24.6z"/>
                                    <path id="Tracé_1390" style={{fill: 'none', stroke: '#382417', strokeWidth: '3', strokeLinecap: 'round'}} d="M28.3,103.4c2.5-1.3,5.3-1.9,8.1-2c3.8-0.1,7.4,1.4,10,4"/>
                                </g>
                            </g>
                        </g>
                        <g id="Groupe_217" transform="translate(154 250.078)">
                            <g id="Groupe_47">
                                <circle id="Ellipse_16" style={{fill: '#FC6B6B'}} cx="43" cy="42.9" r="43"/>
                            </g>
                            <g id="Groupe_214" transform="translate(21.975 21.132)">
                                <g>
                                    <defs>
                                        <rect id="SVGID_1_" x="0" y="0" width="42.9" height="43.3"/>
                                    </defs>
                                    <clipPath id="SVGID_00000005945483870459345680000013583799955755760772_">
                                        <use xlinkHref="#SVGID_1_" style={{overflow: 'visible'}} />
                                    </clipPath>

                                    <g id="Groupe_215" transform="translate(0)" style={{clipPath: 'url(#SVGID_00000005945483870459345680000013583799955755760772_)'}}>
                                        <path id="Tracé_1397" style={{fill: '#FFFFFF'}} d="M9.9,10c-3.7,1.6-7.1,4-9.9,6.9l3.6,3.8c2.7-2.8,6-5,9.7-6.3L9.9,10z"/>
                                        <path id="Tracé_1398" style={{fill: '#FFFFFF'}} d="M28.8,24.7c1.5,0.8,2.8,1.8,3.9,3l3.7-3.9c-3.5-3.7-8.3-6-13.4-6.5L28.8,24.7z"/>
                                        <path id="Tracé_1399" style={{fill: '#FFFFFF'}} d="M19.7,13.1c0.6,0,1.1-0.1,1.7-0.1c6.8,0,13.2,2.8,17.8,7.8l3.6-3.8
                                            c-5.5-6-13.3-9.3-21.5-9.4c-1.9,0-3.7,0.2-5.6,0.6L19.7,13.1z"/>
                                        <path id="Tracé_1400" style={{fill: '#FFFFFF'}} d="M23.3,27.2c-0.6-0.1-1.2-0.2-1.8-0.2c-3.2,0-6.2,1.3-8.4,3.7l3.7,3.9
                                            c2.4-2.6,6.4-2.7,9-0.3c0.1,0.1,0.2,0.2,0.3,0.3l1.6-1.6L23.3,27.2z"/>
                                        <path id="Tracé_1401" style={{fill: '#FFFFFF'}} d="M16.1,18c-3.7,1-7,3-9.6,5.8l3.7,3.9c2.5-2.7,6-4.4,9.7-4.8L16.1,18z"/>
                                        <path id="Tracé_1402" style={{fill: '#FFFFFF'}} d="M21.5,36.4c-1.9,0-3.4,1.5-3.4,3.4c0,1.9,1.5,3.4,3.4,3.4s3.4-1.5,3.4-3.4
                                            S23.4,36.4,21.5,36.4"/>

                                        <rect id="Rectangle_62" x="18.3" y="-3.9" transform="matrix(0.7901 -0.613 0.613 0.7901 -8.4461 16.859)" style={{fill: '#FFFFFF'}} width="4.1" height="49.3"/>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>

                <h2>{t(['No internet connection', 'global.noInternet'])}</h2>
            </div>
        );
    }
}
//language=SCSS
NoInternetView = Styled(NoInternetView)`
& {
    height:100vh;
    padding: 50px;
    text-align:center;
    width:500px;
    margin:0 auto;
    
    h2 {
        font-size: 18px;
        text-transform: uppercase;
        color:${colorPalette.danger};
        font-weight: bold;
        margin-top:40px;
    }
    
    svg {
        width:80%;
        max-width:500px;
        margin-top:25px;
    }
}
`;

export default withTranslation()(NoInternetView);
