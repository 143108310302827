import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';
import {withTranslation} from 'react-i18next';
import {connect as redux_connect} from 'react-redux';
import Styled from 'styled-components';

import HcpUpdateConfiguration from '@/components/business/analytics/hcp/hcp-update-configuration';
import HcpUpdateEmail from '@/components/business/analytics/hcp/hcp-update-email';
import HcpUpdateGroups from '@/components/business/analytics/hcp/hcp-update-groups';
import HcpUpdatePassword from '@/components/business/analytics/hcp/hcp-update-password';
import {HcpUpdatePersonalData} from '@/components/business/analytics/hcp/hcp-update-personal-data';
import ErrorBoundary from '@/components/errors/error-boundary';
import {Page} from '@/decorators/page';

function wrapWithPage(Component) {
    return Page({
        name: 'Profile'
    })(Component);
}

const reduxMapStateToProps = function(state) {
    return {
        me: state.me,
    };
};

class HcpUpdatePage extends React.Component {
    static propTypes = {
        me: PropTypes.object,
        t: PropTypes.func,
        className: PropTypes.string,
    };

    state = {
        steps: [
            this.props.t(['Profile', 'pages.hcpUpdate.steps.profile']),
            this.props.t(['Configuration', 'pages.hcpUpdate.steps.configuration']),
            this.props.t(['Groups', 'pages.hcpUpdate.steps.groups']),
            this.props.t(['Email address', 'pages.hcpUpdate.steps.email']),
            this.props.t(['Password', 'pages.hcpUpdate.steps.password'])
        ],
        activeStep: 0,
    };

    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        const props = this.props;
        const state = this.state;

        return (
            <div className={props.className}>
                <ErrorBoundary>
                    <div className="sections-wrapper">
                        <nav className="sections-menu">
                            {state.steps.map((step, i) => (
                                <a key={i} className={(state.activeStep === i) ? `section-menu-step-${i} activeStep` : `section-menu-step-${i}`} onClick={() => this.handleStepClicked(i)}>{step}</a>
                            ))}
                        </nav>

                        <div className="sections-content">
                            {this.state.activeStep === 0 &&
                                <HcpUpdatePersonalData hcp={props.me.data} />
                            }
                            {this.state.activeStep === 1 &&
                                <HcpUpdateConfiguration hcp={props.me.data} />
                            }
                            {this.state.activeStep === 2 &&
                                <HcpUpdateGroups hcp={props.me.data} />
                            }
                            {this.state.activeStep === 3 &&
                                <HcpUpdateEmail hcp={props.me.data} />
                            }
                            {this.state.activeStep === 4 &&
                                <HcpUpdatePassword hcp={props.me.data} />
                            }
                        </div>
                    </div>
                </ErrorBoundary>
            </div>
        );
    }

    handleStepClicked(stepIndex) {
        this.setState(state => ({
            ...state,
            activeStep: stepIndex,
        }));
    }

    shouldComponentUpdate() {
        return true;
    }
}

//language=SCSS
HcpUpdatePage = Styled(HcpUpdatePage)`
& {
    min-height: 500px;
    width: 90%;
    margin: 150px auto 0 auto;
}
`;

export default redux_connect(reduxMapStateToProps)(wrapWithPage(withTranslation()(HcpUpdatePage)));
