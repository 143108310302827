import PropTypes from 'prop-types';
import styled from 'styled-components';
import Slider, {createSliderWithTooltip} from 'rc-slider';
import {colorPalette} from '@/themes/darkmode';
import {SignalTypeEnum} from '@/enum/signal-type-enum';
const Range = createSliderWithTooltip(Slider.Range);
const SliderWithTooltip = createSliderWithTooltip(Slider);

const EventThresholdConfigurationSlider = (
    {
        lowValue,
        highValue,
        signalType,
        sliderMin,
        sliderMax,
        sliderStep,
        onChanged,
    }) => {

    const handleChange = (range) => {
        onChanged(range);
    };

    return (
        <StyledWrapper className={`threshold-slider threshold-slider_${signalType}`}>
            {(signalType === SignalTypeEnum.SPO2) ?
                <SliderWithTooltip
                    min={sliderMin}
                    max={sliderMax}
                    step={sliderStep}
                    tipProps={{visible: true, placement: 'bottom'}}
                    value={lowValue}
                    allowCross={false}
                    onChange={(value) => {
                        handleChange(value);
                    }}
                />
                :
                <Range
                    min={sliderMin}
                    max={sliderMax}
                    step={sliderStep}
                    tipProps={{visible: true, placement: 'bottom'}}
                    value={[lowValue, highValue]}
                    allowCross={false}
                    onChange={(range) => {
                        handleChange(range);
                    }}
                />
            }
        </StyledWrapper>
    );
};

EventThresholdConfigurationSlider.propTypes = {
    lowValue: PropTypes.number.isRequired,
    highValue: PropTypes.number.isRequired,
    signalType: PropTypes.number.isRequired,
    sliderMin: PropTypes.number.isRequired,
    sliderMax: PropTypes.number,
    sliderStep: PropTypes.number.isRequired,
    onChanged: PropTypes.func.isRequired,
};

//language=SCSS
const StyledWrapper = styled.div`
  && {      
    padding: 2px 10px 2px 20px;
    width: 300px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    margin-right: 25px;

    .rc-slider.rc-slider-disabled {
        display: none;
    }
    .rc-slider:not(.rc-slider-disabled) {
        .rc-slider-rail {
            background: ${colorPalette.activeColor};
            &:before {
                content: "";
                width: 10px;
                height: 10px;
                border-radius: 20px;
                background: ${colorPalette.activeColor};
                position: absolute;
                left: 0;
                top: -3px;
            }
            &:after {
                content: "";
                width: 10px;
                height: 10px;
                border-radius: 20px;
                background: ${colorPalette.activeColor};
                position: absolute;
                right: 0;
                top: -3px;
            }
        }

        .rc-slider-track {
            background: ${colorPalette.clearColor};
        }

        .rc-slider-handle {
            width:20px;
            height:20px;
            margin-top:-8px;
            background:${colorPalette.activeColor};
            border-color:${colorPalette.frontColor};
        }

        .rc-slider-handle-dragging {
            box-shadow: 0 0 0 3px ${colorPalette.frontColor};
        }

        .rc-slider-tooltip-inner {
            padding: 2px;
            min-width: 24px;
            height: auto;
            font-size: 11px;
            line-height: 1;
            color: #fff;
            text-align: center;
            text-decoration: none;
            background-color: inherit;
            border-radius: 0;
            box-shadow: none;
        }
    }

    &.threshold-slider_${SignalTypeEnum.PULSE_RATE} .rc-slider:not(.rc-slider-disabled) {
        .rc-slider-rail, .rc-slider-rail:before, .rc-slider-rail:after {
            background:${colorPalette.signalType.pulseRate};
        }
        .rc-slider-handle {
            background-color:${colorPalette.signalType.pulseRate};
        }
    }

    &.threshold-slider_${SignalTypeEnum.SPO2} .rc-slider:not(.rc-slider-disabled) {
        .rc-slider-track, .rc-slider-handle, .rc-slider-rail:before {
            background:${colorPalette.signalType.spo2};
        }
        .rc-slider-rail, .rc-slider-rail:after {
            background: ${colorPalette.clearColor};
        }
    }
  }
`;

export default EventThresholdConfigurationSlider;
