import React from 'react';
import Styled from 'styled-components';
import PropTypes from 'prop-types';
import {colorPalette} from '@/themes/darkmode.js';
import autoBind from 'react-autobind';
import arrowRightIcon from '@/public/icons/arrow-right-icon.svg';

class GroupsList extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        groups: PropTypes.array.isRequired,
        selectedGroupId: PropTypes.string,
        onSelect: PropTypes.func
    };

    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        const props = this.props;

        return (
            <ul className={props.className}>
                {props.groups.map(group => (
                    <li
                        key={group.groupId.id}
                        className={(props.selectedGroupId && props.selectedGroupId === group.groupId.id) ? 'selected' : ''}
                        onClick={() => this.selectGroup(group.groupId.id)}
                    >
                        {group.name}
                    </li>
                ))}
            </ul>
        );
    }

    selectGroup(id) {
        if(this.props.onSelect) {
            this.props.onSelect(id);
        }
    }
}

//language=SCSS
GroupsList = Styled(GroupsList)`
& {
    margin:40px 0 0 0;
    padding:0;
    li {
        padding:20px 30px;
        list-style-type: none;
        &:hover {
            background-color:${colorPalette.secondBackground};
        }
        &.selected {
            background:${colorPalette.thirdBackground} url(${arrowRightIcon}) no-repeat right center;
        }
    }
}
`;

export { GroupsList };
