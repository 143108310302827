import {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import {ThemeProvider} from 'styled-components';

import LoadingView from '@/components/static/loading-view';
import routes from '@/routes-analytics';
import {Auth0Service} from '@/services/auth0-service';
import {ErrorTrackingService} from '@/services/error-tracking-service';
import '@/services/i18n-service';
import store from '@/store';
import * as theme from '@/themes/darkmode';

// Get application routes
const router = createBrowserRouter(routes);

// Initialize Sentry
ErrorTrackingService.init();

// ENTRY POINT OF GABI ANALYTICS APPLICATION
ReactDOM.createRoot(document.getElementById('app')!).render(
    <Provider store={store}>
        <Suspense fallback={<LoadingView />}>
            <ThemeProvider theme={Object.assign({}, theme)}>
                <RouterProvider router={router} />
            </ThemeProvider>
        </Suspense>
    </Provider>
);

// Check session - redirect to login page after token expiration
function sessionCheck() {
    Auth0Service.getAccessToken()
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.error(error);
            // Reload application to display login page when token expired
            location.reload();
        });
}

setInterval( function() {
    sessionCheck();
}, 900000);
