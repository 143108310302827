import {subDays} from 'date-fns';
import {HasData} from 'gabi-api-js/patient/patient_query_pb';
import {floorHour} from 'gabi-web-common/util/date-util';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import styled from 'styled-components';

import {HcpTutorialStatus} from '@/components/business/analytics/hcp/hcp-tutorial-status';
import {HealthReportPercentagePerDay} from '@/components/business/analytics/health-report/health-report-percentage-per-day';
import HealthReportStatisticsPerDay from '@/components/business/analytics/health-report/health-report-statistics-per-day';
import {HealthReportStatisticsTrend} from '@/components/business/analytics/health-report/health-report-statistics-trend';
import PatientAvatar from '@/components/business/analytics/patient/common/patient-avatar';
import PatientLastRecording from '@/components/business/analytics/patient/common/patient-last-recording';
import {PatientsActiveTableCellHoverable} from '@/components/business/analytics/patients/patients-active-table-cell-hoverable';
import {Button} from '@/components/buttons/button';
import InfoTooltip from '@/components/buttons/info-tooltip';
import ColoredContainer from '@/components/layout/colored-container';
import {PatientChildSexLabel} from '@/enum/patient-child-sex-enum';
import {RecordingInformationTrend} from '@/enum/recording-information-trend';
import {SignalTypeEnum} from '@/enum/signal-type-enum';
import {formatApiDateToJSDate} from '@/services/api-requests/requests-utils';
import {BackendApiService} from '@/services/backend-api-service';
import {useDebugFlags} from '@/services/debug-service';
import {tutorialStatus as tutorialStatusReduxModel} from '@/store/models/index';
import {colorPalette} from '@/themes/darkmode';
import {EventThresholdType} from '@/types/event-threshold-type';
import {formatDateUniversal} from '@/util/date-utils';
import {NewDateUtil} from '@/util/new-date-util';
import {roundTo} from '@/util/number-util';
import {formatDateForUrl} from '@/util/url-util';

import '@/stylesheets/bootstrap.min.css';

let PatientsActiveTableRow = ({data, columns}) => {
    const { t } = useTranslation();
    let navigate = useNavigate();

    const debugFlags = useDebugFlags();

    // Tutorial Status from Redux
    const tutorialStatus = useSelector(state => state.tutorialStatus);
    const dispatch = useDispatch();

    const patient = data.patient;
    const lastRecordingInformation = data.lastRecordingInformation;

    const lastRecordingDate = new Date(
        patient.lastRecordingDate.year,
        patient.lastRecordingDate.month-1,
        patient.lastRecordingDate.day
    );

    const handleClickHideDemoPatient = (e) => {
        e.preventDefault();

        BackendApiService.getRequest({
            domain: 'hcp',
            modelName: 'updateTutorialStatus',
            status: {
                demoPatient: HcpTutorialStatus.DISABLED,
            },
        }).then((response) => {
            const updatedTutorialStatus = {...tutorialStatus};
            updatedTutorialStatus.demoPatient = HcpTutorialStatus.DISABLED;

            dispatch(tutorialStatusReduxModel.actions.set(updatedTutorialStatus));

            return response;
        }).catch(err => {
            console.error(err);
        });
    };

    const handleWidgetChartClick = (selectedDate) => {
        let url = `/patients/${patient.patientId.id}/timeline/${formatDateForUrl(selectedDate)}`;
        navigate(url);
    };
    
    return (
        <StyledWrapper>
            <td className="linkCell">
                <Link to={`/patients/${patient.patientId.id}/healthReport`}>{t(['Patient details', 'pages.patients.patientDetails'])}</Link>
            </td>
            <td className="patientNameCell">
                <div>
                    <PatientAvatar skinType={patient.skinType} />
                    <p>
                        {patient.firstName || patient.externalSource?.idInSource || patient.patientId.id} {patient.lastName}
                        <span>
                            {patient.birthDate ?
                                <>{formatDateUniversal(formatApiDateToJSDate(patient.birthDate))}&nbsp;-&nbsp;</>
                                :
                                <><em>{t(['No birth date information', 'patient.birthDateEmpty'])}&nbsp;-&nbsp;</em></>
                            }
                            {t('patient.sexLabel.' + PatientChildSexLabel[patient.sex])}
                        </span>
                        <span>{patient.trialId?.id}</span>
                    </p>
                    {patient.patientId.id === 'demo' &&
                        <Button
                            className="button-hide-filled-hover"
                            displayIcon={true}
                            onClick={handleClickHideDemoPatient}
                        >
                            {t(['Hide', 'pages.patients.hide'])}
                        </Button>
                    }
                </div>
            </td>
            <td>
                <PatientLastRecording lastRecordingDataTime={patient.lastRecordingDataTime} />
            </td>
            {columns.find(col => col.accessor === 'lastRecordingInformation.pulseRate.statistics.average') && (
                <PatientsActiveTableCellHoverable
                    className="separatorCellFirst"
                    hasHover={
                        debugFlags.alwaysShowTrendsOverlays || (
                            lastRecordingInformation.pulseRate?.statistics?.hasData >= HasData.HAS_MIN_RELIABLE_DATA &&
                            lastRecordingInformation.pulseRate?.statistics?.trend !== RecordingInformationTrend.NORMAL &&
                            !isNaN(lastRecordingInformation.pulseRate?.statistics?.average)
                        )
                    }
                    hoverElement={() => (
                        <div className={'warning-overlay animate__animated animate__fadeIn animate__faster'}>
                            <ColoredContainer color={colorPalette.signalType.pulseRate}>
                                {lastRecordingInformation.pulseRate?.statistics?.trend === RecordingInformationTrend.NO_DATA &&
                                    <p className="trendsNoData">{t(['There are no available data in the previous 14 nights.', 'pages.patients.trends.noData'])}</p>
                                }
                                {lastRecordingInformation.pulseRate?.statistics?.trend !== RecordingInformationTrend.NO_DATA &&
                                    <HealthReportStatisticsPerDay
                                        patientId={patient.patientId.id}
                                        signalType={SignalTypeEnum.PULSE_RATE}
                                        lastRecordingDate={lastRecordingDate}
                                        dateFrom={floorHour(subDays(lastRecordingDate, 14))}
                                        dateTo={floorHour(NewDateUtil())}
                                        ignoreUnifiedDuration
                                        signalEventConfiguration={patient.eventConfiguration.configuration.hr}
                                        displayPreviousNights={true}
                                        onClick={handleWidgetChartClick}
                                    />
                                }
                            </ColoredContainer>
                        </div>
                    )}
                >
                    {(lastRecordingInformation.pulseRate?.statistics?.hasData !== HasData.HAS_MIN_RELIABLE_DATA) || isNaN(lastRecordingInformation.pulseRate?.statistics?.average) ? (
                        <TableCellNoData/>
                    ) : (<>
                        <strong>{Math.round(lastRecordingInformation.pulseRate?.statistics?.average)}</strong> bpm
                        {lastRecordingInformation.pulseRate?.statistics?.trend !== undefined && (
                            <HealthReportStatisticsTrend
                                hasData={lastRecordingInformation.pulseRate?.statistics?.hasData ?? HasData.HAS_NO_DATA}
                                trend={lastRecordingInformation.pulseRate?.statistics?.trend} />
                        )}
                    </>)}
                </PatientsActiveTableCellHoverable>
            )}
            
            {columns.find(col => col.accessor === 'lastRecordingInformation.spo2.statistics.average') && (
                <PatientsActiveTableCellHoverable
                    hasHover={
                        debugFlags.alwaysShowTrendsOverlays || (
                            lastRecordingInformation.spo2?.statistics?.hasData >= HasData.HAS_MIN_RELIABLE_DATA &&
                            lastRecordingInformation.spo2?.statistics?.trend !== RecordingInformationTrend.NORMAL &&
                            !isNaN(lastRecordingInformation.spo2?.statistics?.average)
                        )
                    }
                    hoverElement={() => (
                        <div className={'warning-overlay animate__animated animate__fadeIn animate__faster'}>
                            <ColoredContainer color={colorPalette.signalType.spo2}>
                                {lastRecordingInformation.spo2?.statistics?.trend === RecordingInformationTrend.NO_DATA &&
                                    <p className="trendsNoData">{t(['There are no available data in the previous 14 nights.', 'pages.patients.trends.noData'])}</p>
                                }
                                {lastRecordingInformation.spo2?.statistics?.trend !== RecordingInformationTrend.NO_DATA &&
                                    <HealthReportStatisticsPerDay
                                        patientId={patient.patientId.id}
                                        signalType={SignalTypeEnum.SPO2}
                                        lastRecordingDate={lastRecordingDate}
                                        dateFrom={floorHour(subDays(lastRecordingDate, 14))}
                                        dateTo={floorHour(NewDateUtil())}
                                        ignoreUnifiedDuration
                                        signalEventConfiguration={patient.eventConfiguration.configuration.spo2}
                                        displayPreviousNights={true}
                                        onClick={handleWidgetChartClick}
                                    />
                                }
                            </ColoredContainer>
                        </div>
                    )}
                >
                    {(lastRecordingInformation.spo2?.statistics?.hasData !== HasData.HAS_MIN_RELIABLE_DATA) || isNaN(lastRecordingInformation.spo2?.statistics?.average) ? (
                        <TableCellNoData/>
                    ) : (<>
                        <strong>{Math.round(lastRecordingInformation.spo2?.statistics?.average)}</strong> %
                        {lastRecordingInformation.spo2?.statistics?.trend !== undefined && (
                            <HealthReportStatisticsTrend
                                hasData={lastRecordingInformation.spo2?.statistics?.hasData ?? HasData.HAS_NO_DATA}
                                trend={lastRecordingInformation.spo2?.statistics?.trend}
                            />
                        )}
                    </>)}
                </PatientsActiveTableCellHoverable>
            )}
            
            {columns.find(col => col.accessor === 'lastRecordingInformation.pulseRate.eventsCountLow.eventCount') && (
                <PatientsActiveTableCellHoverable
                    hasHover={
                        debugFlags.alwaysShowTrendsOverlays || (
                            lastRecordingInformation.pulseRate?.eventsCountLow?.hasData >= HasData.HAS_MIN_RELIABLE_DATA &&
                            lastRecordingInformation.pulseRate?.eventsCountLow?.trend !== RecordingInformationTrend.NORMAL
                        )
                    }
                    hoverElement={() => (
                        <div className={'warning-overlay animate__animated animate__fadeIn animate__faster'}>
                            <ColoredContainer className="widget-events" color={colorPalette.signalTypeLight.pulseRate}>
                                {lastRecordingInformation.pulseRate?.eventsCountLow?.trend === RecordingInformationTrend.NO_DATA && (
                                    <p className="trendsNoData">{t(['There are no available data in the previous 14 nights.', 'pages.patients.trends.noData'])}</p>
                                )}
                                {lastRecordingInformation.pulseRate?.eventsCountLow?.trend !== RecordingInformationTrend.NO_DATA && (
                                    <HealthReportPercentagePerDay
                                        patientId={patient.patientId.id}
                                        signalType={SignalTypeEnum.PULSE_RATE}
                                        thresholdType={EventThresholdType.LOW}
                                        lightColor
                                        lastRecordingDate={lastRecordingDate}
                                        dateFrom={floorHour(subDays(lastRecordingDate, 14))}
                                        dateTo={floorHour(NewDateUtil())}
                                        ignoreUnifiedDuration
                                        signalEventConfiguration={patient.eventConfiguration.configuration.hr}
                                        onClick={handleWidgetChartClick}
                                        displayPreviousNights={true}
                                    />
                                )}
                            </ColoredContainer>
                        </div>
                    )}
                >
                    {(lastRecordingInformation.pulseRate?.eventsCountLow?.hasData !== HasData.HAS_MIN_RELIABLE_DATA) || isNaN(lastRecordingInformation.pulseRate?.eventsCountLow?.eventCount) ? (
                        <TableCellNoData/>
                    ) : (<>
                        <strong>{roundTo(lastRecordingInformation.pulseRate?.eventsCountLow?.eventCount, 2)}</strong> {t(['events/h', 'global.eventsPerHour'])}
                        <span>{t(['Below', 'global.below'])} {patient.eventConfiguration.configuration.hr.low.value}bpm</span>
                        {lastRecordingInformation.pulseRate?.eventsCountLow?.trend !== undefined && (
                            <HealthReportStatisticsTrend
                                hasData={lastRecordingInformation.pulseRate?.eventsCountLow?.hasData ?? HasData.HAS_NO_DATA}
                                trend={lastRecordingInformation.pulseRate?.eventsCountLow?.trend}
                            />
                        )}
                    </>)}
                </PatientsActiveTableCellHoverable>
            )}
            
            {columns.find(col => col.accessor === 'lastRecordingInformation.pulseRate.eventsCountHigh.eventCount') && (
                <PatientsActiveTableCellHoverable
                    hasHover={
                        debugFlags.alwaysShowTrendsOverlays || (
                            lastRecordingInformation.pulseRate?.eventsCountHigh?.hasData >= HasData.HAS_MIN_RELIABLE_DATA &&
                            lastRecordingInformation.pulseRate?.eventsCountHigh?.trend !== RecordingInformationTrend.NORMAL
                        )
                    }
                    hoverElement={() => (
                        <div className={'warning-overlay animate__animated animate__fadeIn animate__faster'}>
                            <ColoredContainer className="widget-events" color={colorPalette.signalType.pulseRate}>
                                {lastRecordingInformation.pulseRate?.eventsCountHigh?.trend === RecordingInformationTrend.NO_DATA &&
                                    <p className="trendsNoData">{t(['There are no available data in the previous 14 nights.', 'pages.patients.trends.noData'])}</p>
                                }
                                {lastRecordingInformation.pulseRate?.eventsCountHigh?.trend !== RecordingInformationTrend.NO_DATA &&
                                    <HealthReportPercentagePerDay
                                        patientId={patient.patientId.id}
                                        signalType={SignalTypeEnum.PULSE_RATE}
                                        thresholdType={EventThresholdType.HIGH}
                                        lastRecordingDate={lastRecordingDate}
                                        dateFrom={floorHour(subDays(lastRecordingDate, 14))}
                                        dateTo={floorHour(NewDateUtil())}
                                        ignoreUnifiedDuration
                                        signalEventConfiguration={patient.eventConfiguration.configuration.hr}
                                        onClick={handleWidgetChartClick}
                                        displayPreviousNights={true}
                                    />
                                }
                            </ColoredContainer>
                        </div>
                    )}
                >
                    {(lastRecordingInformation.pulseRate?.eventsCountHigh?.hasData !== HasData.HAS_MIN_RELIABLE_DATA) || isNaN(lastRecordingInformation.pulseRate?.eventsCountHigh?.eventCount) ? (
                        <TableCellNoData/>
                    ) : (<>
                        <strong>{roundTo(lastRecordingInformation.pulseRate?.eventsCountHigh?.eventCount, 2)}</strong> {t(['events/h', 'global.eventsPerHour'])}
                        <span>{t(['Above', 'global.above'])} {patient.eventConfiguration.configuration.hr.high.value}bpm</span>
                        {lastRecordingInformation.pulseRate?.eventsCountHigh?.trend !== undefined && (
                            <HealthReportStatisticsTrend
                                hasData={lastRecordingInformation.pulseRate?.eventsCountHigh?.hasData ?? HasData.HAS_NO_DATA}
                                trend={lastRecordingInformation.pulseRate?.eventsCountHigh?.trend}
                            />
                        )}
                    </>)}
                </PatientsActiveTableCellHoverable>
            )}
            
            {columns.find(col => col.accessor === 'lastRecordingInformation.spo2.eventPercent.eventPercent') && (
                <PatientsActiveTableCellHoverable
                    hasHover={
                        debugFlags.alwaysShowTrendsOverlays || (
                            lastRecordingInformation.spo2?.eventPercent?.hasData >= HasData.HAS_MIN_RELIABLE_DATA &&
                            lastRecordingInformation.spo2?.eventPercent?.trend !== RecordingInformationTrend.NORMAL
                        )
                    }
                    hoverElement={() => (
                        <div className={'warning-overlay animate__animated animate__fadeIn animate__faster'}>
                            <ColoredContainer className="widget-events" color={colorPalette.signalType.spo2}>
                                {lastRecordingInformation.spo2?.eventPercent?.trend === RecordingInformationTrend.NO_DATA &&
                                    <p className="trendsNoData">{t(['There are no available data in the previous 14 nights.', 'pages.patients.trends.noData'])}</p>
                                }
                                {lastRecordingInformation.spo2?.eventPercent?.trend !== RecordingInformationTrend.NO_DATA &&
                                    <HealthReportPercentagePerDay
                                        patientId={patient.patientId.id}
                                        signalType={SignalTypeEnum.SPO2}
                                        thresholdType={EventThresholdType.LOW}
                                        lastRecordingDate={lastRecordingDate}
                                        dateFrom={floorHour(subDays(lastRecordingDate, 14))}
                                        dateTo={floorHour(NewDateUtil())}
                                        ignoreUnifiedDuration
                                        signalEventConfiguration={patient.eventConfiguration.configuration.spo2}
                                        onClick={handleWidgetChartClick}
                                        displayPreviousNights={true}
                                    />
                                }
                            </ColoredContainer>
                        </div>
                    )}
                >
                    {(lastRecordingInformation.spo2?.eventPercent?.hasData !== HasData.HAS_MIN_RELIABLE_DATA) || isNaN(lastRecordingInformation.spo2?.eventPercent?.eventPercent) ? (
                        <TableCellNoData/>
                    ) : (<>
                        <strong>{roundTo(lastRecordingInformation.spo2?.eventPercent?.eventPercent, 2)}</strong> %
                        <span>{t(['Below', 'global.below'])} {patient.eventConfiguration.configuration.spo2.low.value}%</span>
                        {lastRecordingInformation.spo2?.eventPercent?.trend !== undefined && (
                            <HealthReportStatisticsTrend
                                hasData={lastRecordingInformation.spo2?.eventPercent?.hasData ?? HasData.HAS_NO_DATA}
                                trend={lastRecordingInformation.spo2?.eventPercent?.trend}
                            />
                        )}
                    </>)}
                </PatientsActiveTableCellHoverable>
            )}
        </StyledWrapper>
    );
};

PatientsActiveTableRow.propTypes = {
    className: PropTypes.string,
    data: PropTypes.object,
    columns: PropTypes.array,
};

function TableCellNoData() {
    const { t } = useTranslation();
    return (<>
        <em>{t(['No data', 'pages.patients.noValue'])}</em>
        <InfoTooltip className="noDataTooltip" title={t(['No data', 'pages.patients.noValue'])} overlayPosition={'bottomRight'}>
            <p>{t(['No reliable data available for this recording', 'pages.patients.noValueText'])}</p>
        </InfoTooltip>
    </>);
}

//language=SCSS
const StyledWrapper = styled.tr`
    & {
        position: relative;
        
        .noDataTooltip {
            top: 8px;
            right: 8px;
        }
        
        .warning-overlay {
            background: ${colorPalette.thirdBackground};
            margin-left: 20px;
            border-radius: 5px;
            z-index: 1050;
            position: absolute;
            width: 500px;
            right: 0;
        top:55px;
        box-shadow: 0 1px 3px ${colorPalette.darkColor};
        
        .trendsNoData {
            font-style: italic;
            margin-top:20px;
        }
        
        .average-block {
            text-align:right;
            position: relative;
            font-size: 13px;
            
            p {
                margin-bottom: 5px;
                span {
                    display:inline;
                }
            }
        }
    }

    .patientNameCell {
        p {
            flex-grow: 3;
        }
        .button-hide-filled-hover {
            flex-grow: 1;
            margin-left:15px;
            text-align:left;
            background-color ${colorPalette.clearColor};
        }
    }
}
`;

export default PatientsActiveTableRow;
