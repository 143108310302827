import React from 'react';
import PropTypes from 'prop-types';
import {BackendApiService} from '@/services/backend-api-service.js';
import PatientChildForm from '@/components/business/common/patient/patient-child-form.jsx';
import {InfoMessage} from '@/components/form/info-message.jsx';
import autoBind from 'react-autobind';
import {withTranslation} from 'react-i18next';

class PatientCreationChild extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        t: PropTypes.func,
        onCreated: PropTypes.func,
        onChanged: PropTypes.func,
    };

    state = {
        loading: false,
    };

    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        const props = this.props;
        const state = this.state;
        const { t } = this.props;

        return (
            <div className={props.className}>
                <h3>{t(['Child information', 'createChild.title'])}</h3>

                <InfoMessage infoMessage={t(['There are multiple steps in patient creation, but it won\'t take long.', 'createChild.info'])} text={t(['First, please fill the child information.', 'createChild.infoText'])} />

                <PatientChildForm loading={state.loading} onChanged={this.handleFormChanged} onSubmit={(newPatient) => this.createPatientChild(newPatient)} />
            </div>
        );
    }

    handleFormChanged(notSavedDataChild) {
        if(this.props.onChanged) {
            this.props.onChanged(notSavedDataChild);
        }
    }

    async createPatientChild(newPatient) {
        this.setState(state => ({
            ...state,
            loading: true,
        }));

        try {
            const newPatientResponse = await BackendApiService.getRequest({
                domain: 'patient',
                modelName: 'createPatient',
                data: {
                    patient: newPatient,
                },
            });
            
            this.props.onCreated(newPatientResponse.id);
        }
        catch (error) {
            console.error(error);
        }
        finally {
            this.setState(state => ({
                ...state,
                loading: false,
            }));
        }
    }
}

export default withTranslation()(PatientCreationChild);

