import deepEqual from 'deep-eql';
import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';
import {withTranslation} from 'react-i18next';
import {connect as redux_connect} from 'react-redux';
import Styled from 'styled-components';

import {HcpTutorialStatus} from '@/components/business/analytics/hcp/hcp-tutorial-status';
import {GuidedTour} from '@/components/layout/guided-tour';
import {GuidedTourStep} from '@/components/layout/guided-tour-step';
import demoBabyHello from '@/public/images/demo_baby_hello.svg';
import timelineBackGif from '@/public/images/guidedTour/timeline/back.gif';
import timelineDateSelectionGif from '@/public/images/guidedTour/timeline/dateSelection.gif';
import timelineScrollBarGif from '@/public/images/guidedTour/timeline/scrollBar.gif';
import timelineZoomGif from '@/public/images/guidedTour/timeline/zoom.gif';
import {BackendApiService} from '@/services/backend-api-service';
import {tutorialStatus} from '@/store/models/index';

const reduxMapStateToProps = function(state) {
    return {
        tutorialStatus: state.tutorialStatus,
    };
};

const reduxDispatch = function(dispatch) {
    return {
        setTutorialStatus: (status) => {
            dispatch(tutorialStatus.actions.set(status));
        },
    };
};

class GuidedTourTimeline extends React.Component {
    static propTypes = {
        t: PropTypes.func,
        tutorialStatus: PropTypes.any,
        setTutorialStatus: PropTypes.func,
        onClosed: PropTypes.func,
    };

    state = {
        steps: [
            this.props.t(['Welcome on the Daily biometrics', 'guidedTour.timeline.welcome.title']),
            this.props.t(['Scrollbar', 'guidedTour.timeline.scrollbar.title']),
            this.props.t(['Zoom', 'guidedTour.timeline.zoom.title']),
            this.props.t(['Date selection', 'guidedTour.timeline.dateSelection.title']),
            this.props.t(['Go back to Health report', 'guidedTour.timeline.back.title'])
        ],
        activeStep: 0,
    };

    constructor(props) {
        super(props);
        autoBind(this);
    }
    
    componentDidMount() {
        [
            timelineBackGif,
            timelineDateSelectionGif,
            timelineScrollBarGif,
            timelineZoomGif,
        ].forEach((picture) => {
            const img = new Image();
            img.src = picture;
        });
    }

    render() {
        const state = this.state;
        const { t } = this.props;

        return (
            <>
                <GuidedTour steps={state.steps} activeStep={state.activeStep} onChanged={this.handleChangeActiveStep} onClosed={this.handleCloseGuidedTour}>
                    {state.activeStep === 0 &&
                        <GuidedTourStep>
                            <p>{t(['Let’s take your first steps with this dashboard', 'guidedTour.timeline.welcome.text'])}</p>
                            <img src={demoBabyHello} width="100" alt={t(['Baby fox waving hello', 'guidedTour.timeline.welcome.alt'])} />
                        </GuidedTourStep>
                    }
                    {state.activeStep === 1 &&
                        <GuidedTourStep>
                            <p>{t(['You can use the scrollbar to navigate through signals.', 'guidedTour.timeline.scrollbar.text'])}</p>
                            <img src={timelineScrollBarGif} width={400} alt={t(['Animated gif showing how to use the scrollbar', 'guidedTour.timeline.scrollbar.alt'])} />
                        </GuidedTourStep>
                    }
                    {state.activeStep === 2 &&
                        <GuidedTourStep>
                            <p>{t(['You can zoom on a selected event by selecting a period.', 'guidedTour.timeline.zoom.text'])}</p>
                            <img src={timelineZoomGif} width={400} alt={t(['Animated gif showing how to use the zoom.', 'guidedTour.timeline.zoom.alt'])} />
                        </GuidedTourStep>
                    }
                    {state.activeStep === 3 &&
                        <GuidedTourStep>
                            <p>{t(['You can select a specific recording date via the calendar of the side bar', 'guidedTour.timeline.dateSelection.text'])}</p>
                            <img src={timelineDateSelectionGif} width={350} alt={t(['Animated gif showing how to select a recording date', 'guidedTour.timeline.dateSelection.alt'])} />
                        </GuidedTourStep>
                    }
                    {state.activeStep === 4 &&
                        <GuidedTourStep>
                            <p>{t(['You can access the patient\'s health report by using the breadcrumb.', 'guidedTour.timeline.back.text'])}</p>
                            <img src={timelineBackGif} width={350} alt={t(['Animated gif showing how to return to the health report', 'guidedTour.timeline.back.alt'])} />
                        </GuidedTourStep>
                    }
                </GuidedTour>
            </>
        );
    }

    handleChangeActiveStep(activeStep) {
        this.setState(state => ({
            ...state,
            activeStep: activeStep,
        }));
    }

    handleCloseGuidedTour() {
        this.changedGuidedTourTutorialStatus();

        if(this.props.onClosed) {
            this.props.onClosed();
        }
    }

    changedGuidedTourTutorialStatus() {
        BackendApiService.getRequest({
            domain: 'hcp',
            modelName: 'updateTutorialStatus',
            status: {
                guidedTourSignals: HcpTutorialStatus.DISABLED,
            },
        }).then((tutorialStatus) => {
            const updatedTutorialStatus = Object.assign({}, this.props.tutorialStatus);
            updatedTutorialStatus.guidedTourSignals = HcpTutorialStatus.DISABLED;
            this.props.setTutorialStatus(updatedTutorialStatus);

            return tutorialStatus;
        }).catch(err => {
            console.error(err);
            this.setState(state => ({
                ...state,
            }));
        });
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !deepEqual(this.props, nextProps) || !deepEqual(this.state, nextState);
    }
}

//language=SCSS
GuidedTourTimeline = Styled(GuidedTourTimeline)`
& {

}
`;


export default redux_connect(reduxMapStateToProps, reduxDispatch)(withTranslation()(GuidedTourTimeline));
