import PropTypes from 'prop-types';
import {useState} from 'react';
import styled from 'styled-components';

import EventConfigurationButton from '@/components/business/analytics/common/event-configuration-button';
import PatientSummary from '@/components/business/analytics/patient/common/patient-summary';
import PatientHealthCondition from '@/components/business/analytics/patient/patient-health-condition/patient-health-condition';
import LoadingView from '@/components/static/loading-view';
import ComponentErrorMessage from '@/components/widgets/component-error-message';
import {BackendApiService} from '@/services/backend-api-service';
import {colorPalette} from '@/themes/darkmode';

const HealthReportSidebar = ({patient, onEventConfigurationChanged}) => {
    const [patientData, setPatientData] = useState(patient);
    const [loading, setLoading] = useState(false);
    const isStudyPatient = patient.trialId?.id;
    const patientId = patient.patientId.id;
    
    const handleHealthConditionChanged = () => {
        getPatientData();
    };

    const onChanged = (selectedEventConfiguration) => {
        onEventConfigurationChanged(selectedEventConfiguration);
    };

    const getPatientData = () => {
        setLoading(true);
        
        BackendApiService.getRequest({
            domain: 'patient',
            modelName: 'getPatientDetails',
            data: {
                patientId: patientId
            }
        })
            .then((response) => {
                setPatientData(response);
                setLoading(false);
                return response;
            })
            .catch(err => {
                console.error(err);
            });
    };

    if(patientData) {
        return (
            <StyledWrapper>
                <div className="health-report-sidebar">
                    <div className="patient-summary-wrapper">
                        <PatientSummary
                            patient={patientData}
                            currentPage={{page: 'healthReport'}}
                        />
                    </div>
                    {!isStudyPatient &&
                        <div className="patient-health-condition-wrapper">
                            {loading ?
                                <LoadingView/>
                                :
                                <PatientHealthCondition
                                    patient={patientData}
                                    onChanged={handleHealthConditionChanged}
                                />
                            }
                        </div>
                    }
                    <div className="health-report-event-configuration">
                        <EventConfigurationButton
                            patientId={patient.patientId.id}
                            onSaved={selectedEventConfiguration => onChanged(selectedEventConfiguration)}
                        />
                    </div>
                </div>
            </StyledWrapper>
        );
    } else {
        return <ComponentErrorMessage component="HealthReportSidebar" />;
    }
};

HealthReportSidebar.propTypes = {
    patientId: PropTypes.string.isRequired,
    patient: PropTypes.object.isRequired,
    eventConfiguration: PropTypes.object.isRequired,
    onEventConfigurationChanged: PropTypes.func.isRequired,
};

//language=SCSS
const StyledWrapper = styled.div`
  && {
      .health-report-sidebar {
          background:${colorPalette.secondBackground};
          position:fixed;
          height:100%;
          width:250px;
          border-right: 1px solid ${colorPalette.darkColor};
          top:80px;

          div.patient-summary-wrapper,
          div.patient-health-condition-wrapper {
              position:relative;
          }

          > div {
              border-bottom:1px solid ${colorPalette.darkColor};
              padding:20px;
              //text-align: center;
          }

          .health-report-event-configuration {
              .button-event-configuration {
                  border-radius: 5px;
                  right: auto;
                  top: auto;
                  position:relative;
                  margin: 0 auto;
                  display:block;
              }
          }
      }
  }
`;

export default HealthReportSidebar;
