import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import HcpUpdatePersonalDataForm from '@/components/business/analytics/hcp/hcp-update-personal-data-form';
import LoadingView from '@/components/static/loading-view';
import {AlertTypeEnum} from '@/enum/alert-type-enum';
import {BackendApiService} from '@/services/backend-api-service';
import {FeedbackAlertsService} from '@/services/feedback-alerts-service';

const HcpUpdatePersonalDataPropTypes = {
    hcp: PropTypes.object.isRequired,
    onSaved: PropTypes.func,
};

function HcpUpdatePersonalData(props) {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(true);
    const [personalData, setPersonalData] = useState(null);
    
    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall
        fetchHcp();
    }, [props.hcp]);
    
    const fetchHcp = async () => {
        setLoading(true);
        setPersonalData(null);
        
        return BackendApiService.getRequest({
            domain: 'hcp',
            modelName: 'getHcpPersonalData',
            data: {
                hcpId: props.hcp.sub,
            },
        })
            .then((hcp) => {
                setPersonalData(hcp);
                setLoading(false);
                return hcp;
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
            });
    };
    
    const updateHcpPersonalData = async (hcp) => {
        setLoading(true);
        setPersonalData(null);

        BackendApiService.getRequest({
            domain: 'hcp',
            modelName: 'updateHcpPersonalDetails',
            data: {
                hcp: hcp,
            },
        })
            .then((hcp) => {
                FeedbackAlertsService.add(AlertTypeEnum.SUCCESS, 'updateHcpPersonalDetails', t(['Profile updated!', 'hcpUpdatePersonalData.success']));
                return hcp;
            })
            .then(fetchHcp)
            .then((hcp) => {
                if (hcp && props.onSaved) {
                    props.onSaved(hcp);
                }
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
            });
    };

    return (
        <>
            <h3>{t(['hcpUpdatePersonalData.title'])}</h3>

            {!loading && personalData && (
                <HcpUpdatePersonalDataForm hcp={personalData} loading={loading} onSubmit={updateHcpPersonalData} />
            )}
            {loading && (
                <LoadingView />
            )}
        </>
    );
}

HcpUpdatePersonalData.propTypes = HcpUpdatePersonalDataPropTypes;

export {HcpUpdatePersonalData};
