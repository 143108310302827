import { useState } from 'react';
import PropTypes from 'prop-types';
import ComponentErrorMessage from '@/components/widgets/component-error-message.tsx';

const TableSortableHead = ({ columns, handleSorting }) => {
    const [sortField, setSortField] = useState('');
    const [order, setOrder] = useState('asc');

    const handleSortingChange = (accessor) => {
        const sortOrder = (
            (accessor === sortField && order === 'asc') ? 'desc' :
                (accessor === sortField && order === 'desc') ? 'default' : 'asc'
        );
        setSortField(accessor);
        setOrder(sortOrder);
        handleSorting(accessor, sortOrder);
    };

    if(columns && handleSorting) {
        return (
            <thead>
                <tr>
                    {columns.map(({label, subLabel, className, accessor, sortable}) => {
                        const sortableClassName = sortable
                            ? sortField === accessor && order === 'asc'
                                ? 'up'
                                : sortField === accessor && order === 'desc'
                                    ? 'down'
                                    : 'default'
                            : '';
                        return (
                            <th
                                key={accessor}
                                data-accessor={accessor}
                                onClick={sortable && accessor ? () => handleSortingChange(accessor) : null}
                                className={`${sortableClassName}${(className ? ` ${className}` : '')}`}
                            >
                                {label}
                                {subLabel ? <span>{subLabel}</span> : ''}
                            </th>
                        );
                    })}
                </tr>
            </thead>
        );
    } else {
        return <ComponentErrorMessage component="TableSortableHead" />;
    }
};

TableSortableHead.propTypes = {
    columns: PropTypes.array.isRequired,
    handleSorting: PropTypes.func.isRequired,
};

export default TableSortableHead;
