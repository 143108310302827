import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {useEffect, useState} from 'react';
import {BackendApiService} from '@/services/backend-api-service.js';
import LoadingView from '@/components/static/loading-view.tsx';
import ComponentErrorMessage from '@/components/widgets/component-error-message.tsx';
import styled from 'styled-components';
import {GroupUserRole} from '@/components/business/analytics/group/group-user-role.jsx';
import hcpDefaultPic from '@/public/icons/hcp-default-pic.svg';
import {UserPic} from '@/components/static/user-pic.jsx';

const PatientGroupUserDetails = ({userId, userRole}) => {
    const { t } = useTranslation();
    const [userDetails, setUserDetails] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getUserDetails();
    }, []);

    const getUserDetails = () => {
        setLoading(true);

        BackendApiService.getRequest({
            domain: 'hcp',
            modelName: 'getHcpPersonalData',
            data: {
                hcpId: userId
            }
        }).then(user => {
            setLoading(false);
            setUserDetails(user.personalDetails);

            return user;
        }).catch(err => {
            console.error(err);
            setLoading(false);
        });
    };

    if(userId && userRole) {
        if(!loading && userDetails) {
            return (
                <StyledWrapper>
                    <UserPic pic={hcpDefaultPic} alt={userDetails.firstName} width={30}/>
                    <p className="userDetails">
                        {userDetails.firstName}&nbsp;{userDetails.lastName}
                        {(userRole === GroupUserRole.ADMIN) && <span>&nbsp;({t(['Admin', 'userChangeRole.role'])})</span>}<br />
                        <span>{t(['Email', 'patientGroup.email'])}&nbsp;{userDetails.email}</span>
                    </p>
                </StyledWrapper>
            );
        } else {
            return <LoadingView />;
        }
    } else {
        return <ComponentErrorMessage component="PatientGroupUserDetails" />;
    }
};

PatientGroupUserDetails.propTypes = {
    userId: PropTypes.string.isRequired,
    userRole: PropTypes.number.isRequired,
};

//language=SCSS
const StyledWrapper = styled.div`
  && {
      .userDetails {
          display: inline-block;
          vertical-align: top;
          padding-top: 4px;
          padding-left: 10px;
          width: calc(100% - 40px);
          word-break: break-all;
      }
  }
`;

export default PatientGroupUserDetails;
