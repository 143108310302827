import {SignalEventConfiguration} from 'gabi-api-js/patient/patient_common_pb';
import {
    Average,
    AverageForDay,
    HasData,
    StatisticsPerDay,
    UnifiedSignalDurations
} from 'gabi-api-js/signal/signal_query_pb';
import {useTranslation} from 'react-i18next';
import {Area, Bar, ComposedChart, Line, ReferenceLine, Tooltip, XAxis, YAxis} from 'recharts';
import {CategoricalChartFunc} from 'recharts/types/chart/generateCategoricalChart';
import styled from 'styled-components';

import {AverageValue} from '@/components/business/analytics/health-report/health-report-average-value';
import {HealthReportStatisticsTrend} from '@/components/business/analytics/health-report/health-report-statistics-trend';
import {ChartAxisTickColored} from '@/components/business/analytics/patient/patient-chart-axis/chart-axis-tick-colored';
import {ChartAxisTickDate} from '@/components/business/analytics/patient/patient-chart-axis/chart-axis-tick-date';
import {
    ChartAxisTickDuration
} from '@/components/business/analytics/patient/patient-chart-axis/chart-axis-tick-duration';
import {
    getDomainForDuration,
    getDomainForSignalType
} from '@/components/business/analytics/timeline/timeline-chart-domains';
import {ChartContainer} from '@/components/layout/chart-container';
import LoadingView from '@/components/static/loading-view';
import ComponentErrorMessage from '@/components/widgets/component-error-message';
import {getSignalTypeIdentifier, getSignalTypeUnit, SignalTypeEnum} from '@/enum/signal-type-enum';
import {useBackendQuery} from '@/hooks/use-backend-query';
import {colorPalette} from '@/themes/darkmode';
import {getMax} from '@/util/array-util';
import {formatDateUniversal, formatSecondsToHms} from '@/util/date-utils';
import {roundDurationHoursToQuarterPrecision} from '@/util/time-utils';

type HealthReportStatisticsPerDayProps = {
    patientId: string,
    signalType: number,
    lastRecordingDate?: object,
    dateFrom: object,
    dateTo: object,
    ignoreUnifiedDuration?: boolean,
    signalEventConfiguration: SignalEventConfiguration.AsObject,
    displayPreviousNights: boolean,
    onClick: (date: Date) => void,
}

type StatisticPerDayFormatted = {
    date: string;
    average: number | null;
    hasEnoughData: boolean;
    reliableDurationInSeconds: number;
    duration: number | null;
    standardDeviation: [number, number] | null;
}

type HealthReportStatisticsPerDayTooltipProps = {
    payload?: Array<{
        payload?: StatisticPerDayFormatted;
    }>;
}

const HealthReportStatisticsPerDay = ({patientId, signalType, lastRecordingDate, dateFrom, dateTo, ignoreUnifiedDuration, signalEventConfiguration, displayPreviousNights, onClick}: HealthReportStatisticsPerDayProps) => {
    const { t } = useTranslation();
    const signalTypeUnit = getSignalTypeUnit(signalType);

    const [unifiedDurationLoading, unifiedDuration] = useBackendQuery({
        domain: 'signal',
        modelName: 'getSignalsUnifiedDuration',
        data: {
            patientId: patientId,
            signalTypes: [SignalTypeEnum.PULSE_RATE, SignalTypeEnum.SPO2],
            dateFrom: dateFrom,
            dateTo: dateTo,
        },
        memoize: true,
        callback: (response: UnifiedSignalDurations.AsObject) => {
            return response.maxUnifiedDurationInSeconds;
        },
    });
    const [statisticsPerDayLoading, statisticsPerDay] = useBackendQuery({
        domain: 'signal',
        modelName: 'getStatisticsPerDay',
        data: {
            patientId: patientId,
            signalType: signalType,
            dateFrom: dateFrom,
            dateTo: dateTo,
        },
        memoize: true,
        callback: (response: StatisticsPerDay.AsObject) => {
            const statisticsPerDayFormatted: StatisticPerDayFormatted[] = [];
            response.statisticsPerDayList
                .forEach(statistic => {
                    const hasEnoughData = statistic.hasRecording && statistic.hasData >= HasData.HAS_MIN_RELIABLE_DATA;
                    statisticsPerDayFormatted.push({
                        date: statistic.date,
                        hasEnoughData: statistic.hasData >= HasData.HAS_MIN_RELIABLE_DATA,
                        average: (hasEnoughData ? statistic.average : null),
                        reliableDurationInSeconds: statistic.reliableDurationInSeconds,
                        duration: (statistic.hasRecording && statistic.reliableDurationInSeconds > 0) ? roundDurationHoursToQuarterPrecision(statistic.reliableDurationInSeconds) : null,
                        standardDeviation: ((hasEnoughData && statistic.reliableDurationInSeconds > 0) ? [
                            statistic.min,
                            statistic.max
                        ] : null)
                    });
                });

            //setSignalRange([response.rangeMin, response.rangeMax]);
            return {
                rangeMin: response.rangeMin,
                rangeMax: response.rangeMax,
                list: statisticsPerDayFormatted,
            };
        },
    }, []);

    const signalRange = (statisticsPerDayLoading || !statisticsPerDay) ? [0, 1000] : [statisticsPerDay.rangeMin, statisticsPerDay.rangeMax];

    const signalAverageQuery = displayPreviousNights ? {
        domain: 'signal',
        modelName: 'getSignalAverageForDay',
        data: {
            patientId: patientId,
            signalType: signalType,
            day: lastRecordingDate,
            nbPreviousDays: 14,
        },
        memoize: true,
    } : {
        domain: 'signal',
        modelName: 'getSignalAverageForPeriod',
        data: {
            patientId: patientId,
            signalType: signalType,
            dateFrom: dateFrom,
            dateTo: dateTo,
        },
        memoize: true,
    };
    const [signalAverageLoading, signalAverage] = useBackendQuery<AverageForDay.AsObject | Average.AsObject>(signalAverageQuery);

    const loading = unifiedDurationLoading || statisticsPerDayLoading || signalAverageLoading;

    const handleClick: CategoricalChartFunc = (rechartData) => {
        if (onClick) {
            if (rechartData && rechartData.activePayload && rechartData.activePayload[0]) {
                const rechartsPayload = rechartData?.activePayload[0].payload;
                if (rechartsPayload) {
                    const selectedDate = new Date(rechartsPayload.date);
                    const duration = rechartsPayload.reliableDurationInSeconds;
                    if (duration && duration > 0) {
                        onClick(selectedDate);
                    }
                }
            }
        }
    };

    const renderTooltip = (data: HealthReportStatisticsPerDayTooltipProps) => {
        if (data?.payload && data?.payload[0]?.payload) {
            const payload = data.payload[0].payload;
            const average = payload.average;
            const reliableDurationInSeconds = payload.reliableDurationInSeconds ?? 0;
            const hasEnoughData = payload.hasEnoughData;

            return (
                <div className="chart-tooltip">
                    <strong>{formatDateUniversal(new Date(payload.date + ' 00:00:00'))}</strong><br />
                    {reliableDurationInSeconds ? (
                        <span className="tooltip-duration">{t(['Duration', 'healthReport.statistics.last7nights.duration'])}: {formatSecondsToHms(reliableDurationInSeconds)}</span>
                    ) : (
                        <span>{t(['No recording for this day', 'healthReport.statistics.last7nights.noRecordingForThisDay'])}</span>
                    )}
                    {!reliableDurationInSeconds ? (
                        <span>{t(['No recording for this day', 'healthReport.statistics.last7nights.noRecordingForThisDay'])}</span>
                    ) : (!hasEnoughData ? (
                        <span>{t(['Not enough data for this night', 'healthReport.statistics.last7nights.notEnoughDataForThisDay'])}</span>
                    ) : (
                        <span className="tooltip-average">{t(['Average', 'healthReport.statistics.last7nights.average'])}: {Math.round(average!)}{signalTypeUnit}</span>
                    ))}
                </div>
            );
        }
        return null;
    };

    console.log(JSON.stringify(signalRange, null, '  '));
    console.log(JSON.stringify(signalRange, null, '  '));
    console.log(JSON.stringify(signalRange, null, '  '));
    console.log(JSON.stringify(signalRange, null, '  '));
    console.log(JSON.stringify(signalRange, null, '  '));
    const domainForSignalType = getDomainForSignalType(signalType, signalRange[0], signalRange[1]);

    if (patientId && dateFrom && dateTo && signalEventConfiguration) {
        const title = (() => {
            switch(signalType) {
            case SignalTypeEnum.PULSE_RATE: return t(['Pulse Rate - daily average', 'healthReport.statistics.last7nights.pulseRateTitle']);
            case SignalTypeEnum.SPO2: return t(['SpO2 - percentage', 'healthReport.statistics.last7nights.spo2Title']);
            case SignalTypeEnum.MOVEMENTS: return ''; // not used
            }
            return '';
        })();

        const topRightContent = displayPreviousNights ? (<>
            {signalAverage && (
                <div className="average-block">
                    <p>
                        <HealthReportStatisticsTrend
                            hasData={Math.min(
                                (signalAverage as AverageForDay.AsObject).averageValue?.hasData ?? HasData.HAS_NO_DATA,
                                (signalAverage as AverageForDay.AsObject).averagePrevious?.hasData ?? HasData.HAS_NO_DATA,
                            )}
                            trend={(signalAverage as AverageForDay.AsObject).trend}
                        />
                        {t(['Last night avg:', 'healthReport.aggregatedSignal.avgRecording'])}{' '}
                        <AverageValue averageObj={(signalAverage as AverageForDay.AsObject).averageValue!} unit={signalTypeUnit} precision={0} />
                    </p>
                    <p>
                        {t(['Prev. 14 nights avg:', 'healthReport.aggregatedSignal.avgLast14Nights'])}{' '}
                        <AverageValue averageObj={(signalAverage as AverageForDay.AsObject).averagePrevious!} unit={signalTypeUnit} precision={0} />
                    </p>
                </div>
            )}
        </>) : (
            <div className="average-block">
                <p>
                    {t(['Avg for period:', 'healthReport.statistics.averageForPeriod'])}{' '}
                    <AverageValue averageObj={(signalAverage as Average.AsObject)} unit={signalTypeUnit} precision={0} />
                </p>
            </div>
        );

        const tooltipText = <>
            <p>
                {signalType === SignalTypeEnum.PULSE_RATE &&
                    <>{t(['This chart shows the daily average of the patient\'s pulse rate values.', 'infoButton.average.pulseRate'])}&nbsp;</>
                }
                {signalType === SignalTypeEnum.SPO2 &&
                    <>{t(['This chart shows the daily average of patient\'s SpO2 values.', 'infoButton.average.spo2'])}&nbsp;</>
                }
                {t(['Notice that only reliable data are considered.', 'infoButton.average.reliableData'])}
            </p>
            <p>
                {t(['For each night, the recording duration is also displayed, taking only the reliable data into account.', 'infoButton.average.duration'])}
            </p>
        </>;

        if (!loading && statisticsPerDay) {
            const maxDuration = ignoreUnifiedDuration ? getMax(statisticsPerDay.list, (point) => point.reliableDurationInSeconds).reliableDurationInSeconds : unifiedDuration;
            const durationDomain = getDomainForDuration(maxDuration!);

            return (
                <StyledWrapper className="health-report-statistics-per-day" color={colorPalette.signalTypeLight[getSignalTypeIdentifier(signalType)]}>
                    <ChartContainer
                        title={title}
                        topRightContent={topRightContent}
                        infoTooltipTitle={`${t(`global.${getSignalTypeIdentifier(signalType)}`)} - ${t(['average', 'infoButton.average.title'])}`}
                        infoTooltipText={tooltipText}
                        infoTooltipOverlayPosition="bottomLeft"
                    >
                        {(statisticsPerDay.list.length <= 0) ? (
                            <div className="empty-message">{t(['No average for the last 7 nights', 'healthReport.statistics.last7nights.empty'])}</div>
                        ) : (
                            <ComposedChart data={statisticsPerDay.list} margin={{ top: 0, right: 0, bottom: 0, left: 10 }} onClick={handleClick}>
                                <XAxis
                                    dataKey="date"
                                    interval="equidistantPreserveStart"
                                    tick={<ChartAxisTickDate textAnchor="middle" />}
                                />
                                <YAxis
                                    yAxisId="left"
                                    orientation="left"
                                    dataKey="average"
                                    tick={(props) => <ChartAxisTickColored {...props} unit={signalTypeUnit} fill={colorPalette.signalTypeLight[getSignalTypeIdentifier(signalType)]} />}
                                    tickCount={4}
                                    domain={domainForSignalType.domain}
                                    ticks={domainForSignalType.ticks}
                                    interval={0}
                                    padding={{ top: 10 }}
                                    width={55}
                                />
                                <YAxis
                                    yAxisId="right"
                                    dataKey="duration"
                                    orientation="right"
                                    tick={<ChartAxisTickDuration textAnchor="start"/>}
                                    padding={{ top: 10 }}
                                    width={37}
                                    domain={durationDomain.domain}
                                    ticks={durationDomain.ticks}
                                    interval={0}
                                />

                                <Bar
                                    dataKey="duration"
                                    fill={colorPalette.thirdBackground}
                                    yAxisId="right"
                                    barSize={40}
                                />

                                {signalType !== SignalTypeEnum.SPO2 &&
                                    <ReferenceLine
                                        y={signalEventConfiguration.high!.value}
                                        label={{
                                            position: (signalEventConfiguration.high!.value >= (domainForSignalType.domain[1] - (5))) ? 'bottom' : 'top',
                                            value: `${signalEventConfiguration.high!.value}${signalTypeUnit}`,
                                            fill: colorPalette.signalTypeClear[getSignalTypeIdentifier(signalType)]
                                        }}
                                        stroke={colorPalette.signalTypeClear[getSignalTypeIdentifier(signalType)]}
                                        strokeDasharray="4"
                                        yAxisId="left"
                                    />
                                }

                                <ReferenceLine
                                    y={signalEventConfiguration.low!.value}
                                    label={{
                                        position: (signalEventConfiguration.low!.value <= (domainForSignalType.domain[0] + (5))) ? 'top' : 'bottom',
                                        value: `${signalEventConfiguration.low!.value}${signalTypeUnit}`,
                                        fill: colorPalette.signalTypeClear[getSignalTypeIdentifier(signalType)]
                                    }}
                                    stroke={colorPalette.signalTypeClear[getSignalTypeIdentifier(signalType)]}
                                    strokeDasharray="4"
                                    yAxisId="left"
                                />

                                <Area
                                    type="monotone"
                                    dot={false}
                                    activeDot={false}
                                    dataKey="standardDeviation"
                                    fill={colorPalette.signalType[getSignalTypeIdentifier(signalType)]}
                                    fillOpacity="0.3"
                                    strokeWidth={0}
                                    yAxisId="left"
                                    connectNulls
                                />

                                <Line
                                    type="monotone"
                                    dot={{ fill:colorPalette.signalType[getSignalTypeIdentifier(signalType)] }}
                                    dataKey="average"
                                    stroke={colorPalette.signalType[getSignalTypeIdentifier(signalType)]}
                                    strokeWidth={2}
                                    isAnimationActive={false}
                                    yAxisId="left"
                                />

                                <Tooltip
                                    content={renderTooltip}
                                    isAnimationActive={false}
                                />
                            </ComposedChart>
                        )}
                    </ChartContainer>
                </StyledWrapper>
            );
        }
        else {
            return (
                <StyledWrapper className="health-report-statistics-per-day" color={colorPalette.signalTypeLight[getSignalTypeIdentifier(signalType)]}>
                    <ChartContainer title={title}>
                        <LoadingView color={colorPalette.signalType[getSignalTypeIdentifier(signalType)]} />
                    </ChartContainer>
                </StyledWrapper>
            );
        }
    }
    else {
        return <ComponentErrorMessage component="HealthReportStatisticsPerDay" />;
    }
};


//language=SCSS
const StyledWrapper = styled.div`
& {
    min-height: 260px;
    height: 100%;
    display: flex;
    flex-direction: column;
    
    > * {
        flex-grow: 1;
    }

    svg {
        cursor: pointer;
    }
    
    .tooltip-average {
        color: ${props => props.color};
    }
    
    .tooltip-duration {
        color: ${colorPalette.clearColor};
    }
    
    .average-block {
        p span {
            color: ${props => props.color};
        }
        img.warningFlag {
            width: 15px;
            margin-right: 7px;
            margin-top: -3px;
        }
    }
}
`;

export default HealthReportStatisticsPerDay;
