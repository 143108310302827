import {HasData} from 'gabi-api-js/signal/signal_query_pb';

import {RecordingInformationTrend} from '@/enum/recording-information-trend';
import warningDownIcon from '@/public/icons/warning-down-icon.svg';
import warningIcon from '@/public/icons/warning-icon-white.svg';
import warningUpIcon from '@/public/icons/warning-up-icon.svg';

type HealthReportStatisticsTrendProps = {
    hasData: HasData;
    trend: number;
};

function HealthReportStatisticsTrend({hasData, trend}: HealthReportStatisticsTrendProps) {
    if (!hasData || hasData < HasData.HAS_MIN_RELIABLE_DATA) {
        return null;
    }
    switch (trend) {
    case RecordingInformationTrend.NO_DATA:
        return <img src={warningIcon} className="warningFlag" alt="Warning - no data" />;
    case RecordingInformationTrend.LOW:
        return <img src={warningDownIcon} className="warningFlag" alt="Warning - low"/>;
    case RecordingInformationTrend.HIGH:
        return <img src={warningUpIcon} className="warningFlag" alt="Warning - high"/>;
    default:
        return null;
    }
}

export {HealthReportStatisticsTrend};
