import {subDays} from 'date-fns';
import {floorHour} from 'gabi-web-common/util/date-util';
import PropTypes from 'prop-types';
import {useState} from 'react';
import {getI18n} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import Styled from 'styled-components';

import HealthReportNoRecording from '@/components/business/analytics/health-report/health-report-no-recording';
import HealthReportStatisticsPerDay from '@/components/business/analytics/health-report/health-report-statistics-per-day';
import HealthReportWrapper from '@/components/business/analytics/health-report/health-report-wrapper';
import ErrorBoundary from '@/components/errors/error-boundary';
import ColoredContainer from '@/components/layout/colored-container';
import {Loader} from '@/decorators/loader.new';
import {Page} from '@/decorators/page';
import withRouter from '@/decorators/withRouter';
import {HealthReportDashboardTypeEnum} from '@/enum/health-report-dashboard-type-enum';
import {SignalTypeEnum} from '@/enum/signal-type-enum';
import {formatApiDateToJSDate} from '@/services/api-requests/requests-utils';
import {colorPalette} from '@/themes/darkmode';
import {NewDateUtil} from '@/util/new-date-util';
import {formatDateForUrl} from '@/util/url-util';

function getPagePath() {
    return [
        {
            route: 'patients',
            name: 'Patients'
        },
    ];
}

function wrapWithPage(Component) {
    return Page({
        name: () => getI18n().t(['Health Report - trends 90 nights', 'healthReport.trends.title']),
        pagePath: props => (
            getPagePath(props)
        )
    })(Component);
}

function wrapWithLoader(Component) {
    return Loader({
        models: {
            eventConfiguration: props => ({ name: 'getEventConfiguration', domain: 'patient', id: props.params.id_patient, cached: true }),
            patientData: props => ({ name: 'getPatientDetails', domain: 'patient', data: {patientId: props.params.id_patient}, cached: true }),
        },
    })(Component);
}

const HealthReportTrendsPageProptypes = {
    eventConfiguration: PropTypes.object.isRequired,
    patientData: PropTypes.object.isRequired,
    params: PropTypes.object,
    className: PropTypes.string,
};

function HealthReportTrendsPageUnstyled(props) {
    const navigate = useNavigate();
    const [eventConfiguration, setEventConfiguration] = useState(props.eventConfiguration);
    const patientId = props.params.id_patient;

    let dateFrom = null;
    let dateTo = null;
    let dashboardHasContent = false;
    let lastRecordingDate = null;
    if (props.patientData.lastRecordingDate) {
        dateFrom = floorHour(subDays(NewDateUtil(), 89));
        dateTo = floorHour(NewDateUtil());
        
        lastRecordingDate = formatApiDateToJSDate(props.patientData.lastRecordingDate);

        dashboardHasContent = lastRecordingDate.getTime() > dateFrom.getTime();
    }
    
    const handleWidgetChartClick = (selectedDate) => {
        let url = `/patients/${props.params.id_patient}/timeline/${formatDateForUrl(selectedDate)}`;
        navigate(url);
    };
    
    const handleEventConfigurationChanged = (selectedEventConfiguration) => {
        setEventConfiguration(selectedEventConfiguration);
    };

    return (
        <div className={`health-report-trends ${props.className ?? ''}`}>
            <ErrorBoundary>
                <HealthReportWrapper
                    patient={props.patientData}
                    activeDashboard={HealthReportDashboardTypeEnum.TRENDS}
                    eventConfiguration={eventConfiguration}
                    onChanged={handleEventConfigurationChanged}
                >
                    {!dashboardHasContent ? (
                        <HealthReportNoRecording patient={props.patientData} activeDashboard={HealthReportDashboardTypeEnum.TRENDS} />
                    ) : (
                        <div className={'health-report-content'}>
                            <div className="health-report-widgets">
                                <ColoredContainer className="widget-average" color={colorPalette.signalType.pulseRate}>
                                    <HealthReportStatisticsPerDay
                                        patientId={patientId}
                                        signalType={SignalTypeEnum.PULSE_RATE}
                                        lastRecordingDate={lastRecordingDate}
                                        dateFrom={dateFrom}
                                        dateTo={dateTo}
                                        signalEventConfiguration={eventConfiguration.configuration.hr}
                                        displayPreviousNights={false}
                                        onClick={handleWidgetChartClick}
                                    />
                                </ColoredContainer>
                                <ColoredContainer className="widget-average" color={colorPalette.signalType.spo2}>
                                    <HealthReportStatisticsPerDay
                                        patientId={patientId}
                                        signalType={SignalTypeEnum.SPO2}
                                        lastRecordingDate={lastRecordingDate}
                                        dateFrom={dateFrom}
                                        dateTo={dateTo}
                                        signalEventConfiguration={eventConfiguration.configuration.spo2}
                                        displayPreviousNights={false}
                                        onClick={handleWidgetChartClick}
                                    />
                                </ColoredContainer>
                            </div>
                        </div>
                    )}
                </HealthReportWrapper>
            </ErrorBoundary>
        </div>
    );
}
HealthReportTrendsPageUnstyled.propTypes = HealthReportTrendsPageProptypes;

//language=SCSS
const HealthReportTrendsPage = Styled(HealthReportTrendsPageUnstyled)`
& {
    .health-report-content {
        display: flex;
        flex-wrap: nowrap;

        .health-report-widgets {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            width: 100%;
        }

        .widget-average {
            margin-bottom: 10px;
            position: relative;
        }
    }
}
`;

export default withRouter(wrapWithPage(wrapWithLoader(HealthReportTrendsPage)));
