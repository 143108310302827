import {subDays} from 'date-fns';
import {floorHour} from 'gabi-web-common/util/date-util';
import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';
import {getI18n, withTranslation} from 'react-i18next';
import Styled from 'styled-components';

import HealthReportDuration from '@/components/business/analytics/health-report/health-report-duration';
import {HealthReportEventsPerDay} from '@/components/business/analytics/health-report/health-report-events-per-day';
import HealthReportNoRecording from '@/components/business/analytics/health-report/health-report-no-recording';
import {HealthReportPercentagePerDay} from '@/components/business/analytics/health-report/health-report-percentage-per-day';
import HealthReportStatisticsPerDay from '@/components/business/analytics/health-report/health-report-statistics-per-day';
import HealthReportWrapper from '@/components/business/analytics/health-report/health-report-wrapper';
import ErrorBoundary from '@/components/errors/error-boundary';
import ColoredContainer from '@/components/layout/colored-container';
import {Loader} from '@/decorators/loader.new';
import {Page} from '@/decorators/page';
import withRouter from '@/decorators/withRouter';
import {HealthReportDashboardTypeEnum} from '@/enum/health-report-dashboard-type-enum';
import {SignalTypeEnum} from '@/enum/signal-type-enum';
import {formatApiDateToJSDate} from '@/services/api-requests/requests-utils';
import {colorPalette} from '@/themes/darkmode';
import {EventThresholdType} from '@/types/event-threshold-type';
import {NewDateUtil} from '@/util/new-date-util';
import {formatDateForUrl} from '@/util/url-util';

function getPagePath() {
    return [
        {
            route: 'patients',
            name: 'Patients'
        },
    ];
}

function wrapWithPage(Component) {
    return Page({
        name: props => getI18n().t('healthReport.lastNnights.title', {N: props.nbDays}),
        pagePath: props => (
            getPagePath(props)
        ),
    })(Component);
}

function wrapWithLoader(Component) {
    return Loader({
        models: {
            eventConfiguration: props => ({ name: 'getEventConfiguration', domain: 'patient', id: props.params.id_patient, cached: true }),
            patientData: props => ({ name: 'getPatientDetails', domain: 'patient', data: {patientId: props.params.id_patient}, cached: true }),
        },
    })(Component);
}

function getHealthReportDashboardTypeEnum(/* number */ nbDays) {
    if (nbDays === 7) {
        return HealthReportDashboardTypeEnum.LAST_7DAYS;
    }
    else if (nbDays === 30) {
        return HealthReportDashboardTypeEnum.LAST_30DAYS;
    }
    else {
        throw new Error(`Invald nbDays: ${nbDays}`);
    }
}

class HealthReportLastNDaysPage extends React.Component {
    static propTypes = {
        nbDays: PropTypes.number.isRequired,
        eventConfiguration: PropTypes.object.isRequired,
        patientData: PropTypes.object.isRequired,
        t: PropTypes.func,
        params: PropTypes.object,
        className: PropTypes.string,
        navigate: PropTypes.func,
    };

    state = {
        eventConfiguration: this.props.eventConfiguration,
    };

    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        const props = this.props;
        const patientId = props.params.id_patient;
        const eventConfiguration = this.state.eventConfiguration;
        const t = props.t;

        let dateFrom = null;
        let dateTo = null;
        let dashboardHasContent = false;
        let lastRecordingDate = null;
        if (props.patientData.lastRecordingDate) {
            dateFrom = floorHour(subDays(NewDateUtil(), props.nbDays-1));
            dateTo = floorHour(NewDateUtil());

            lastRecordingDate = formatApiDateToJSDate(props.patientData.lastRecordingDate);

            dashboardHasContent = lastRecordingDate.getTime() > dateFrom.getTime();
        }
        
        const dashboardTypeEnum = getHealthReportDashboardTypeEnum(props.nbDays);

        return (
            <div className={props.className}>
                <ErrorBoundary>
                    <HealthReportWrapper
                        patient={props.patientData}
                        activeDashboard={dashboardTypeEnum}
                        eventConfiguration={eventConfiguration}
                        onChanged={this.handleEventConfigurationChanged}
                    >

                        {dashboardHasContent &&
                            <div className="health-report-switch-wrapper">
                                <HealthReportDuration dateFrom={dateFrom} dateTo={dateTo} patientId={patientId}/>
                            </div>
                        }

                        {dashboardHasContent &&
                            <div className={'health-report-content health-report-content-view'}>
                                <div className="health-report-widgets">
                                    <ColoredContainer className="widget-average" color={colorPalette.signalType.pulseRate}>
                                        <HealthReportStatisticsPerDay
                                            patientId={patientId}
                                            signalType={SignalTypeEnum.PULSE_RATE}
                                            lastRecordingDate={lastRecordingDate}
                                            dateFrom={dateFrom}
                                            dateTo={dateTo}
                                            signalEventConfiguration={eventConfiguration.configuration.hr}
                                            displayPreviousNights={false}
                                            onClick={this.handleWidgetChartClick}
                                        />
                                    </ColoredContainer>
                                    
                                    <ColoredContainer className="widget-events" color={colorPalette.signalType.pulseRate}>
                                        <HealthReportEventsPerDay
                                            patientId={patientId}
                                            signalType={SignalTypeEnum.PULSE_RATE}
                                            thresholdTypes={[EventThresholdType.LOW, EventThresholdType.HIGH]}
                                            dateFrom={dateFrom}
                                            dateTo={dateTo}
                                            subtitle={t(['#events/h with aduration of X seconds', 'healthReport.events.last24h.subtitle'], {duration: eventConfiguration.configuration.hr.sensibility.seconds})}
                                            signalEventConfiguration={eventConfiguration.configuration.hr}
                                            onClick={this.handleWidgetChartClick}
                                        />
                                    </ColoredContainer>

                                    <ColoredContainer className="widget-average" color={colorPalette.signalType.spo2}>
                                        <HealthReportStatisticsPerDay
                                            patientId={patientId}
                                            signalType={SignalTypeEnum.SPO2}
                                            lastRecordingDate={lastRecordingDate}
                                            dateFrom={dateFrom}
                                            dateTo={dateTo}
                                            signalEventConfiguration={eventConfiguration.configuration.spo2}
                                            displayPreviousNights={false}
                                            onClick={this.handleWidgetChartClick}
                                        />
                                    </ColoredContainer>

                                    <ColoredContainer className="widget-events" color={colorPalette.signalType.spo2}>
                                        <HealthReportPercentagePerDay
                                            patientId={patientId}
                                            signalType={SignalTypeEnum.SPO2}
                                            thresholdType={EventThresholdType.LOW}
                                            lastRecordingDate={lastRecordingDate}
                                            dateFrom={dateFrom}
                                            dateTo={dateTo}
                                            signalEventConfiguration={eventConfiguration.configuration.spo2}
                                            onClick={this.handleWidgetChartClick}
                                            displayPreviousNights={false}
                                        />
                                    </ColoredContainer>
                                </div>
                            </div>
                        }
                        {!dashboardHasContent &&
                            <HealthReportNoRecording patient={props.patientData} activeDashboard={dashboardTypeEnum} />
                        }
                    </HealthReportWrapper>
                </ErrorBoundary>
            </div>
        );
    }

    handleWidgetChartClick(selectedDate) {
        let url = `/patients/${this.props.params.id_patient}/timeline/${formatDateForUrl(selectedDate)}`;
        this.props.navigate(url);
    }

    handleEventConfigurationChanged(selectedEventConfiguration) {
        this.setState(state => ({
            ...state,
            eventConfiguration: selectedEventConfiguration,
        }));
    }
}

//language=SCSS
HealthReportLastNDaysPage = Styled(HealthReportLastNDaysPage)`
& {
    .health-report-content {
        display: flex;
        flex-wrap: nowrap;
    }

    .health-report-widgets {
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        width: 100%;
        margin-top: 20px;
        gap: 10px;
    }
    
    .widget-events {
        position: relative;
        min-height: 340px;
    }

    .widget-average {
        position: relative;
        min-height: 340px;
    }
}
`;

export default withRouter(wrapWithPage(wrapWithLoader(withTranslation()(HealthReportLastNDaysPage))));
